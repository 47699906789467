import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import Input from "../../components/Items/input"
import {addAffiliate} from "../../state/actions/affiliates"
import { ButtonPrimary, ButtonPrimaryLigth } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import {onChange} from "../../state/actions/form"


const AddAdmins = () => {
    const dispatch = useDispatch();
    const { loading} = useSelector(
        (state) => ({
            loading: state.fakes.loading
        }), shallowEqual
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("formCount", 0))
        dispatch(onChange("formCountSafe", 0))
        dispatch(onChange("formName", ""))
        dispatch(onChange("formLink", ""))
        dispatch(onChange("formTotalPacket", 0))
        dispatch(onChange("formTotalPay", 0))
        dispatch(onChange("formTotalUser", 0))

    }, [dispatch]);

    const [tab, tabSet] = useState(1)

    return (
        <section className={classNames(styles.Pages, styles.edit)}>
            <div className={styles.headerBottom}>
                <div className={styles.left}>
                    <NavLink to="/affiliate-system">
                        Affiliates
                    </NavLink>
                    <img className={styles.arrow} src={Arrow} alt="" />
                    <span>
                        Add
                    </span>
                </div>
                <div className={styles.right}>
                    <NavLink to={"/affiliate-system"}>
                        Back
                    </NavLink>
                    <ButtonPrimary onClick={(e) => dispatch(addAffiliate())} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13, marginLeft:15}} text="Add" />
                </div>
            </div>
            <div className={styles.row}>
                <div className={classNames(styles.container, styles.full)}>
                    <div className={styles.contentWhite}>
                        <div className={classNames(styles.tab, styles.p)}>
                            {tab === 1 ? <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Detail" /> : 
                            <ButtonPrimaryLigth onClick={(e) => tabSet(1)} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Detail" />}
                           
                        </div>
                        {tab === 1 ? <div className={classNames(styles.px)} style={{marginBottom:25}}>
                            <div className={styles.row}>
                                <div className={classNames(styles.container, styles.wHalf)}>
                                <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Name
                                        </span>
                                        <Input styletype="type_4" name="formName" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Link
                                        </span>
                                        <Input styletype="type_4" name="formLink" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                         Count
                                        </span>
                                        <Input styletype="type_4" name="formCount" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                         Count Safe
                                        </span>
                                        <Input styletype="type_4" name="formCountSafe" type="text" />
                                    </div>
                                </div>
                                <div className={classNames(styles.container, styles.wHalf)}>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                         Total Packet
                                        </span>
                                        <Input styletype="type_4" name="formTotalPacket" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                         Total Pay
                                        </span>
                                        <Input styletype="type_4" name="formTotalPay" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                         Total User
                                        </span>
                                        <Input styletype="type_4" name="formTotalUser" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div> : ""}
                        {loading ?<div className="loading"></div> : ""}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddAdmins;
