import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import { totalPayment} from "../../state/actions/payments"
import Chart from 'react-apexcharts'
const Index = () => {
  const { list, pay_type_week, pay_type_month} = useSelector(
    (state) => ({
      list: state.payments.pay_type ? state.payments.pay_type : [],
      pay_type_week: state.payments.pay_type_week ? state.payments.pay_type_week : [],
      pay_type_month: state.payments.pay_type_month ? state.payments.pay_type_month : [],
    }), shallowEqual
  );


  const dispatch = useDispatch();
  const [toggle, toggleSet] = useState("31")
  useEffect(() => {
    dispatch(totalPayment())
  }, [dispatch]);
  const options = {
      chart:{type:"radialBar"},
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 9,
            size: '20%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      colors: ['#739ef1', '#f67374', '#f8b85d', '#41da8a', "#48cfdd"],
      labels: list.map(a => a.name),
      legend: {
        show: true,
        floating: true,
        fontSize: '14px',
        position: 'left',
        offsetX: -30,
        offsetY: -3,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 0
        }
      },
    }
  const series = toggle === "month" ? pay_type_month.map(a => a.value) : toggle === "31" ? list.map(a => a.value) : pay_type_week.map(a => a.value)
  return (
    <div id="paymentType" className={classNames(styles.paymentType, styles.box)}>
      <div className={styles.row}>
        <div className={styles.left}>
          <div className={styles.title}>
            <h3>Payment Type</h3>
            <div className={styles.buttons}>
              {/* {toggle === "week" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Week"} /> :
              <ButtonPrimaryLigth onClick={(e) => toggleSet("week")} style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Week"} />}
              
              {toggle === "month" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Month"} /> :
              <ButtonPrimaryLigth onClick={(e) =>  toggleSet("month")} style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Month"} />}
              {toggle === "31" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, padding:0}} styletype="type_1" text={"31 Day"} /> :
              <ButtonPrimaryLigth onClick={(e) =>  toggleSet("31")} style={{height:30, width:70, fontSize:12, padding:0}} styletype="type_1" text={"31 Day"} />}
               */}
            </div>
          </div>
          <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
            <Chart options={options} series={series} style={{width:280,marginRight:-22}} width={280} height={280} type="radialBar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
