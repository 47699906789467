import React from 'react';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import {FormattedMessage, useIntl} from 'react-intl';

const Head = (props) => {
  const intl = useIntl();
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_id" type="text" placeholder="Admin ID" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_nickname" size="12" type="text" placeholder={intl.formatMessage({id:"Dashboard.nickname"})} bar="false"/>
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
        </ul>
    </div>
  )
}

export default Head;
