import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import {ccStat } from "../../state/actions/callcenters"
import DateRange from "../../components/Items/dateRange"
import classNames from "classnames"
import TableStyle from '../../components/Items/Table.module.scss';
import firebase from "../../firebase"

const Stats = ({list}) => {
    const dispatch = useDispatch();
    const [affiliate, affiliateSet] = useState([])
    const [affiliateName, affiliateNameSet] = useState([])


    // { type: "name", value: doc.data().post },
    // { type: "name", value: doc.data().get },
    // { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
    // { type: "avatar", value: doc.data().image },
    // { type: "text", value: ccName ? ccName : doc.data().cc },
    // { type: "text", value: doc.data().asa ? doc.data().asa : doc.data().type },
    // { type: "text", value: doc.data().buy ? "true" : "" },
    // { type: "text", value: doc.data().visible ? "True" : "False" },
    // { type: "date", value:  new Date(doc.data().time.toDate()).toLocaleString() },
    useEffect(() => {
        let arr = []
        console.log(list);
        list.forEach(doc=>{
            let i = arr.findIndex(x => x.name === doc.data[4].value)
            if(i !== -1){
                arr[i].totalPhoto = arr[i].totalPhoto + 1
                if(doc.data[6].value === "true"){
                    arr[i].buyPhoto = arr[i].buyPhoto + 1
                    arr[i].coin = arr[i].coin + Number(doc.data[7].value)
                }
            }else{
                arr.push({
                    name:doc.data[4].value,
                    totalPhoto:1,
                    buyPhoto:doc.data[6].value === "true" ? 1 : 0,
                    coin:doc.data[7].value && doc.data[6].value === "true" ? Number(doc.data[7].value) : 0,
                })
            }
        })
        affiliateSet(arr)
        // eslint-disable-next-line
    }, [list]);
    let total = 0
    affiliate.forEach(doc=>{
        total+=doc.coin
    })
    return (<div>
        <div className={classNames(styles.tabContent)} style={{paddingBottom:20, paddingTop:20}}>
            <div className={TableStyle.tableContent}>
                <table className={classNames(TableStyle.table)}>
                    <thead>
                        <tr>
                            <th>Callcenter</th>
                            <th>Total Photo</th>
                            <th>Buy Photo</th>
                            <th>Coin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {affiliate.map((doc,index) =>{
                            return <tr>
                            <td>{doc.name}</td>
                            <td>{doc.totalPhoto}</td>
                            <td>{doc.buyPhoto}</td>
                            <td>{Number(doc.coin)}</td>
                        </tr>
                        })}
                        
                    </tbody>
                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td>{Number(total)}</td>
                            </tr>
                        </tfoot>
                </table>
            </div>
        </div>
    </div>
    )
}

export default Stats;
