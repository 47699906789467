import { createReducer } from 'redux-act';

import {
    USERS_DELETED_START,
    USERS_DELETED_END,
    USERS_DELETED_DATA,
    USERS_DELETED_CHANGE,
    USER_DELETED_DETAIL,
    USER_DELETED_HISTORY,
    USER_DELETED_PAYMENT_DATA,
    USERS_DELETED_MESSAGES,
    USERS_DELETED_MESSAGES_LIST,
    LAST_MESSAGES2_DELETED,
    LAST_MESSAGES_DELETED
} from '../actions/users_deleted';

const initialState = {};

const newsletters = createReducer(
    {
        [USERS_DELETED_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [USERS_DELETED_END]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                error: ""
            })
        },
        [USER_DELETED_DETAIL]: (state, payload) => {
            return ({
                ...state,
                detail:payload
            })
        },
        [USERS_DELETED_DATA]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
        [USERS_DELETED_CHANGE]: (state, payload) => {
            return ({
                ...state,
                ...payload,
            })
        },
        [LAST_MESSAGES_DELETED]: (state, payload) => {
            return ({
                ...state,
                lastMessages: payload,
            })
        },
        [LAST_MESSAGES2_DELETED]: (state, payload) => {
            return ({
                ...state,
                lastMessages2: payload,
            })
        },
        [USERS_DELETED_MESSAGES_LIST]: (state, payload) => {
            return ({
                ...state,
                messagesList: payload,
            })
        },
        [USERS_DELETED_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                messages: payload,
            })
        },
        [USER_DELETED_PAYMENT_DATA]: (state, payload) => {
            return ({
                ...state,
                payments: payload,
            })
        },
        [USER_DELETED_HISTORY]: (state, payload) => {
            return ({
                ...state,
                historyList: payload,
            })
        },
    },
    initialState
);
export default newsletters