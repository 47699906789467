import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Styles from "../../assets/styles/Pages.module.scss"
import { searchUsers } from "../../state/actions/newsletters"
import Table from "../../components/Items/table"
import { ButtonPrimary } from "../../components/Items/buttons"
import TableHead3 from "./TableHead3"
import Arrow from "../../assets/img/icons/arrow.svg"

const AddNewsletter = () => {
    const { loading, searchListUsers, unCheckUsers } = useSelector(
        (state) => ({
            loading: state.newsletters.loading,
            searchListUsers: state.newsletters.searchListUsers ? state.newsletters.searchListUsers : [],
            unCheckUsers: state.newsletters.unCheckUsers ? state.newsletters.unCheckUsers : []
        }), shallowEqual
    );
    const { id } = useParams();
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    let first = true
    const [head, headSet] = useState([
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"disable", key:"meta.nickname"}, 
        {name:"Country", orderBy:"disable", key:"meta.country"}, 
        {name:"City", orderBy:"disable", key:"meta.city"}, 
        {name:"Zip", orderBy:"disable", key:"meta.zip"}, 
        {name:"Time", orderBy:"desc", key:"timestamp"}, 
        {name:"Settings", orderBy:"disable"}
    ])
    const head2 = [
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"disable", key:"meta.nickname"}, 
        {name:"Country", orderBy:"disable", key:"meta.country"}, 
        {name:"City", orderBy:"disable", key:"meta.city"}, 
        {name:"Zip", orderBy:"disable", key:"meta.zip"}, 
        {name:"Time", orderBy:"disable", key:"timestamp"}, 
        {name:"Settings", orderBy:"disable"}
    ]
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(searchUsers("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if(first){
            first = false
        }else{
            if(unCheckUsers.length){
                dispatch(searchUsers("first", lastData, lastDataSet, perPage, head, false, activePage))
            }
        }
        // eslint-disable-next-line
    }, [unCheckUsers]);

    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(searchUsers("", lastData, lastDataSet, perPage, head, false, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(searchUsers("", lastData, lastDataSet, perPage, head, false, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(searchUsers("", lastData, lastDataSet, perPage, head, false, type))
        }
    }

    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(searchUsers("first", 0, lastDataSet, perPage, change, false, 0))
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className={Styles.left}>
                    <NavLink to="/newsletters">
                        Newsletters
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <NavLink to="/newsletters/add-newsletter">
                        Select Fake
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={Styles.right}>
                    <NavLink to={"/newsletters/add-newsletter/last-step/" + id}>
                        <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text={"Next Step"} />
                    </NavLink>
                </div>
            </div>
            <div className={Styles.contentWhite} style={{ padding:0, marginBottom:34 }}>
                <TableHead3  onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(searchUsers("first", 0, lastDataSet, perPage, head, false, 0))}}/>
                {searchListUsers.length ? <Table  class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={searchListUsers} buttonType="addNewsletterUsers" /> : ""}
                {loading ?<div className="loading"></div> : ""}
            </div>
            <div className={Styles.contentWhite} style={{ padding:0, marginBottom:34 }}>
                <b style={{padding:20,display:"block", width:"100%", borderBottom:"1px solid var(--gray-2)", marginBottom:20}}>Disable Users</b>
                {unCheckUsers.length ? <Table class={"type_2"} head={head2}  body={unCheckUsers} buttonType="addNewsletterUsersBanned" /> : ""}
            </div>
        </section>
    )
}

export default AddNewsletter;
