import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import { totalPayment} from "../../state/actions/payments"
import Chart from 'react-apexcharts'
const Index = () => {
  const { list, packets} = useSelector(
    (state) => ({
      list: state.payments.packet_type ? state.payments.packet_type : [],
      packets: state.general.packets2 ? state.general.packets2 : []
    }), shallowEqual
  );


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(totalPayment())
  }, [dispatch]);
  const options = {
      chart:{type:"bar"},
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      colors: ['#739ef1', '#f67374', '#f8b85d', '#41da8a', "#48cfdd"],
      xaxis: {
        categories: list.map(a => packets[a.name] ? packets[a.name].title : a.name),
        labels:{style:{colors:"var(--gray-5)", fontSize:10}}
      },
      yaxis: {
        labels:{style:{colors:"var(--gray-5)", fontSize:10}}
      }
    }
  const series = [{
        name:"value",
      data:list.map(a => a.value)
  }]
  return (
    <div id="paymentType" className={classNames(styles.paymentType, styles.box, styles.h2)}>
      <div className={styles.row}>
        <div className={styles.left}>
          <div className={styles.title}>
            <h3>Packets</h3>
            <div className={styles.buttons}>
              {/* {toggle === "week" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Week"} /> :
              <ButtonPrimaryLigth onClick={(e) => toggleSet("week")} style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Week"} />}
              
              {toggle === "month" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Month"} /> :
              <ButtonPrimaryLigth onClick={(e) =>  toggleSet("month")} style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Month"} />}
              {toggle === "31" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, padding:0}} styletype="type_1" text={"31 Day"} /> :
              <ButtonPrimaryLigth onClick={(e) =>  toggleSet("31")} style={{height:30, width:70, fontSize:12, padding:0}} styletype="type_1" text={"31 Day"} />}
               */}
            </div>
          </div>
          <div className={styles.px}>
            <Chart options={options} series={series} style={{width:"100%"}}height={390} type="bar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
