import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import Chart from 'react-apexcharts'
const Index = () => {
  const { waiting, loading} = useSelector(
    (state) => ({
        waiting: state.messages.waiting ? state.messages.waiting : {waiting:0, message:0, asa1:0, asa2:0, asa3:0},
        loading: state.messages.loading,
    }), shallowEqual
  );


  const dispatch = useDispatch();
  useEffect(() => {
  }, [dispatch]);
  const options = {
      chart:{height:100,type:"bar",stacked:!0,toolbar:{show:!1}},
      grid:{show:!1,padding:{left:0,right:0,top:-20,bottom:-15}},
      plotOptions:{bar:{horizontal:!1,columnWidth:"20%",endingShape:"rounded"}},
      legend:{show:!1},
    //   dataLabels:{enabled:!1},
      colors:["#5fdae4","#e7edf3"],
      xaxis:{categories:["Message","Waiting","Asa1","Asa2","Asa3"],
      axisBorder:{show:!1},
      axisTicks:{show:!1},
      labels:{style:{colors:"#727E8C"},offsetY:-5}},
      yaxis:{show:!1,floating:!0},tooltip:{x:{show:!1}}}
    //   , {name:"Top", data:[10-waiting.message,10-waiting.waiting,10-waiting.asa1,10-waiting.asa2,10-waiting.asa3]
      const series = [{name:"Waiting",data:[waiting.message,waiting.waiting,waiting.asa1,waiting.asa2,waiting.asa3]}]
  return (
    <div id="onlineUsers" className={classNames(styles.onlineUsers, styles.box)} style={{marginRight:0}}>
        {/* {loading ?<div className="loading"></div> : ""} */}
        <div className={styles.title} style={{marginBottom:0}}>
            <h3>
            Waiting Messages
            </h3>
        </div>

        <Chart options={options} series={series} style={{width:"100%"}} height={85} type="bar" />
        {/* <div className={styles.px}>
            <div className={styles.waiting}>
                <div className={styles.item}>
                    <p>Message</p>
                    <b>{waiting.message}</b>
                </div>
                <div className={styles.item}>
                    <p>Waiting</p>
                    <b>{waiting.waiting}</b>
                </div>
                <div className={styles.item}>
                    <p>Asa1</p>
                    <b>{waiting.asa1}</b>
                </div>
                <div className={styles.item}>
                    <p>Asa2</p>
                    <b>{waiting.asa2}</b>
                </div>
                <div className={styles.item}>
                    <p>Asa3</p>
                    <b>{waiting.asa3}</b>
                </div>

            </div>
        </div> */}
    </div>
  );
};

export default Index;
