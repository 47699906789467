import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { messageSend, photoUpdate, photoDelete } from "../../state/actions/messages"
import { NavLink } from 'react-router-dom';
import classNames from "classnames"
import Textarea from "../../components/Items/textarea"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import { ButtonPrimary, ButtonPrimaryLigth, ButtonDanger } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import Table from "../../components/Items/table"
import TableHead from "./TableHead"
import { onChange } from "../../state/actions/form"

const Users = () => {
    const { loading, form } = useSelector(
        (state) => ({
            loading: state.messages.loading,
            form: state.form,
        }), shallowEqual
    );
    const { id } = useParams();
    const [sendStat, sendStatSet] = useState(true)
    const dispatch = useDispatch();
    let history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [dispatch]);
    return (
        <section className={Styles.Pages}>

            <div className={Styles.headerBottom}>
                <div className={Styles.left}>
                    <NavLink to="/users">
                        Users
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={Styles.right}>
                    <NavLink to={"/users"}>
                        Back
                    </NavLink>
                    <ButtonPrimary onClick={(e) => { dispatch(messageSend(id)); }} style={{ height: 38, borderRadius: 5, fontWeight: 500, fontSize: 13, marginLeft: 15 }} text="Send Message" />

                </div>
            </div>
            <div className={Styles.contentWhite} style={{ marginBottom: 25, padding: 34 }}>
                <div className={classNames(Styles.center)}>
                    <div className={Styles.list}>
                        <label>Fake ID :</label>
                        <Input styletype="type_2" name="message_fakeId" height={200} style={{ flex: 1, marginBottom: 0 }} />
                    </div>
                    <div className={Styles.list}>
                        <label>Message :</label>
                        <Textarea styletype="type_2" name="message" height={200} style={{ flex: 1, marginBottom: 0 }} />
                    </div>
                    <div className={Styles.list} style={{ marginBottom: 20 }}>
                        <label></label>
                        <small style={{ flex: 1 }}>%username% - %region% - %alt%</small>
                    </div>
                    <div className={Styles.list}>
                        <label>Image :</label>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div className={Styles.buttonUpload}>
                                <input className={Styles.absolute} type="file" onChange={(e) => dispatch(photoUpdate(e.target))} />
                                <ButtonPrimaryLigth style={{ height: 38, fontSize: 13, borderRadius: 5 }} text="Upload Image" />
                            </div>
                            <div>
                                <ButtonDanger style={{ height: 38, fontSize: 13, marginLeft: 10, borderRadius: 5 }} text="Delete Image" onClick={(e) => dispatch(photoDelete())} />
                            </div>
                        </div>
                    </div>
                    <div className={Styles.list}>
                        <label></label>
                        <small style={{ flex: 1 }}>{form.message_image}</small>
                    </div>
                </div>
                {loading ? <div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
