import { createReducer } from 'redux-act';

import {
    CALLCENTERS_START,
    CALLCENTERS_END,
    CALLCENTERS_SEARCH_USER,
    CALLCENTERS_USER_DETAIL,
    CALLCENTERS_LAST_MESSAGES,
    CALLCENTERS_LAST_MESSAGES2,
    CALLCENTERS_MESSAGES_LIST,
    CALLCENTERS_MESSAGES,
    CALLCENTERS_STATS
} from '../actions/callcenters';

const initialState = {};

const newsletters = createReducer(
    {
        [CALLCENTERS_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [CALLCENTERS_END]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                error: ""
            })
        },
        [CALLCENTERS_USER_DETAIL]: (state, payload) => {
            return ({
                ...state,
                detail:payload
            })
        },
        [CALLCENTERS_SEARCH_USER]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
        [CALLCENTERS_LAST_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                lastMessages: payload,
            })
        },
        [CALLCENTERS_LAST_MESSAGES2]: (state, payload) => {
            return ({
                ...state,
                lastMessages2: payload,
            })
        },
        [CALLCENTERS_MESSAGES_LIST]: (state, payload) => {
            return ({
                ...state,
                messagesList: payload,
            })
        },
        [CALLCENTERS_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                messages: payload,
            })
        },
        [CALLCENTERS_STATS]: (state, payload) => {
            return ({
                ...state,
                stats:payload,
            })
        },
    },
    initialState
);
export default newsletters