import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import { getMessages, getLastMessage, getLastMessage2, getMessagesList } from "../../state/actions/users"
import Modal from "../../components/Items/modal"
import {ButtonPrimaryLigth, ButtonPrimary } from "../../components/Items/buttons"
import MessageBox from "../../components/Layout/MessageBox/MessageBox_2"
import {defaultLAvatar} from "../../Settings"
import classNames from "classnames"

const MessagesList = ({id}) => {
    const { messagesList, lastMessages, users, lastMessages2, userData, messages} = useSelector(
        (state) => ({
            users: state.usersList,
            messagesList: state.users.messagesList ? state.users.messagesList : [],
            lastMessages: state.users.lastMessages ? state.users.lastMessages : [],
            messages: state.users.messages ? state.users.messages : [],
            lastMessages2: state.users.lastMessages2 ? state.users.lastMessages2 : [],
            userData: state.users.detail,
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [modal_2, modal_2Status] = useState("")
    const [toggle, toggleSet] = useState("Messages")
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getMessagesList(id))
        // eslint-disable-next-line
    }, [dispatch]);
    const openMessage = (element, fakeid) => {
        dispatch(getMessages(element, id, fakeid))
        modal_2Status(fakeid)
    }
    const openMessageTab = (element) => {
        if(element === "From"){
            dispatch(getLastMessage(id))
        }else if(element === "To"){
            dispatch(getLastMessage2(id))
        }else if(element === "Messages"){
            dispatch(getMessagesList(id))
        }
        toggleSet(element)
    }
    return (<div>
        <div className={classNames(styles.tabContent)}>
            <div className={classNames(styles.title, styles.p)}>
                <h3>Last Messages</h3>
                <div className={styles.buttons}>
                {toggle === "Messages" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Messages"} /> :
                <ButtonPrimaryLigth onClick={(e) => openMessageTab("Messages")} style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"Messages"} />}
                {toggle === "From" ? <ButtonPrimary style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"From"} /> :
                <ButtonPrimaryLigth onClick={(e) =>  openMessageTab("From")} style={{height:30, width:70, fontSize:12, marginRight:15}} styletype="type_1" text={"From"} />}
                {toggle === "To" ? <ButtonPrimary style={{height:30, width:70, fontSize:12}} styletype="type_1" text={"To"} /> :
                <ButtonPrimaryLigth onClick={(e) =>  openMessageTab("To")} style={{height:30, width:70, fontSize:12}} styletype="type_1" text={"To"} />}
                </div>
            </div>
            {toggle === "Messages" ? 
            <ul className={styles.lastMessagesList}>
                {messagesList.map((doc, index) =>{
                    return <li key={index}>
                    <div className={styles.avatar}>
                        <img src={doc.avatar === "noavatar.jpg" ? defaultLAvatar : doc.avatar} alt="" />
                    </div>
                    <div className={styles.messageContent}>
                        <p><b>{doc.nickname} </b> <small>(From: {doc.from} / To: {doc.to})</small></p>
                        {/* <p>Last Time</p> */}
                        <p className={styles.message}>{doc.lastMessage}</p>
                        <small>{doc.time.toLocaleString()}</small>
                    </div>
                    <ButtonPrimaryLigth onClick={(e) =>  openMessage(doc.id, doc.fakeId)} style={{height:30, width:70, fontSize:12, marginLeft:"auto"}} styletype="type_1" text={"Detail"} />
                </li>
                })}
            </ul>  : toggle === "From" ? 
            <ul className={styles.lastMessages}>
                
                {lastMessages.map((doc, index) =>{
                    return <li key={index}>
                    <div className={styles.avatar}>
                        <img src={users[doc.fake] && users[doc.fake].avatar ? users[doc.fake].avatar === "noavatar.jpg" ? defaultLAvatar :  users[doc.fake].avatar : defaultLAvatar} alt="" />
                    </div>
                    <div className={styles.messageContent}>
                        <div className={styles.message}>
                            {doc.message}
                            <small>{doc.time.toLocaleString()}</small>
                        </div>
                        <small>{doc.cc}</small>
                    </div>
                </li>
                })}
            </ul> : toggle === "To" ? 
            <ul className={styles.lastMessages}>
                {lastMessages2.map((doc, index) =>{
                    return <li key={index}>
                    <div className={styles.avatar}>
                        <img src={userData.meta.avatarUrl === "noavatar.jpg" ? defaultLAvatar : userData.meta.avatarUrl } alt="" />
                    </div>
                    <div className={styles.messageContent}>
                        <div className={styles.message}>
                            {doc.message}
                            <small>{doc.time.toLocaleString()}</small>
                        </div>
                        <small>{doc.cc}</small>
                    </div>
                    <div className={styles.avatar}>
                        <img src={users[doc.fake] && users[doc.fake].avatar ? users[doc.fake].avatar === "noavatar.jpg" ? defaultLAvatar :  users[doc.fake].avatar : defaultLAvatar} alt="" />
                    </div>
                </li>
                })}
            </ul> : ""}
            
        </div>
        {modal_2 ? <Modal padding={0} content={<MessageBox userId={id} messageList={messages} fakeId={modal_2} />} status={modal_2} width={500} close={(e) => modal_2Status("")} /> : ""}
    </div>
    )
}

export default MessagesList;
