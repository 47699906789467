import React, { useEffect } from 'react';
import {  useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
const Index = () => {
    const { messages, userData} = useSelector(
      (state) => ({
          messages: state.messages.total ? state.messages.total : {},
          userData: state.auth.userData,
      }), shallowEqual
    );

  useEffect(() => {
  }, []);
  if(userData.role === 2){
      return null;
  }
  return (
    <div id="newMessages" className={classNames(styles.newMessages, styles.box)}>
        <div className={classNames(styles.iconHeader, styles.type_2)}>
            <div className={styles.icon}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 511.999 511.999">
                    <g>
                        <path fill="" d="M113.304,396.177c-41.405-40.668-66.722-93.368-72.6-150.216c-22.006,27.818-33.938,61.937-33.883,97.745
                            c0.037,23.29,5.279,46.441,15.212,67.376L1.551,470.689c-3.521,10.247-0.949,21.373,6.713,29.036
                            c5.392,5.392,12.501,8.264,19.812,8.264c3.076,0,6.188-0.508,9.223-1.551l59.609-20.483c20.935,9.933,44.086,15.175,67.376,15.212
                            c36.509,0.049,71.256-12.338,99.361-35.168C207.133,460.886,154.416,436.556,113.304,396.177z"/>
                    </g>
                    <g>
                        <path fill="" d="M510.156,401.842L480.419,315.3c14.334-29.302,21.909-61.89,21.96-94.679c0.088-57.013-21.97-110.92-62.112-151.79
                            C400.117,27.952,346.615,4.942,289.615,4.039C230.51,3.104,174.954,25.586,133.187,67.352
                            C91.42,109.119,68.934,164.674,69.87,223.782c0.903,56.999,23.913,110.502,64.79,150.652
                            c40.79,40.064,94.56,62.116,151.451,62.114c0.112,0,0.23,0,0.34,0c32.79-0.051,65.378-7.626,94.68-21.96l86.544,29.738
                            c3.606,1.239,7.304,1.843,10.959,1.843c8.688,0,17.136-3.412,23.545-9.822C511.284,427.241,514.34,414.021,510.156,401.842z
                            M307.004,295.328H195.331c-8.416,0-15.238-6.823-15.238-15.238c0-8.416,6.823-15.238,15.238-15.238h111.672
                            c8.416,0,15.238,6.823,15.238,15.238C322.241,288.506,315.42,295.328,307.004,295.328z M376.892,232.659h-181.56
                            c-8.416,0-15.238-6.823-15.238-15.238s6.823-15.238,15.238-15.238h181.56c8.416,0,15.238,6.823,15.238,15.238
                            S385.308,232.659,376.892,232.659z M376.892,169.988h-181.56c-8.416,0-15.238-6.823-15.238-15.238
                            c0-8.416,6.823-15.238,15.238-15.238h181.56c8.416,0,15.238,6.823,15.238,15.238C392.13,163.165,385.308,169.988,376.892,169.988z
                            "/>
                    </g>
                </svg>

            </div>
            <p>New Messages</p>
            <b>{(messages.message + messages.asa1 + messages.asa2 + messages.asa3 + messages.gif + messages.gift + messages.photo + messages.new) ? messages.message + messages.asa1 + messages.asa2 + messages.asa3 + messages.gif + messages.gift + messages.photo + messages.new : 0}</b>
        </div>
    </div>
  );
};

export default Index;
