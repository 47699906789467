import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import {ButtonPrimary} from "../../components/Items/buttons"
import Cup from "../../assets/img/cup.png"
const Index = () => {
  const { bestUser, form} = useSelector(
    (state) => ({
        form: state.form,
        bestUser: state.payments.bestUser ? state.payments.bestUser : {},
    }), shallowEqual
  );


  const dispatch = useDispatch();
  useEffect(() => {
  }, [dispatch]);
  return (
    <div id="bestUser" className={classNames(styles.bestUser, styles.box)}>
        <div className={styles.title_2}>
            Top User {bestUser.nickname}!
            <small>Best seller of {new Date(form.startDate) ? new Date(form.startDate).toLocaleDateString() : ""} -  {new Date(form.endDate) ? new Date(form.endDate).toLocaleDateString() : ""}</small>
        </div>
        <div className={styles.down}>
            <b>€{bestUser.cost ? bestUser.cost.toFixed(2) : 0}</b>
            <div className={styles.row}>
              <div>
                  <p>You have done 57.6% more sales today.</p>
                  <ButtonPrimary text="PAyments"  style={{height:38, width:120, fontSize:14}} styletype="type_1" />
              </div>
              <div className={styles.img}>
                  <img src={Cup} alt="" />
              </div>
            </div>
        </div>
    </div>
  );
};

export default Index;
