import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import {usersList} from "./usersList"

let unsubscribe2 = ""
export const MESSAGES_IMAGE_START = createAction('MESSAGES_IMAGE_START');
export const MESSAGES_IMAGE_END = createAction('MESSAGES_IMAGE_END');
export const MESSAGES_IMAGE_DATA = createAction('MESSAGES_IMAGE_DATA');
export const MESSAGES_CHANGE = createAction('MESSAGES_CHANGE');

const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(MESSAGES_CHANGE({
            [name]: value
        }))
    }
}


const messagesData = (first, head, total) => {
    return async (dispatch, getState) => {

        let form = getState().form
        let messages = getState().messages
        let packets = getState().general.packets ? getState().general.packets : []
        let callcenters = getState().callcenters.list ? getState().callcenters.list : []
        dispatch(MESSAGES_IMAGE_START())
        let fireData = firebase.firestore().collection("messages").where("type", "==", "photo")

        if(form.searchStartDate){
            fireData = fireData.where("time", ">=", new Date(form.searchStartDate))
        }
        if(form.searchEndDate){
            fireData = fireData.where("time", "<=", new Date(form.searchEndDate))
        }
        if (form.search_docId) {
            fireData = fireData.where("id", "==", form.search_docId);
        }
        if (form.search_post) {
            fireData = fireData.where("post", "==", form.search_post);
        }
        if (form.search_get) {
            fireData = fireData.where("get", "==", form.search_get);
        }
        if (form.search_cc) {
            fireData = fireData.where("cc", "==", form.search_cc);
        }
        if (form.search_asa) {
            fireData = fireData.where("asa", "==", form.search_asa);
        }
        head.forEach(doc=>{
            if(doc.orderBy && doc.orderBy !== "disable"){
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            } 
        })

        if(messages.lastData && messages.lastData[(messages.activePage - 1)] && first === ""){
            fireData = fireData.startAfter(messages.lastData[(messages.activePage - 1)])
        }
        if(!total){
            fireData = fireData.limit(10) 
        }
        
        if(unsubscribe2){
            unsubscribe2()
        }
        unsubscribe2 = fireData.onSnapshot(async doc => {
            let userList = []
            if(!doc.size){
                dispatch(MESSAGES_IMAGE_DATA({
                    list: userList
                }))
                dispatch(MESSAGES_IMAGE_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            // if(doc && _lastData){
            //     if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
            //         lastData.push(_lastData)
            //         lastDataSet(lastData)
            //     }else if(!lastData){
            //         lastDataSet([_lastData])
            //     }
            // }

            if(doc && _lastData){
                if(messages.lastData && (messages.lastData.findIndex(x => x.id === _lastData.id) === -1)){
                    console.log(_lastData);
                    messages.lastData.push(_lastData)
                }else if(!messages.lastData){
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            // if(doc && _lastData){
            //     if(activePage >= lastData.length){
            //         if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
            //             lastData.push(_lastData)
            //             lastDataSet(lastData)
            //         }else if(!lastData){
            //             lastDataSet([_lastData])
            //         }
            //     }else{
            //         if(lastData){
            //             lastData.pop()
            //             lastData.push(_lastData)
            //             lastDataSet(lastData)
            //         }else{
            //             lastDataSet([_lastData])
            //         }
            //     }
            // }
            doc.forEach(doc=>{
                let packetName = ""
                packets.forEach(doc2=>{
                    if(doc2.id === doc.data().packet){
                        packetName = doc2.title
                    }
                })
                let pushArr = {}
                let ccName = ""
                if(doc.data().cc){
                    callcenters.forEach(doc2=>{
                        if(doc2.docId === doc.data().cc){
                            ccName = doc2.data[0].value
                        }
                    })
                }
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "name", value: doc.data().post },
                    { type: "name", value: doc.data().get },
                    { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
                    { type: "avatar", value: doc.data().image },
                    { type: "text", value: ccName ? ccName : doc.data().cc },
                    { type: "text", value: doc.data().asa ? doc.data().asa : doc.data().type },
                    { type: "text", value: doc.data().buy ? "true" : "" },
                    { type: "text", value: doc.data().coin },
                    { type: "text", value: doc.data().visible ? "True" : "False" },
                    { type: "date", value:  new Date(doc.data().time.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
                firebase.firestore().collection("users").doc(doc.data().post).get().then(doc=>{
                    dispatch(usersList(doc))
                })
                firebase.firestore().collection("users").doc(doc.data().get).get().then(doc=>{
                    dispatch(usersList(doc))
                })
            })
            dispatch(MESSAGES_IMAGE_DATA({
                list: userList
            }))
            if(total){
                dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            }
            dispatch(MESSAGES_IMAGE_END())
        })
    }
}

// const messagesData = (first, lastData, lastDataSet, perPage, head, activePage) => {
//     return async (dispatch, getState) => {

//         let form = getState().form
//         let callcenters = getState().callcenters.list ? getState().callcenters.list : []
//         dispatch(MESSAGES_IMAGE_START())
//         let fireData = firebase.firestore().collection("messages").where("type", "==", "photo")
//         if(form.searchStartDate){
//             fireData = fireData.where("time", ">=", new Date(form.searchStartDate))
//         }
//         if(form.searchEndDate){
//             fireData = fireData.where("time", "<=", new Date(form.searchEndDate))
//         }
//         if (form.search_docId) {
//             fireData = fireData.where("id", "==", form.search_docId);
//         }
//         if (form.search_post) {
//             fireData = fireData.where("post", "==", form.search_post);
//         }
//         if (form.search_get) {
//             fireData = fireData.where("get", "==", form.search_get);
//         }
//         if (form.search_cc) {
//             fireData = fireData.where("cc", "==", form.search_cc);
//         }
//         if (form.search_asa) {
//             fireData = fireData.where("asa", "==", form.search_asa);
//         }
//         head.forEach(doc=>{
//             if(doc.orderBy && doc.orderBy !== "disable"){
//                 fireData = fireData.orderBy(doc.key, doc.orderBy)
//             } 
//         })
//         if(lastData && lastData[(activePage - 1)] && first === ""){
//             fireData = fireData.startAfter(lastData[(activePage - 1)])
//         }
//         if(unsubscribe2){
//             unsubscribe2()
//         }
//         unsubscribe2 = fireData.limit(perPage).onSnapshot(async doc => {
//             let userList = []
//             if(!doc.size){
//                 dispatch(MESSAGES_IMAGE_DATA({
//                     list: userList
//                 }))
//                 dispatch(MESSAGES_IMAGE_END())
//                 return null
//             }
//             let _lastData = doc.docs[doc.docs.length - 1]
//             if(doc && _lastData){
//                 if(activePage >= lastData.length){
//                     if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
//                         lastData.push(_lastData)
//                         lastDataSet(lastData)
//                     }else if(!lastData){
//                         lastDataSet([_lastData])
//                     }
//                 }else{
//                     if(lastData){
//                         lastData.pop()
//                         lastData.push(_lastData)
//                         lastDataSet(lastData)
//                     }else{
//                         lastDataSet([_lastData])
//                     }
//                 }
//             }
//             doc.forEach(doc=>{
//                 let pushArr = {}
//                 let ccName = ""
//                 if(doc.data().cc){
//                     callcenters.forEach(doc2=>{
//                         if(doc2.docId === doc.data().cc){
//                             ccName = doc2.data[0].value
//                         }
//                     })
//                 }
//                 pushArr["docId"] = doc.id
//                 pushArr["data"] = [
//                     { type: "name", value: doc.data().post },
//                     { type: "name", value: doc.data().get },
//                     { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
//                     { type: "avatar", value: doc.data().image },
//                     { type: "text", value: ccName ? ccName : doc.data().cc },
//                     { type: "text", value: doc.data().asa ? doc.data().asa : doc.data().type },
//                     { type: "text", value: doc.data().buy ? "true" : "" },
//                     { type: "text", value: doc.data().visible ? "True" : "False" },
//                     { type: "date", value:  new Date(doc.data().time.toDate()).toLocaleString() },
//                 ]
//                 userList.push(pushArr);

//                 firebase.firestore().collection("users").doc(doc.data().post).get().then(doc=>{
//                     dispatch(usersList(doc))
//                 })
//                 firebase.firestore().collection("users").doc(doc.data().get).get().then(doc=>{
//                     dispatch(usersList(doc))
//                 })
//             })
//             dispatch(MESSAGES_IMAGE_DATA({
//                 list: userList
//             }))
//             dispatch(MESSAGES_IMAGE_END())
//         })
//     }
// }
export { messagesData, changeData};
