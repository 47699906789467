import { createReducer } from 'redux-act';

import {
    AFFIRMATIONS_START,
    AFFIRMATIONS_END,
    AFFIRMATIONS_DATA,
    AFFIRMATIONS_CHANGE
} from '../actions/affirmations';

const initialState = {
  loading: false
};

const authReducer = createReducer(
    {
        [AFFIRMATIONS_START]: (state, payload) => ({
        ...state,
        loading: true
        }),
        [AFFIRMATIONS_DATA]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
        [AFFIRMATIONS_END]: (state, payload) => ({
        ...state,
        loading: false
        }),
        [AFFIRMATIONS_CHANGE]: (state, payload) => {
            return ({
                ...state,
                ...payload
            })
        },
    },
    initialState
);

export default authReducer