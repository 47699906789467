import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { fakesData, changeData} from "../../state/actions/fakes"
import Table from "../../components/Items/table"
import { ButtonSuccess } from "../../components/Items/buttons"
import TableHead from "./TableHead"
import { NavLink } from 'react-router-dom';
import {getParams} from "../../Hooks"
import {onChange} from "../../state/actions/form"

const Fakes = () => {
    const { loading, list, activePage, lastData} = useSelector(
        (state) => ({
            loading: state.fakes.loading,
            list: state.fakes && state.fakes.list ? state.fakes.list : [],
            lastData: state.fakes.lastData ? state.fakes.lastData : [],
            activePage: state.fakes.activePage ? state.fakes.activePage : 0,
        }), shallowEqual
    );
    const dispatch = useDispatch();
    // const [lastData, lastDataSet] = useState([])
    // const [perPage, perPageSet] = useState(10)
    // const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"", key:"meta.nickname"}, 
        {name:"Gender", orderBy:"disable", key:"gender"}, 
        {name:"Country", orderBy:"", key:"meta.country"}, 
        {name:"City", orderBy:"", key:"meta.city"}, 
        {name:"Photos", orderBy:"disable", key:""}, 
        {name:"Photos CC", orderBy:"disable", key:""}, 
        {name:"Note", orderBy:"disable", key:"ref"}, 
        {name:"Time", orderBy:"desc", key:"timestamp"}, 
        {name:"Settings", orderBy:"disable", key:"timestamp"}
    ])

    useEffect(() => {
        window.scrollTo(0, 0);
        if(!getParams("back")){

            dispatch(onChange("ssId", ""))
            dispatch(onChange("search_nickname", ""))
            dispatch(onChange("searchStartDate", ""))
            dispatch(onChange("searchEndDate", ""))
            dispatch(onChange("search_country", ""))
            dispatch(onChange("search_city", ""))

            dispatch(changeData("activePage", 0))
            dispatch(changeData("lastData", 0))
            dispatch(fakesData("first", head))
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        dispatch(changeData("activePage", 0))
        dispatch(changeData("lastData", 0))
        dispatch(fakesData("first", change))
    }
    const changePage = (type) => {
        if(type==="prev"){
            dispatch(changeData("activePage", activePage - 1))
            dispatch(fakesData("", head))
        }else if(type === "next"){
            dispatch(changeData("activePage", activePage + 1))
            dispatch(fakesData("", head))
        }else{
            dispatch(changeData("activePage", type))
            dispatch(fakesData("", head, type))
        }
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className="left">
                </div>
                <div className="right">
                    <NavLink to={"/fakes/add"}>
                        <ButtonSuccess style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0, marginTop:0 }}   text={"Add Fake"} />
                    </NavLink> 
                </div>
            </div>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {dispatch(changeData("activePage", 0));dispatch(changeData(0));dispatch(fakesData("first", head))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="fakes" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Fakes;
