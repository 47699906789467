import { createAction } from 'redux-act';
export const USERSLIST = createAction('USERSLIST');

const usersList = (doc) => {
    return async (dispatch, getState) => {
        if(doc.data()){
            dispatch(USERSLIST({ [doc.id]: {
                id:doc.id,
                nickname:doc.data().meta.nickname,
                avatar:doc.data().meta.avatarThumb,
            } }));
        }
    }
}

export { usersList };
