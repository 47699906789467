import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Index';
import Dashboard from '../pages/Dashboard/Index';
import Newsletter from '../pages/Newsletters/Index';
import UsersEdit from '../pages/Users/Edit';
import UsersDeletedEdit from '../pages/UsersDeleted/Edit';
import Users from '../pages/Users/Index';
import Fakes from '../pages/Fakes/Index';
import FakesEdit from '../pages/Fakes/Edit';
import FakesAdd from '../pages/Fakes/Add';
import Callcenters from '../pages/Callcenters/Index';
import CallcentersEdit from '../pages/Callcenters/Edit';
import CallcenterAdd from '../pages/Callcenters/Add';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import AddNewsletter from '../pages/Newsletters/AddNewsletter';
import AddNewsletter2 from '../pages/Newsletters/AddNewsletter_2';
import AddNewsletter3 from '../pages/Newsletters/AddNewsletter_3';
import NewsletterWaiting from '../pages/Newsletters/Waiting';
import AdminsEdit from '../pages/Admins/Edit';
import Admins from '../pages/Admins/Index';
import AdminAdd from '../pages/Admins/Add';
import Payments from '../pages/Payments/Index';
import AffiliateSystem from '../pages/AffiliateSystem/Index';
import AffiliateAdd from '../pages/AffiliateSystem/Add';
import AffiliateEdit from '../pages/AffiliateSystem/Edit';
import Messages from '../pages/Messages/Index';
import MessagesImage from '../pages/MessagesImage/Index';
import MessagesSend from '../pages/Messages/Send';
import Logs from '../pages/Logs/Index';
import Contacts from '../pages/Contacts/Index';
import Settings from '../pages/Settings/Index';
import UsersDeleted from '../pages/UsersDeleted/Index';
import Affirmations from '../pages/Affirmations/Index';
import AutoMessages from '../pages/AutoMessages/AutoMessages';
import AutoMessagesAdd from '../pages/AutoMessages/AddAutoMessages';
import AutoMessagesSend from '../pages/AutoMessages/SendAutoMessages';
import { ToastProvider } from 'react-toast-notifications'
import Layout from "../components/Layout/Layout"
import { verifyAuth } from '../state/actions/auth';

const RouterComponent = () => {
  const { userData } = useSelector(
    (state) => ({
      userData: state.auth.userData
    }), shallowEqual
  );
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(verifyAuth())
  }, [dispatch]);
  // return <BrowserRouter>
  //   <ToastProvider>
  //     <Routes>
  //       <Route path="/" element={<Home />} />
  //       <Route element={<Home />} />
  //     </Routes>
  //   </ToastProvider>
  // </BrowserRouter>
  if (userData.role === 2) {
    return (
      <BrowserRouter>
        <ToastProvider>
          <Routes>
            <Route exact path={paths.DASHBOARD} element={<PrivateRoute2><Dashboard /></PrivateRoute2>} />
            <Route exact path={paths.USERS_EDIT} element={<PrivateRoute2><UsersEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.USERS} element={<PrivateRoute2><Users /></PrivateRoute2>} />
            <Route exact path={paths.USERS_DELETED} element={<PrivateRoute2><UsersDeleted /></PrivateRoute2>} />
            <Route exact path={paths.MESSAGES_SEND} element={<PrivateRoute2><MessagesSend key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.PAYMENTS} element={<PrivateRoute2><Payments /></PrivateRoute2>} />
            <Route path={paths.ROOT} element={<PrivateRoute1 />} />
            <Route element={<PrivateRoute1 />} />
          </Routes>

        </ToastProvider>
      </BrowserRouter>
    );
  } else {

    return (
      <BrowserRouter>
        <ToastProvider>
          <Routes>
            <Route exact path={paths.DASHBOARD} element={<PrivateRoute2><Dashboard /></PrivateRoute2>} />
            <Route exact path={paths.AUTO_MESSAGES_SEND} element={<PrivateRoute2><AutoMessagesSend key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.AFFILIATE_SYSTEM_EDIT} element={<PrivateRoute2><AffiliateEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.NEWSLETTERS_ADD_3} element={<PrivateRoute2><AddNewsletter3 key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.USERS_EDIT} element={<PrivateRoute2><UsersEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.USERS_DELETED_EDIT} element={<PrivateRoute2><UsersDeletedEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.FAKES_EDIT} element={<PrivateRoute2><FakesEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.USERS_DELETED} element={<PrivateRoute2><UsersDeleted /></PrivateRoute2>} />
            <Route exact path={paths.FAKES_ADD} element={<PrivateRoute2><FakesAdd /></PrivateRoute2>} />
            <Route exact path={paths.AFFILIATE_SYSTEM_ADD} element={<PrivateRoute2><AffiliateAdd /></PrivateRoute2>} />
            <Route exact path={paths.NEWSLETTERS_ADD_2} element={<PrivateRoute2><AddNewsletter2 key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.NEWSLETTER_WAITING} element={<PrivateRoute2><NewsletterWaiting key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.NEWSLETTERS_ADD} element={<PrivateRoute2><AddNewsletter /></PrivateRoute2>} />
            <Route exact path={paths.USERS} element={<PrivateRoute2><Users /></PrivateRoute2>} />
            <Route exact path={paths.FAKES} element={<PrivateRoute2><Fakes /></PrivateRoute2>} />
            <Route exact path={paths.CALLCENTERS_EDIT} element={<PrivateRoute2><CallcentersEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.CALLCENTERS_ADD} element={<PrivateRoute2><CallcenterAdd /></PrivateRoute2>} />
            <Route exact path={paths.ADMINS_EDIT} element={<PrivateRoute2><AdminsEdit key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.MESSAGES_SEND} element={<PrivateRoute2><MessagesSend key={window.location.pathname} /></PrivateRoute2>} />
            <Route exact path={paths.SETTINGS} element={<PrivateRoute2><Settings /></PrivateRoute2>} />
            <Route exact path={paths.AFFIRMATIONS} element={<PrivateRoute2><Affirmations /></PrivateRoute2>} />
            <Route exact path={paths.CONTACTS} element={<PrivateRoute2><Contacts /></PrivateRoute2>} />
            <Route exact path={paths.LOGS} element={<PrivateRoute2><Logs /></PrivateRoute2>} />
            <Route exact path={paths.CALLCENTERS} element={<PrivateRoute2><Callcenters /></PrivateRoute2>} />
            <Route exact path={paths.NEWSLETTERS} element={<PrivateRoute2><Newsletter /></PrivateRoute2>} />
            <Route exact path={paths.ADMINS_ADD} element={<PrivateRoute2><AdminAdd /></PrivateRoute2>} />
            <Route exact path={paths.ADMINS} element={<PrivateRoute2><Admins /></PrivateRoute2>} />
            <Route exact path={paths.PAYMENTS} element={<PrivateRoute2><Payments /></PrivateRoute2>} />
            <Route exact path={paths.AFFILIATE_SYSTEM} element={<PrivateRoute2><AffiliateSystem /></PrivateRoute2>} />
            <Route exact path={paths.MESSAGES} element={<PrivateRoute2><Messages /></PrivateRoute2>} />
            <Route exact path={paths.MESSAGES_IMAGES} element={<PrivateRoute2><MessagesImage /></PrivateRoute2>} />
            <Route exact path={paths.AUTO_MESSAGES_ADD} element={<PrivateRoute2><AutoMessagesAdd /></PrivateRoute2>} />
            <Route exact path={paths.AUTO_MESSAGES} element={<PrivateRoute2><AutoMessages /></PrivateRoute2>} />
            <Route path={paths.ROOT} element={<PrivateRoute1 />} />
            <Route element={<PrivateRoute1 />} />
          </Routes>
        </ToastProvider>
      </BrowserRouter>
    );
  }
};

const PrivateRoute1 = () => {
  const { id } = useSelector(
    state => ({
      id: state.auth.userData.docId
    }),
    shallowEqual
  );

  if (id) {
    return <Navigate to={paths.DASHBOARD} />
  } else {
    return <Home />
  }
};

const PrivateRoute2 = ({ children }) => {
  const { id } = useSelector(
    state => ({
      id: state.auth.userData.docId
    }),
    shallowEqual
  );

  if (id) {
    return <Layout>{children}</Layout>
  } else {
    return <Navigate to={paths.ROOT} />
  }
};


export default RouterComponent;
