import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
const config = {
    apiKey: process.env.REACT_APP_APEY,
    authDomain: "beepr-de.firebaseapp.com",
    databaseURL: "https://beepr-de.firebaseio.com",
    projectId: "beepr-de",
    storageBucket: "beepr-de.appspot.com",
    messagingSenderId: process.env.REACT_APP_APXEXY,
    appId: process.env.REACT_APP_APXEY,
    measurementId: process.env.REACT_APP_AXPXEXY
};
firebase.initializeApp(config);
firebase.database();
firebase.storage();


export default firebase;