import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Styles from "../../assets/styles/Pages.module.scss"
import { getData} from "../../state/actions/newsletters"
import Table from "../../components/Items/table"
import { ButtonPrimary} from "../../components/Items/buttons"
import {defaultNewsletterOptions} from "../../Settings"
import TableHead from "./TableHead"
import {onChange} from "../../state/actions/form"

const Newsletters = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.newsletters.loading,
            list: state.newsletters && state.newsletters.list ? state.newsletters.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"", key:"fakeNickneme"}, 
        {name:"Message", orderBy:"", key:"message"}, 
        {name:"Type", orderBy:"", key:"setting", options:defaultNewsletterOptions}, 
        {name:"Time", orderBy:"desc", key:"timestamp"}, 
        {name:"Users", orderBy:"", key:"totalUser"}, 
        {name:"Visible", orderBy:"disable", key:""}, 
        {name:"Payments", orderBy:"disable", key:""}, 
        {name:"Pay_Value", orderBy:"disable", key:""}, 
        {name:"Status", orderBy:"", key:"status"}, 
        {name:"Settings", orderBy:"disable"}
    ])
    // { type: "text", value: doc.data().visible },
    // { type: "text", value: doc.data().paymentLength },
    // { type: "text", value: doc.data().payment },
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("nStartDate", ""))
        dispatch(onChange("nEndDate", ""))
        dispatch(onChange("nNickname", ""))
        dispatch(onChange("nType", ""))
        dispatch(onChange("sType", ""))
        dispatch(getData("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(getData("first", 0, lastDataSet, perPage, change, false, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(getData("", lastData, lastDataSet, perPage, head, false, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(getData("", lastData, lastDataSet, perPage, head, false, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(getData("", lastData, lastDataSet, perPage, head, false, type))
        }
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className="left">
                    <NavLink to="/newsletters/add-newsletter">
                        <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text={"Add Newsletter"} />
                    </NavLink>
                </div>
                <div className="right">
                </div>
            </div>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(getData("first", 0, lastDataSet, perPage, head, false, 0))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="newsletter" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Newsletters;
