import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { usersData, changeData} from "../../state/actions/users"
import Table from "../../components/Items/table"
import TableTotal from "../../components/Items/tableTotal"
import TableHead from "./TableHead"
import Affiliates from "./Affiliates"
import {getParams} from "../../Hooks"
import { onChange } from '../../state/actions/form';

const Users = () => {
    const { loading, list, lastData, activePage, totalPage} = useSelector(
        (state) => ({
            loading: state.users.loading,
            list: state.users.list ? state.users.list : [],
            lastData: state.users.lastData ? state.users.lastData : [],
            activePage: state.users.activePage ? state.users.activePage : 0,
            totalPage: state.users.totalPage ? state.users.totalPage : 0,
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [head, headSet] = useState([
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"", key:"meta.nickname"}, 
        {name:"Email", orderBy:"", key:"meta.email"}, 
        {name:"Gender", orderBy:"disable", key:"gender"}, 
        {name:"Country", orderBy:"", key:"meta.country"}, 
        {name:"City", orderBy:"", key:"meta.city"}, 
        {name:"Zip", orderBy:"", key:"meta.zip"}, 
        {name:"Online", orderBy:"", key:"online"}, 
        {name:"Ref", orderBy:"", key:"ref"}, 
        {name:"Verify", orderBy:"", key:"verify"}, 
        {name:"Payments", orderBy:"", key:"pay"}, 
        {name:"Coin", orderBy:"", key:"coin"}, 
        {name:"Time", orderBy:"desc", key:"timestamp"}, 
        {name:"Settings", orderBy:"disable", key:"timestamp"}
    ])

    useEffect(() => {
        window.scrollTo(0, 0);
        if(!getParams("back")){
            dispatch(onChange("search_id", ""))
            dispatch(onChange("search_nickname", ""))
            dispatch(onChange("searchStartDate", ""))
            dispatch(onChange("searchEndDate", ""))
            dispatch(onChange("search_verify", ""))
            dispatch(onChange("search_ref", ""))
            dispatch(onChange("search_country", ""))
            dispatch(onChange("search_city", ""))

            dispatch(changeData("activePage", 0))
            dispatch(changeData("totalPage", 0))
            dispatch(changeData("lastData", 0))
            dispatch(usersData("first", head))
        }
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        dispatch(changeData("activePage", 0))
        dispatch(changeData("totalPage", 0))
        dispatch(changeData("lastData", 0))
        dispatch(usersData("first", change))
    }
    const changePage = (type) => {
        console.log(totalPage);
        if(type==="prev"){
            dispatch(changeData("activePage", activePage - 1))
            if(!totalPage){
                dispatch(usersData("", head))
            }
        }else if(type === "next"){
            dispatch(changeData("activePage", activePage + 1))
            if(!totalPage){
                dispatch(usersData("", head))
            }
        }else{
            dispatch(changeData("activePage", type))
            if(!totalPage){
                dispatch(usersData("", head))
            }
        }
    }
    return (
        <section className={Styles.Pages}>
            {totalPage ? <div className={Styles.contentWhite} style={{marginBottom:20}}>
                <Affiliates list={list} />
            </div> : ""}
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {dispatch(changeData("activePage", 0));dispatch(changeData(0));dispatch(usersData("first", head))}} onsearchtotal={(e) => {dispatch(changeData("activePage", 0));dispatch(changeData(0));dispatch(changeData("totalPage", 0));dispatch(usersData("first", head, true))}} />
                {!totalPage ?<Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="users" />
                : <TableTotal class={"type_2"} head={head} lastData={lastData} activePage={activePage} totalPage={totalPage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="users" />}
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
