import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers/Index';
import { verifyAuth } from './actions/auth';
import { logsData2 } from './actions/logs';
import { newsletterEnd } from './actions/newsletters';
import { defaultColorsSet, Packets, giftsData, generalAffiliates } from './actions/general';

export const configureStore = initialState => {
  const middlewares = [];

  const composeEnhancers =
    (process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null) || compose;

  middlewares.push(applyMiddleware(thunk));

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...middlewares)
  );
  defaultColorsSet();
  store.dispatch(generalAffiliates());
  store.dispatch(Packets());
  // store.dispatch(verifyAuth());
  store.dispatch(giftsData());

  const persistor = persistStore(store);

  return { store, persistor };
};
