import { createAction } from 'redux-act';
import firebase from '../../firebase.js';

export const ONLINE_USERS = createAction('ONLINE_USERS');
export const ONLINE_LOGS_DATA = createAction('ONLINE_LOGS_DATA');
export const ONLINE_USERS_RESET = createAction('ONLINE_USERS_RESET');
const onlineUsers = (type) => {
    return (dispatch, getState) => {
        firebase.database().ref('online').on('value', (snapshot) => {
            let list = []
            snapshot.forEach(doc=>{
                list.push(doc.key)
                dispatch(onlineUsersData(doc.key))
            })
            dispatch(ONLINE_USERS(list))
        });
    }
}
const onlineUsersReset = () => {
    return (dispatch, getState) => {
        dispatch(ONLINE_USERS_RESET())
    }
}

const onlineUsersData = (logId) =>{
    return (dispatch, getState) => {
        const logs = getState().online ? getState().online : [];
        if(!logs[logId]){
            firebase.firestore().collection("logs").doc(logId).onSnapshot( async doc=>{
                const logs2 = getState().online ? getState().online : [];
                let arr = doc.data()
                arr["id"] = doc.id
                if(doc.data().u){
                    if(logs2[doc.id] && logs2[doc.id].nickname){
                    }else{
                        firebase.firestore().collection("users").doc(doc.data().u).get().then(userDoc=>{
                            if(!userDoc.data()){
                                return null
                            }
                            arr["nickname"] = userDoc.data().meta.nickname;
                            dispatch(ONLINE_LOGS_DATA({[doc.id]: arr}))
                        })
                    }
                }else if(!logs2[doc.id]){
                    dispatch(ONLINE_LOGS_DATA({[doc.id]: arr}))
                }
            })
        }
        // else if(logs[logId].nickname === "Visitor"){
            
        // }
    }
}
  

export { onlineUsers, onlineUsersData, onlineUsersReset };
