import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { usersData} from "../../state/actions/callcenters"
import Table from "../../components/Items/table"
import { NavLink } from 'react-router-dom';
import { ButtonSuccess } from "../../components/Items/buttons"
import TableHead from "./TableHead"
import {onChange} from "../../state/actions/form"

const Users = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.callcenters.loading,
            list: state.callcenters && state.callcenters.list ? state.callcenters.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Nickname", orderBy:"", key:"nickname"}, 
        {name:"Email", orderBy:"", key:"email"}, 
        {name:"Online", orderBy:"", key:"online"}, 
        {name:"Cadmin", orderBy:"", key:"cadmin"}, 
        {name:"Total In", orderBy:"", key:"total_get"}, 
        {name:"Total Out", orderBy:"", key:"total_post"}, 
        {name:"Total Asa", orderBy:"", key:"total_asa1"}, 
        {name:"Last Login", orderBy:"desc", key:"lastLogin"}, 
        {name:"Settings", orderBy:"disable", key:""}
    ])

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("search_id", ""))
        dispatch(onChange("search_nickname", ""))
        dispatch(onChange("search_cadmin", ""))
        dispatch(usersData("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(usersData("first", 0, lastDataSet, perPage, change, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(usersData("", lastData, lastDataSet, perPage, head, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(usersData("", lastData, lastDataSet, perPage, head, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(usersData("", lastData, lastDataSet, perPage, head, type))
        }
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className="left">
                </div>
                <div className="right">
                    <NavLink to={"/callcenters/add"}>
                        <ButtonSuccess style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0, marginTop:0 }}   text={"Add Callcenter"} />
                    </NavLink> 
                </div>
            </div>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(usersData("first", 0, lastDataSet, perPage, head, 0))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="callcenters" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
