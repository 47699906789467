import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import Chart from 'react-apexcharts'
import {ButtonPrimary} from "../../components/Items/buttons"
import DateRange from "../../components/Items/dateRange"
import {logstStat} from "../../state/actions/logs"
const Index = () => {
  const { web, device, loading, userData} = useSelector(
    (state) => ({
      web: state.logs.web ? state.logs.web : [],
      device: state.logs.device ? state.logs.device : [],
      loading: state.logs.loading,
      userData: state.auth.userData,
    }), shallowEqual
  );


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logstStat())
  }, [dispatch]);
  const options = {
    chart:{height:260,type:"bar",toolbar:{show:!1}},
    plotOptions:{bar:{columnWidth:"25%",endingShape:"rounded"}},
    dataLabels:{enabled:!1},
    colors:["#5a8dee","#B6CDF8"],
    fill:{type:"gradient",gradient:{shade:"light",type:"vertical",inverseColors:!0,opacityFrom:1,opacityTo:1,stops:[0,70,100]}},
    // categories:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep"], 
    xaxis:{axisBorder:{show:!1},axisTicks:{show:!1},labels:{style:{colors:"var(--gray-5)", fontSize:10}}},
    yaxis:{tickAmount:3,labels:{style:{color:"var(--gray-5)"}}},
    legend:{show:!1},
    // tooltip:{y:{formatter:function(e){return"$ "+e+" thousands"}}}
  }
    //   , {name:"Top", data:[10-waiting.message,10-waiting.waiting,10-waiting.asa1,10-waiting.asa2,10-waiting.asa3]
  const series = [{name:"2020",data:web}]
  const options2 = {
    chart:{type:"radialBar"},
    grid:{show:!1,padding:{left:-6,right:-6,top:0}},
    colors:["#70bf98"],
    plotOptions:{radialBar:{hollow:{size:"35%"},dataLabels:{showOn:"always",name:{show:!1},value:{show:!1}}}},
    fill:{type:"gradient",gradient:{shade:"light",type:"horizontal",gradientToColors:["#70bf98"],opacityFrom:1,opacityTo:.8,stops:[0,70,100]}},
    stroke:{lineCap:"round"}
  }
  const options3 = {
    chart:{type:"radialBar"},
    grid:{show:!1,padding:{left:-6,right:-6,top:0}},
    colors:["#f9c681"],
    plotOptions:{radialBar:{hollow:{size:"35%"},dataLabels:{showOn:"always",name:{show:!1},value:{show:!1}}}},
    fill:{type:"gradient",gradient:{shade:"light",type:"horizontal",gradientToColors:["#f9c681"],opacityFrom:1,opacityTo:.8,stops:[0,70,100]}},
    stroke:{lineCap:"round"}
  }
  const options4 = {
    chart:{type:"radialBar"},
    grid:{show:!1,padding:{left:-6,right:-6,top:0}},
    colors:["#f79293"],
    plotOptions:{radialBar:{hollow:{size:"35%"},dataLabels:{showOn:"always",name:{show:!1},value:{show:!1}}}},
    fill:{type:"gradient",gradient:{shade:"light",type:"horizontal",gradientToColors:["#f79293"],opacityFrom:1,opacityTo:.8,stops:[0,70,100]}},
    stroke:{lineCap:"round"}
  }
  if(userData.role === 2){
    return null;
  }
  return (
    <div id="onlineUsers" className={classNames(styles.onlineUsers, styles.box, styles.h2)} style={{marginRight:0}}>
      {loading ?<div className="loading"></div> : ""}
      <div className={styles.title} style={{marginBottom:0}}>
          <h3>
          Web Analytics
          </h3>
          <div className={styles.buttons}>
            <ButtonPrimary style={{height:30, width:70, fontSize:12, padding:0, marginRight:15}} styletype="type_1" text={"Today"} />
            <DateRange name1="startDate2" name2="endDate2" styletype="type_1" onsearch={logstStat} />
          </div>
      </div>
      <div className={styles.px}>
        <div className={styles.chartList}>
          <div className={styles.item}>
            <div className={styles.head}>
              <svg id="Layer_1" viewBox="0 0 511.248 511.248" xmlns="http://www.w3.org/2000/svg">
                <path fill="" d="m239.301 239.301v-75.078c-29.203-1.642-57.634-7.704-84.842-18.086-7.911 28.611-12.628 60.194-13.8 93.164z"/>
                <path fill="" d="m239.301 347.026v-75.078h-98.642c1.172 32.97 5.889 64.553 13.8 93.164 27.208-10.382 55.639-16.445 84.842-18.086z"/>
                <path fill="" d="m164.714 115.204c23.642 9.146 48.727 14.669 74.586 16.311v-104.472c-35.847 10.733-60.867 53.215-74.586 88.161z"/>
                <path fill="" d="m346.534 396.044c-23.642-9.146-48.727-14.669-74.586-16.311v104.471c35.848-10.732 60.867-53.214 74.586-88.16z"/>
                <path fill="" d="m107.999 239.301c1.259-37.711 6.869-73.94 16.383-106.657-22.804-11.869-43.789-26.737-62.679-44.394-36.715 42.407-58.201 95.001-61.703 151.051z"/>
                <path fill="" d="m403.249 271.947c-1.259 37.711-6.869 73.94-16.383 106.657 22.804 11.869 43.789 26.737 62.679 44.394 36.715-42.407 58.201-95.001 61.703-151.051z"/>
                <path fill="" d="m107.999 271.947h-107.999c3.502 56.05 24.988 108.644 61.703 151.051 18.89-17.657 39.875-32.524 62.679-44.394-9.514-32.717-15.124-68.946-16.383-106.657z"/>
                <path fill="" d="m239.301 484.205v-104.472c-25.86 1.641-50.944 7.164-74.586 16.311 13.74 35.003 38.765 77.436 74.586 88.161z"/>
                <path fill="" d="m376.173 409.821c-15.424 38.636-40.513 78.926-76.958 98.277 47.352-8.072 91.286-29.275 127.455-61.802-15.457-14.362-32.407-26.569-50.497-36.475z"/>
                <path fill="" d="m135.075 409.821c-18.09 9.906-35.041 22.114-50.497 36.476 36.169 32.526 80.103 53.73 127.455 61.802-36.45-19.355-61.538-59.651-76.958-98.278z"/>
                <path fill="" d="m135.075 101.427c15.424-38.636 40.513-78.926 76.958-98.277-47.352 8.072-91.286 29.275-127.455 61.802 15.457 14.362 32.407 26.569 50.497 36.475z"/>
                <path fill="" d="m271.947 271.947v75.078c29.203 1.642 57.634 7.704 84.842 18.086 7.911-28.611 12.628-60.194 13.8-93.164z"/>
                <path fill="" d="m386.866 132.644c9.514 32.717 15.124 68.946 16.383 106.657h107.999c-3.502-56.05-24.988-108.644-61.703-151.051-18.89 17.657-39.875 32.524-62.679 44.394z"/>
                <path fill="" d="m271.947 164.222v75.078h98.642c-1.172-32.97-5.889-64.553-13.8-93.164-27.208 10.382-55.639 16.445-84.842 18.086z"/>
                <path fill="" d="m271.947 27.043v104.471c25.86-1.641 50.944-7.164 74.586-16.311-13.74-35.002-38.765-77.435-74.586-88.16z"/>
                <path fill="" d="m299.215 3.15c36.45 19.354 61.538 59.649 76.958 98.277 18.09-9.906 35.041-22.114 50.497-36.476-36.17-32.525-80.103-53.729-127.455-61.801z"/>
              </svg>
              Web
            </div>
            <div className={styles.chart}>
              <Chart options={options2} series={[(device.web * 100) / (device.web + device.iphone + device.android) ]}  style={{width:80}} height={80} type="radialBar" />
              <b>{device.web}</b>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.head}>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <path fill="" d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734   c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z"/>
                  <path fill="" d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z"/>
                </g>
              </svg>
              Iphone
            </div>
            <div className={styles.chart}>
              <Chart options={options3} series={[(device.iphone * 100) / (device.web + device.iphone + device.android) ]}  style={{width:80}} height={80} type="radialBar" />
              <b>{device.iphone}</b>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.head}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 553.048 553.048">
                <g>
                  <path fill="" d="M76.774,179.141c-9.529,0-17.614,3.323-24.26,9.969c-6.646,6.646-9.97,14.621-9.97,23.929v142.914
                    c0,9.541,3.323,17.619,9.97,24.266c6.646,6.646,14.731,9.97,24.26,9.97c9.522,0,17.558-3.323,24.101-9.97
                    c6.53-6.646,9.804-14.725,9.804-24.266V213.039c0-9.309-3.323-17.283-9.97-23.929C94.062,182.464,86.082,179.141,76.774,179.141z"
                    />
                  <path fill="" d="M351.972,50.847L375.57,7.315c1.549-2.882,0.998-5.092-1.658-6.646c-2.883-1.34-5.098-0.661-6.646,1.989l-23.928,43.88
                    c-21.055-9.309-43.324-13.972-66.807-13.972c-23.488,0-45.759,4.664-66.806,13.972l-23.929-43.88
                    c-1.555-2.65-3.77-3.323-6.646-1.989c-2.662,1.561-3.213,3.764-1.658,6.646l23.599,43.532
                    c-23.929,12.203-42.987,29.198-57.167,51.022c-14.18,21.836-21.273,45.698-21.273,71.628h307.426
                    c0-25.924-7.094-49.787-21.273-71.628C394.623,80.045,375.675,63.05,351.972,50.847z M215.539,114.165
                    c-2.552,2.558-5.6,3.831-9.143,3.831c-3.55,0-6.536-1.273-8.972-3.831c-2.436-2.546-3.654-5.582-3.654-9.137
                    c0-3.543,1.218-6.585,3.654-9.137c2.436-2.546,5.429-3.819,8.972-3.819s6.591,1.273,9.143,3.819
                    c2.546,2.558,3.825,5.594,3.825,9.137C219.357,108.577,218.079,111.619,215.539,114.165z M355.625,114.165
                    c-2.441,2.558-5.434,3.831-8.971,3.831c-3.551,0-6.598-1.273-9.145-3.831c-2.551-2.546-3.824-5.582-3.824-9.137
                    c0-3.543,1.273-6.585,3.824-9.137c2.547-2.546,5.594-3.819,9.145-3.819c3.543,0,6.529,1.273,8.971,3.819
                    c2.438,2.558,3.654,5.594,3.654,9.137C359.279,108.577,358.062,111.619,355.625,114.165z"/>
                  <path fill="" d="M123.971,406.804c0,10.202,3.543,18.838,10.63,25.925c7.093,7.087,15.729,10.63,25.924,10.63h24.596l0.337,75.454
                    c0,9.528,3.323,17.619,9.969,24.266s14.627,9.97,23.929,9.97c9.523,0,17.613-3.323,24.26-9.97s9.97-14.737,9.97-24.266v-75.447
                    h45.864v75.447c0,9.528,3.322,17.619,9.969,24.266s14.73,9.97,24.26,9.97c9.523,0,17.613-3.323,24.26-9.97
                    s9.969-14.737,9.969-24.266v-75.447h24.928c9.969,0,18.494-3.544,25.594-10.631c7.086-7.087,10.631-15.723,10.631-25.924V185.45
                    H123.971V406.804z"/>
                  <path fill="" d="M476.275,179.141c-9.309,0-17.283,3.274-23.93,9.804c-6.646,6.542-9.969,14.578-9.969,24.094v142.914
                    c0,9.541,3.322,17.619,9.969,24.266s14.627,9.97,23.93,9.97c9.523,0,17.613-3.323,24.26-9.97s9.969-14.725,9.969-24.266V213.039
                    c0-9.517-3.322-17.552-9.969-24.094C493.888,182.415,485.798,179.141,476.275,179.141z"/>
                </g>
              </svg>
              Android
            </div>
            <div className={styles.chart}>
              <Chart options={options4} series={[(device.android * 100) / (device.web + device.iphone + device.android) ]} style={{width:80}} height={80} type="radialBar" />
              <b>{device.android}</b>
            </div>
          </div>
        </div>
      </div>

      <Chart options={options} series={series} style={{width:"100%"}} height={300} type="bar" />
    </div>
  );
};

export default Index;
