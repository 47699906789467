import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';
import { readAndCompressImage } from 'browser-image-resizer';
import { makeid } from "../../Hooks"
import { onChange } from "./form"
export const AUTO_MESSAGES_START = createAction('AUTO_MESSAGES_START');
export const AUTO_MESSAGES_END = createAction('AUTO_MESSAGES_END');
export const AUTO_MESSAGES_DATA = createAction('AUTO_MESSAGES_DATA');
export const AUTO_MESSAGES_CHANGE = createAction('AUTO_MESSAGES_CHANGE');
export const AUTO_MESSAGES_SEARCH_FAKE = createAction('AUTO_MESSAGES_SEARCH_FAKE');


const config = {
    quality: 1,
    maxWidth: 1920,
    maxHeight: 1920,
    autoRotate: true,
    debug: true
};


const deleteAutoMessages = (id) => {
    firebase.firestore().collection("auto_messages").doc(id).delete()
}

const Data = (first, head, ref) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let users = getState().users
        dispatch(AUTO_MESSAGES_START())
        let fireData = firebase.firestore().collection("auto_messages")
        if (form.search_nickname) {
            fireData = fireData.where("nickname", "==", form.search_nickname.toLowerCase());
        } else if (form.search_id) {
            fireData = fireData.where("userId", "==", form.search_id.toLowerCase());
        } else {
            if (form.searchStartDate) {
                fireData = fireData.where("time", ">=", new Date(form.searchStartDate))
            }
            if (form.searchEndDate) {
                fireData = fireData.where("time", "<=", new Date(form.searchEndDate))
            }
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (users.lastData && users.lastData[(users.activePage - 1)] && first === "") {
            fireData = fireData.startAfter(users.lastData[(users.activePage - 1)])
        }
        fireData.onSnapshot(doc => {
            let userList = []
            if (!doc.size) {
                dispatch(AUTO_MESSAGES_DATA({
                    list: userList
                }))
                dispatch(AUTO_MESSAGES_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (users.lastData && (users.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    users.lastData.push(_lastData)
                } else if (!users.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["docData"] = doc.data()
                pushArr["data"] = [
                    { type: "bold", value: doc.data().fake_name, sub: doc.data().fake_id },
                    { type: "textAlt", value: doc.data().p_1 ? "Photo" : doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message, sub: doc.data().p_2 ? "Photo" : doc.data().message2 },
                    { type: "avatar", value: doc.data().p_1 ? doc.data().p_1 : "" },
                    { type: "avatar", value: doc.data().p_2 ? doc.data().p_2 : "" },
                    { type: "badge", value: doc.data().time, class: "success" },
                    { type: "badge", value: doc.data().type, sub: doc.data().ref, class: "primary" },
                    { type: "date", value: doc.data().timestamp ? new Date(doc.data().timestamp.toDate()).toLocaleString() : new Date().toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(AUTO_MESSAGES_DATA({
                list: userList
            }))
            dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            dispatch(AUTO_MESSAGES_END())
        })
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(AUTO_MESSAGES_CHANGE({
            [name]: value
        }))
    }
}



const photoUpdate = (name, e) => {
    return async (dispatch, getState) => {
        dispatch(AUTO_MESSAGES_START())
        await readAndCompressImage(e.files[0], config).then(resizedImage => {
            let randomid = makeid(12)
            firebase.storage().ref('autoMessages/' + randomid + '' + e.files[0].name).put(resizedImage).then(doc => {
                firebase.storage().ref('autoMessages/' + randomid + '' + e.files[0].name).getDownloadURL().then(url => {
                    dispatch(onChange(name, url))
                })
            })
        });
        dispatch(AUTO_MESSAGES_END())
    }
}
const photoDelete = (name) => {
    return async (dispatch, getState) => {
        dispatch(onChange(name, ""))
    }
}
// const saveChanges = (fakeId) => {
//     return async (dispatch, getState) => {
//         let form = getState().form
//         dispatch(AUTO_MESSAGES_START())
//         await firebase.firestore().collection("auto_messages").add({
//             timestamp: firebase_app.firestore.FieldValue.serverTimestamp(),
//             fakeId: fakeId,
//             message: form.autoMessages_message,
//             photo: form.autoMessages_image,
//             time: form.autoMessages_time
//         }).then(doc => {
//             firebase.firestore().collection("auto_messages").doc(doc.id).update({
//                 docId: doc.id
//             })
//         })
//         dispatch(AUTO_MESSAGES_END())
//     }
// }






export const saveData = (fakeId, history) => {
    return async (dispatch, getState) => {

        let form = getState().form
        dispatch(AUTO_MESSAGES_START())
        let fakeData = await firebase.firestore().collection("users").doc(fakeId).get()
        if (fakeData.data()) {
            await firebase.firestore().collection("auto_messages").add({
                timestamp: firebase_app.firestore.FieldValue.serverTimestamp(),
                fake_id: fakeId,
                fake_name: fakeData.data().meta.nickname,
                message: form.autoMessages_message,
                message2: form.autoMessages_message2 ? form.autoMessages_message2 : "",
                p_1: form.autoMessages_image ? form.autoMessages_image : "",
                p_2: form.autoMessages_image2 ? form.autoMessages_image2 : "",
                c_p_1: form.autoMessages_image_coin ? form.autoMessages_image_coin : "",
                c_p_2: form.autoMessages_image_coin2 ? form.autoMessages_image_coin2 : "",
                ref: form.autoMessages_ref ? form.autoMessages_ref : "",
                type: form.autoMessages_type ? form.autoMessages_type : "first"
            }).then(doc => {
                firebase.firestore().collection("auto_messages").doc(doc.id).update({
                    docId: doc.id
                })
            })
        }
        dispatch(AUTO_MESSAGES_END())

        dispatch(onChange("autoMessages_message", ""))
        dispatch(onChange("autoMessages_message2", ""))
        dispatch(onChange("autoMessages_image", ""))
        dispatch(onChange("autoMessages_image2", ""))
        dispatch(onChange("autoMessages_image_coin", ""))
        dispatch(onChange("autoMessages_image_coin2", ""))
        dispatch(onChange("autoMessages_ref", ""))
        dispatch(onChange("nNickname", ""))
        dispatch(onChange("sType", ""))
        dispatch(onChange("nStartDate", ""))
        dispatch(onChange("nEndDate", ""))
        dispatch(onChange("sId", ""))
        dispatch(onChange("sNickname", ""))
        dispatch(onChange("sRange", ""))
        dispatch(onChange("sGender", ""))
        dispatch(onChange("sCountry", ""))
        dispatch(onChange("sCity", ""))
        dispatch(onChange("sStartDate", ""))
        dispatch(onChange("sEndDate", ""))
        dispatch(onChange("ssId", ""))
        dispatch(onChange("edit_search_online", ""))
        dispatch(onChange("edit_filter_country", ""))
        dispatch(onChange("edit_filter_city", ""))
        dispatch(onChange("edit_search_nickname_newsletter", ""))
        dispatch(onChange("edit_search_range2", ""))
        dispatch(onChange("edit_search_gender2", ""))
        dispatch(onChange("edit_search_avatar", ""))
        dispatch(onChange("edit_filter_payment", ""))
        dispatch(onChange("edit_filter_coin_up", ""))
        dispatch(onChange("edit_filter_coin_down", ""))
        dispatch(onChange("ssStartDate", ""))
        dispatch(onChange("ssEndDate", ""))

        setTimeout(doc => { history.push("/auto-messages") }, 500)


    }
}





const search = (first, lastData, lastDataSet, perPage, head, tekrar, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form

        let fireData = firebase.firestore().collection("users").where("role", "==", 1)

        if (form.sId) {
            fireData = fireData.where("docId", "==", form.sId)
        } else {
            if (form.sNickname) {
                fireData = fireData.where("meta.nickname_search", "==", form.sNickname.toLowerCase())
            }
            if (form.sGender) {
                fireData = fireData.where("meta.gender", "==", Number(form.sGender))
            }
            if (form.sRange) {
                fireData = fireData.where("range", "==", Number(form.sRange))
            }
            if (form.sCountry) {
                fireData = fireData.where("meta.country", "==", form.sCountry.toLowerCase())
            }
            if (form.sCity) {
                fireData = fireData.where("meta.city", "==", form.sCity.toLowerCase())
            }
            if (form.sStartDate) {
                fireData = fireData.where("timestamp", ">=", new Date(form.sStartDate))
            }
            if (form.sEndDate) {
                fireData = fireData.where("timestamp", "<=", new Date(form.sEndDate))
            }
        }

        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        fireData.limit(perPage).get().then(doc => {
            let userList = []
            if (!doc.size) {
                dispatch(AUTO_MESSAGES_SEARCH_FAKE({
                    list: userList
                }))
                dispatch(AUTO_MESSAGES_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (!tekrar && doc && _lastData) {
                if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                } else if (!lastData) {
                    lastDataSet([_lastData])
                }
            } else {
                return null
            }

            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip },
                    { type: "text", value: doc.data().newsletter ? "Yes" : "" },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(AUTO_MESSAGES_SEARCH_FAKE({
                list: userList
            }))
        })
    }
}
export { search, changeData, Data, deleteAutoMessages, photoUpdate, photoDelete };



// import { createAction } from 'redux-act';
// import firebase from '../../firebase.js';
// import { makeid } from "../../Hooks"
// import { onChange } from "./form"
// import firebase_app from 'firebase/compat/app';
// import { readAndCompressImage } from 'browser-image-resizer';

// export const AUTO_MESSAGES_START = createAction('AUTO_MESSAGES_START');
// export const AUTO_MESSAGES_END = createAction('AUTO_MESSAGES_END');
// export const AUTO_MESSAGES_LIST = createAction('AUTO_MESSAGES_LIST');
// export const AUTO_MESSAGES_SEARCH_FAKE = createAction('AUTO_MESSAGES_SEARCH_FAKE');


// const config = {
//     quality: 1,
//     maxWidth: 1920,
//     maxHeight: 1920,
//     autoRotate: true,
//     debug: true
// };



// async function asyncForEach(array, callback) {
//     for (let index = 0; index < array.length; index++) {
//         await callback(array[index], index, array);
//     }
// }

// export const Data = () => {
//     return async (dispatch, getState) => {
//         let list = [];
//         dispatch(AUTO_MESSAGES_START())
//         // console.log(new Date(new Date().setMonth(new Date().getMonth() - 1)))
//         firebase.firestore().collection("auto_messages").orderBy("timestamp", "desc").get().then(async doc => {
//             await asyncForEach(doc.docs, async (doc) => {
//                 // console.log(doc.data().timestamp.toDate())
//                 let fakeUsers = await firebase.firestore().collection("users").doc(doc.data().fakeId).get()
//                 let pushArr = []
//                 pushArr["docId"] = doc.id
//                 pushArr["data"] = [
//                     { type: "avatar", value: fakeUsers.data().meta.avatarThumb },
//                     { type: "bold", value: fakeUsers.data().meta.nickname, sub: doc.data().fakeId },
//                     { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
//                     { type: "avatar", value: doc.data().photo },
//                     { type: "badge", value: doc.data().time, class: "success" },
//                     { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
//                 ]
//                 list.push(pushArr);
//             })
//             dispatch(AUTO_MESSAGES_LIST({
//                 list: list,
//             }))
//         })
//     }
// }
// export const search = (e) => {
//     return async (dispatch, getState) => {
//         let form = getState().form

//         let firebaseSearch = firebase.firestore().collection("users").where("role", "==", 1)
//         if (form.edit_fake_nickname) {
//             firebaseSearch = firebaseSearch.where("meta.nickname_search", "==", form.edit_fake_nickname)
//         }
//         if (form.edit_fake_id) {
//             firebaseSearch = firebaseSearch.where("docId", "==", form.edit_fake_id)
//         }
//         if (form.edit_search_gender || form.edit_search_gender === 0) {
//             firebaseSearch = firebaseSearch.where("meta.gender", "==", Number(form.edit_search_gender))
//         }
//         if (form.edit_search_range || form.edit_search_range === 0) {
//             firebaseSearch = firebaseSearch.where("range", "==", Number(form.edit_search_range))
//         }
//         firebaseSearch.limit(50).get().then(doc => {
//             let userList = []
//             doc.forEach(doc => {
//                 let pushArr = {}
//                 // let payments = await firebase.firestore().collection("payments").where("function", "==", "ok").where("userid", "==", doc.id).get()
//                 // let pay = 0;
//                 // payments.forEach(doc => {
//                 //     pay = pay + doc.data().pay2
//                 // })
//                 pushArr["docId"] = doc.id
//                 // console.log(new Date(doc.data().timestamp.toDate()).toLocaleString())
//                 pushArr["data"] = [
//                     { type: "avatar", value: doc.data().meta.avatarThumb },
//                     { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
//                     { type: "text", value: doc.data().meta.country },
//                     { type: "text", value: doc.data().meta.city },
//                     { type: "text", value: doc.data().meta.zip },
//                     { type: "text", value: doc.data().photos.length },
//                     { type: "text", value: doc.data().photos_callcenter && doc.data().photos_callcenter.length },
//                     // { type: "text", value: pay },
//                 ]
//                 // console.log(pushArr)
//                 userList.push(pushArr);
//             })
//             dispatch(AUTO_MESSAGES_SEARCH_FAKE({
//                 list: userList
//             }))
//         })
//     }
// }
// export const photoUpdate = (e) => {
//     return async (dispatch, getState) => {
//         dispatch(AUTO_MESSAGES_START())
//         await readAndCompressImage(e.files[0], config).then(resizedImage => {
//             let randomid = makeid(12)
//             firebase.storage().ref('autoMessages/' + randomid + '' + e.files[0].name).put(resizedImage).then(doc => {
//                 firebase.storage().ref('autoMessages/' + randomid + '' + e.files[0].name).getDownloadURL().then(url => {
//                     dispatch(onChange("autoMessages_image", url))
//                 })
//             })
//         });
//         dispatch(AUTO_MESSAGES_END())
//     }
// }
// export const photoDelete = (e) => {
//     return async (dispatch, getState) => {
//         dispatch(onChange("autoMessages_image", ""))
//     }
// }
// export const saveChanges = (fakeId) => {
//     return async (dispatch, getState) => {
//         let form = getState().form
//         dispatch(AUTO_MESSAGES_START())
//         await firebase.firestore().collection("auto_messages").add({
//             timestamp: firebase_app.firestore.FieldValue.serverTimestamp(),
//             fakeId: fakeId,
//             message: form.autoMessages_message,
//             photo: form.autoMessages_image,
//             time: form.autoMessages_time
//         }).then(doc => {
//             firebase.firestore().collection("auto_messages").doc(doc.id).update({
//                 docId: doc.id
//             })
//         })
//         dispatch(AUTO_MESSAGES_END())
//     }
// }