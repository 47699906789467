import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "./Styles.module.scss"
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import TrashIcon from "../../assets/img/icons/trash.svg"
import { ButtonDanger, ButtonPrimary, ButtonSuccess } from "../../components/Items/buttons"
import { onChange, getSettings, photoUpdate, changePacket, deletePacket, addPacket, SavePacket, settingsSave } from '../../state/actions/settings';

const Users = () => {
    const { settings, loading} = useSelector(
        (state) => ({
            settings: state.settings,
            loading: state.settings.loading,
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [tabs, setTabs] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getSettings())
        // eslint-disable-next-line
    }, [dispatch]);
    return (
        <section className={Styles.Users}>
        <div className={Styles.headerBottom}>
            <div className="left">
            </div>
            <div className="right">
                    <ButtonSuccess onClick={()=> dispatch(settingsSave())} style={{ borderRadius: 5, width:200, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0, marginTop:0 }}   text={"Save"} />
            </div>
        </div>
            <div className={Styles.contentWhite}>

                <ul className={Styles.tabHeader}>
                    <li className={tabs === 0 ? Styles.active : ""} onClick={(e) => setTabs(0)}>
                        General
                    </li>
                    <li className={tabs === 1 ? Styles.active : ""} onClick={(e) => setTabs(1)}>
                        Prize
                    </li>
                    <li className={tabs === 2 ? Styles.active : ""} onClick={(e) => setTabs(2)}>
                        Info
                    </li>
                    <li className={tabs === 3 ? Styles.active : ""} onClick={(e) => setTabs(3)}>
                        Faq
                    </li>
                    <li className={tabs === 4 ? Styles.active : ""} onClick={(e) => setTabs(4)}>
                        Packets
                    </li>

                    {settings.templates && settings.templates.map((doc,index) =>{
                        return <li key={index} className={tabs === (index + 5) ? Styles.active : ""} onClick={(e) => setTabs(index + 5)}>
                        Template {doc.id}
                    </li>
                    })}

                </ul>
                <ul className={Styles.tabContent}>
                    <li className={tabs === 0 ? Styles.active : ""}>
                        <div className={Styles.center}>
                            <div className={Styles.formGroup}>
                                <label>Title</label>
                                <input type="text" onChange={(e) => dispatch(onChange("title", e.target.value))} value={settings.title}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Template</label>
                                <input type="text" onChange={(e) => dispatch(onChange("template", e.target.value))} value={settings.template}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Facebook</label>
                                <input type="text" onChange={(e) => dispatch(onChange("facebook", e.target.value))} value={settings.facebook}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>instagram</label>
                                <input type="text" onChange={(e) => dispatch(onChange("instagram", e.target.value))} value={settings.instagram}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Register Free Coin</label>
                                <input type="text" onChange={(e) => dispatch(onChange("freeCredit", e.target.value))} value={settings.freeCredit}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Message Coin</label>
                                <input type="text" onChange={(e) => dispatch(onChange("messageCoin", e.target.value))} value={settings.messageCoin}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Message Photo Coin</label>
                                <input type="text" onChange={(e) => dispatch(onChange("messagePhotoCoin", e.target.value))} value={settings.messagePhotoCoin}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Profile Photo Coin</label>
                                <input type="text" onChange={(e) => dispatch(onChange("profilePhotoCoin", e.target.value))} value={settings.profilePhotoCoin}  />
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Gif Coin</label>
                                <input type="text" onChange={(e) => dispatch(onChange("gifCoin", e.target.value))} value={settings.gifCoin}  />
                            </div>
                            
                        </div>
                    </li>
                    <li className={tabs === 1 ? Styles.active : ""}>
                        <div className={Styles.formGroup}>
                            <label>Login</label>
                            <input type="text" onChange={(e) => dispatch(onChange("prize_login", e.target.value))} value={settings.prize_login}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Login Coin</label>
                            <input type="text" onChange={(e) => dispatch(onChange("prize_loginCoin", e.target.value))} value={settings.prize_loginCoin}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Profile</label>
                            <input type="text" onChange={(e) => dispatch(onChange("prize_profile", e.target.value))} value={settings.prize_profile}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Profile Photo Coin</label>
                            <input type="text" onChange={(e) => dispatch(onChange("prize_profilePhotoCoin", e.target.value))} value={settings.prize_profilePhotoCoin}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Send Message</label>
                            <input type="text" onChange={(e) => dispatch(onChange("prize_sendMessage", e.target.value))} value={settings.prize_sendMessage}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Send Message coin</label>
                            <input type="text" onChange={(e) => dispatch(onChange("prize_sendMessageCoin", e.target.value))} value={settings.prize_sendMessageCoin}  />
                        </div>
                    </li>
                    <li className={tabs === 2 ? Styles.active : ""}>
                        <div className={Styles.list}>
                            <label>Datenschutz</label>
                            <SimpleMDE 
                                style={{width:"100%"}} 
                                value={settings.Datenschutz}
                                onChange={(e) =>dispatch(onChange("Datenschutz", e))}
                                // 
                            />
                        </div>
                        <div className={Styles.list}>
                            <label>Impressum</label>
                            <SimpleMDE 
                                style={{width:"100%"}} 
                                value={settings.Impressum}
                                onChange={(e) => dispatch(onChange("Impressum", e))}
                            />
                        </div>
                        <div className={Styles.list}>
                            <label>Nutzungdbedingungen</label>
                            <SimpleMDE 
                                style={{width:"100%"}} 
                                value={settings.Nutzungdbedingungen}
                                onChange={(e) => dispatch(onChange("Nutzungdbedingungen", e))}
                            />
                        </div>
                    </li>
                    <li className={tabs === 3 ? Styles.active : ""}>
                        <div className={Styles.formGroup}>
                            <label>Title 1</label>
                            <input type="text" onChange={(e) => dispatch(onChange("faqTitle1", e.target.value))} value={settings.faqTitle1}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Content 1</label>
                            <textarea onChange={(e) => dispatch(onChange("faq1", e.target.value))} value={settings.faq1} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Title 2</label>
                            <input type="text" onChange={(e) => dispatch(onChange("faqTitle2", e.target.value))} value={settings.faqTitle2}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Content 2</label>
                            <textarea onChange={(e) => dispatch(onChange("faq2", e.target.value))} value={settings.faq2} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Title 3</label>
                            <input type="text" onChange={(e) => dispatch(onChange("faqTitle3", e.target.value))} value={settings.faqTitle3}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Content 3</label>
                            <textarea onChange={(e) => dispatch(onChange("faq3", e.target.value))} value={settings.faq3} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Title 4</label>
                            <input type="text" onChange={(e) => dispatch(onChange("faqTitle4", e.target.value))} value={settings.faqTitle4}  />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Content 4</label>
                            <textarea onChange={(e) => dispatch(onChange("faq4", e.target.value))} value={settings.faq4} />
                        </div>
                    </li>
                    <li className={tabs === 4 ? Styles.active : ""}>
                        <table className={Styles.table}>
                            <thead>
                                <tr style={{fontSize:13}}>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Coin
                                    </th>
                                    <th>
                                        Extra Coin
                                    </th>
                                    <th>
                                        Discount
                                    </th>
                                    <th>
                                        Month
                                    </th>
                                    <th>
                                        Monthly
                                    </th>
                                    <th>
                                        Pay
                                    </th>
                                    <th>
                                        Pay String
                                    </th>
                                    <th>
                                        Pay Text
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {settings.packets && settings.packets.map((doc, index)=>{
                                    return <tr>
                                        <td>
                                            <input size="4" value={doc.id} onChange={(e) => dispatch(changePacket("id", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input value={doc.title} onChange={(e) => dispatch(changePacket("title", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="8" value={doc.coin} onChange={(e) => dispatch(changePacket("coin", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="8" value={doc.extraCoin} onChange={(e) => dispatch(changePacket("extraCoin", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="8" value={doc.discount} onChange={(e) => dispatch(changePacket("discount", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="8" value={doc.month} onChange={(e) => dispatch(changePacket("month", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="8" value={doc.mountly} onChange={(e) => dispatch(changePacket("mountly", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="10" value={doc.pay} onChange={(e) => dispatch(changePacket("pay", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="10" value={doc.payStr} onChange={(e) => dispatch(changePacket("payStr", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input size="10" value={doc.payText} onChange={(e) => dispatch(changePacket("payText", e.target.value, index))} />
                                        </td>
                                        <td>
                                            <input value={doc.type} onChange={(e) => dispatch(changePacket("type", e.target.value, index))} />
                                        </td>
                                        <td>
                                        <ButtonDanger onClick={(e) => dispatch(deletePacket(index, doc.docId))} style={{borderRadius:5,padding:0,width:36,height:36}} text={<img style={{width:20}} src={TrashIcon} alt="" />} />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div style={{display:"flex", marginTop:20}}>

                        <ButtonPrimary onClick={(e) => dispatch(addPacket())} style={{borderRadius:5,padding:0,width:52,height:36}} text="Add" />
                        <ButtonSuccess onClick={(e) => dispatch(SavePacket())} style={{borderRadius:5,padding:0,width:82,height:36, marginTop:0, marginLeft:10}} text="Save" />
                        </div>
                    </li>
                    {settings.templates && settings.templates.map((doc,index) =>{
                        return (<li key={index} className={tabs === (index+5) ? Styles.active : ""}>
                        <div className={Styles.list}>
                            <label>Background</label>
                            <div className={Styles.avatar}>
                                <div className={Styles.avatarImg}>
                                    <img src={settings["temp"+index+"_bg"]} alt="avatar" />
                                </div>
                                <div className={Styles.photoEdit}>
                                    <input className={Styles.absolute} type="file" onChange={(e) => dispatch(photoUpdate(e.target, index))} />
                                </div>
                            </div>
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Text 1</label>
                            <input type="text" onChange={(e) => dispatch(onChange("temp"+index+"_1", e.target.value))} value={settings["temp"+index+"_1"]} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Text 2</label>
                            <input type="text" onChange={(e) => dispatch(onChange("temp"+index+"_2", e.target.value))} value={settings["temp"+index+"_2"]} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Text 3</label>
                            <input type="text" onChange={(e) => dispatch(onChange("temp"+index+"_3", e.target.value))} value={settings["temp"+index+"_3"]} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Text 4</label>
                            <input type="text" onChange={(e) => dispatch(onChange("temp"+index+"_4", e.target.value))} value={settings["temp"+index+"_4"]} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Text 5</label>
                            <input type="text" onChange={(e) => dispatch(onChange("temp"+index+"_5", e.target.value))} value={settings["temp"+index+"_5"]} />
                        </div>
                        <div className={Styles.formGroup}>
                            <label>Text 6</label>
                            <input type="text" onChange={(e) => dispatch(onChange("temp"+index+"_6", e.target.value))} value={settings["temp"+index+"_6"]} />
                        </div>
                    </li>)
                    })}
                </ul>
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
