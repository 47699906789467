import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
// import Client from 'shopify-buy';
import {defaultColors, defaultColorsDark} from "../../Settings"
// import {useToasts } from 'react-toast-notifications'
// // import {changeMode} from "./dashboard"
//   const { addToast } = useToasts();

export const GENERAL_DATA = createAction('GENERAL_DATA');
export const INFO_DATA = createAction('INFO_DATA');
export const PRIZE_DATA = createAction('PRIZE_DATA');
export const SITE_DATA = createAction('SITE_DATA');
export const GENERAL_INIT = createAction('GENERAL_INIT');
export const GENERAL_ERROR = createAction('GENERAL_ERROR');
export const TOTAL_DATA = createAction('TOTAL_DATA');
export const PACKETS_DATA = createAction('PACKETS_DATA');
export const FAQ_DATA = createAction('FAQ_DATA');
export const ONLINE_DATA = createAction('ONLINE_DATA');
export const SET_COIN = createAction('SET_COIN');
export const GIFTS_DATA = createAction('GIFTS_DATA');
export const AFFILIATES_NAME = createAction('AFFILIATES_NAME');

export const defaultColorsSet = (mode) => {
  // addToast('Saved Successfully', { appearance: 'success' });
    let colors = defaultColors
    if(mode === "dark"){
      colors = defaultColorsDark
      document.body.className = "dark"
    }else if(mode === "light"){
      colors = defaultColors
      document.body.className = "light"
    }else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      colors = defaultColorsDark
      document.body.className = "dark"
    }
  //   window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
  //     const newColorScheme = e.matches ? "dark" : "light";
  //     console.log(newColorScheme);
  // });

  let root = document.documentElement;
  root.style.setProperty('--white-1', colors.white1);
  root.style.setProperty('--gray-1', colors.gray1);
  root.style.setProperty('--gray-2', colors.gray2);
  root.style.setProperty('--gray-3', colors.gray3);
  root.style.setProperty('--gray-4', colors.gray4);
  root.style.setProperty('--gray-5', colors.gray5);
  root.style.setProperty('--blue', colors.blue);
  root.style.setProperty('--indigo', colors.indigo);
  root.style.setProperty('--purple', colors.purple);
  root.style.setProperty('--pink', colors.pink);
  root.style.setProperty('--red', colors.red);
  root.style.setProperty('--orange', colors.orange);
  root.style.setProperty('--yellow', colors.yellow);
  root.style.setProperty('--green', colors.green);
  root.style.setProperty('--teal', colors.teal);
  root.style.setProperty('--cyan', colors.cyan);
  root.style.setProperty('--white', colors.white);
  root.style.setProperty('--white-bg', colors.whiteBg);
  root.style.setProperty('--gray', colors.gray);
  root.style.setProperty('--gray-dark', colors.grayDark);
  root.style.setProperty('--primary', colors.primary);
  root.style.setProperty('--primary-dark', colors.primaryDark);
  root.style.setProperty('--secondary', colors.secondary);
  root.style.setProperty('--secondary-dark', colors.secondaryDark);
  root.style.setProperty('--success', colors.success);
  root.style.setProperty('--success-dark', colors.successDark);
  root.style.setProperty('--info', colors.info);
  root.style.setProperty('--warning', colors.warning);
  root.style.setProperty('--danger', colors.danger);
  root.style.setProperty('--light', colors.light);
  root.style.setProperty('--dark', colors.dark);
    // root.style.setProperty('--primary-color', defaultColors.primary);
    // root.style.setProperty('--primary-color-dark', defaultColors.primaryH);
    // root.style.setProperty('--success-color', defaultColors.success);
    // root.style.setProperty('--success-color-dark', defaultColors.successH);
}
export const generalAffiliates = () => {
  return async (dispatch) => {
    firebase.firestore().collection("affiliate").get().then(doc=>{
      let arr = []
      doc.forEach(doc=>{
        arr[doc.id] = doc.data().name
      })
      dispatch(AFFILIATES_NAME(arr))
    })
  };
};
export const generalData = () => {
    return async (dispatch) => {
      // dispatch(GENERAL_INIT());
      firebase.firestore().collection("settings").doc("general").onSnapshot(doc=>{
        if(doc.data()){
          return dispatch(
              GENERAL_DATA(doc.data())
          );
        }
      })
    };
  };

  export const onlineUsers = () => {
    return async (dispatch, getState) => {
      const { onlineUsers } = getState().generalDataReducer;
      const lenght = getState().generalDataReducer.length && getState().generalDataReducer.length.online ? getState().generalDataReducer.length.online : 3000;
      if(onlineUsers){
        var y = Math.random();
        if (y < 0.5){
          dispatch(ONLINE_DATA(onlineUsers + ((-6) + Math.floor((6 - (-6)) * Math.random()))))
        }
        
      }else{
        dispatch(ONLINE_DATA(lenght + Math.floor(Math.random(lenght) * 1000)))
      }
      
    };
  };
  export const setCoin = (coin) => {
    return async (dispatch, getState) => {
      dispatch(SET_COIN(coin))
    };
  };

  export const infoData = () => {
    return async (dispatch) => {
      // dispatch(GENERAL_INIT());
      firebase.firestore().collection("settings").doc("info").get().then(doc => {
        if (doc.data()) {
          return dispatch(
            INFO_DATA(doc.data())
          );
        }
      })
    };
  };
  export const giftsData = () => {
    return async (dispatch) => {
      // dispatch(GENERAL_INIT());
      firebase.firestore().collection("gifts").orderBy("coin").get().then(doc => {
        let arr = []
        doc.forEach(doc=>{
          arr.push(doc.data())
        })
        dispatch(GIFTS_DATA(arr));
      })
    };
  };
  export const prizeData = () => {
    return async (dispatch) => {
      // dispatch(GENERAL_INIT());
      firebase.firestore().collection("settings").doc("prize").get().then(doc => {
        if (doc.data()) {
          return dispatch(
            PRIZE_DATA(doc.data())
          );
        }
      })
    };
  };
    
  export const siteData = () => {
    return (dispatch) => {
      // dispatch(GENERAL_INIT());
      firebase.firestore().collection("settings").doc("templates").collection("data").get().then(doc=>{
        let arr = []
        doc.forEach(doc=>{
          arr[doc.data().id] = doc.data()
        })
        return dispatch(SITE_DATA(arr));
      })
    };
  };

export const totalLength = () => {
  return (dispatch) => {
    // dispatch(GENERAL_INIT());
    firebase.firestore().collection("totalLength").onSnapshot(doc => {
      if (doc.size) {
        return dispatch(TOTAL_DATA({
          fake: doc.docs[0].data().length,
          user: doc.docs[2].data().length,
          online:doc.docs[1].data().length,
        }));
      }
    })
  };
};

export const Packets = () => {
  return (dispatch) => {
    // dispatch(GENERAL_INIT());
    firebase.firestore().collection("settings").doc("packets").collection("data").orderBy("id").get().then(doc => {
      let arr = []
      let arr2 = []
      doc.forEach(doc => {
        arr.push(doc.data())
        arr2[doc.data().id] = doc.data()
      })
      dispatch(PACKETS_DATA({
        list1:arr,
        list2:arr2,
      }));
    })
  };
};

export const Faq = () => {
  return (dispatch) => {
    firebase.firestore().collection("settings").doc("faq").get().then(doc => {
      dispatch(FAQ_DATA(doc.data()));
    })
  };
};
