import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import {defaultColorsSet} from "./general"

export const DASHBOARD_SEARCH = createAction('DASHBOARD_SEARCH');
export const CHANGE_MODE = createAction('CHANGE_MODE');
const changeMode = (value, def) => {
  return (dispatch, getState) => {
    if(!def){
      defaultColorsSet(value)
    }
    dispatch(CHANGE_MODE(value))
  }
}
const searchHeader = (e) => {
  return (dispatch, getState) => {
    
    let arr = []
    if(e){
        
      firebase.firestore().collection("users").where("docId", "==", e).limit(10).get().then(doc=>{
        if(doc.size){
          doc.forEach(doc=>{
            arr.push({
              docId:doc.id,
              nickname:doc.data().meta.nickname,
              email:doc.data().meta.email
            })
          })
          dispatch(DASHBOARD_SEARCH(arr))
        }else{
          firebase.firestore().collection("users").where("meta.email", "==", e).limit(10).get().then(doc=>{
            if(doc.size){
              doc.forEach(doc=>{
                arr.push({
                  docId:doc.id,
                  nickname:doc.data().meta.nickname,
                  email:doc.data().meta.email
                })
              })
              dispatch(DASHBOARD_SEARCH(arr))
            }else{
              firebase.firestore().collection("users").where("meta.nickname", ">=", e).limit(10).get().then(doc=>{
                doc.forEach(doc=>{
                  arr.push({
                    docId:doc.id,
                    nickname:doc.data().meta.nickname,
                    email:doc.data().meta.email
                  })
                })
                dispatch(DASHBOARD_SEARCH(arr))
              })
            }
          })
        }
      })
    }else{
      dispatch(DASHBOARD_SEARCH([]))
    }
    //     
  }
}


export { searchHeader, changeMode };
