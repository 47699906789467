import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { cleanErrorAuth } from '../../../state/actions/auth'
import styles from './Styles.module.scss';
import Modal from "../../Items/modal"
// import { useHistory } from "react-router-dom";
import {useIntl} from 'react-intl';
// import {useToasts } from 'react-toast-notifications'

const Header_3 = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    // const [dropdown, setDropdown] = useState(false);
    // const { addToast } = useToasts()
    // let location = useLocation();
    // const history = useHistory();

    // const routeChange = () => {
    //     let path = `/premium?type=vip`;
    //     history.push(path);
    // }
    const { auth } = useSelector(
        (state) => ({
            auth: state.auth,
        }), shallowEqual
    );
    // useEffect(() => {
    //     setDropdown(false)
    //     // eslint-disable-next-line
    // }, [location]);
    return (<div className={styles.modalsPage}>



        {auth.error && auth.error.type ? <Modal title={auth.error.type === "success" ? intl.formatMessage({id:"General.basarili"}) : intl.formatMessage({id:"General.hata"})} content={auth.error.message} status={auth.error.type} width={400} close={(e) => dispatch(cleanErrorAuth())} /> : "" }
    </div>)
}
export default Header_3;
