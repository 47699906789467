import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import Input from "../../components/Items/input"
import Toggle from "../../components/Items/toggle"
import { ButtonPrimary, ButtonPrimaryLigth} from "../../components/Items/buttons"
import {onChange} from "../../state/actions/form"
import {FormattedMessage, useIntl} from 'react-intl';

const EditDetail = () => {
    const { loading, userData} = useSelector(
        (state) => ({
            loading: state.admins.loading,
            userData: state.admins.detail,
        }), shallowEqual
    );

    useEffect(() => {
    window.scrollTo(0, 0);
        if (userData){
            dispatch(onChange("formNickname", userData.username));
            dispatch(onChange("formRef", userData.ref));
            dispatch(onChange("formEmail", userData.email));
            dispatch(onChange("formPassword", userData.password));
            dispatch(onChange("formType", userData.type));
            dispatch(onChange("formRole", userData.role));
            dispatch(onChange("formStatus", userData.status));
        }
        // eslint-disable-next-line
    }, [userData]);


    const dispatch = useDispatch();
    const intl = useIntl();
    const [tab, tabSet] = useState(1)
    if(!userData){
        return null
    }

    return (
        <div className={styles.contentWhite}>
            <div className={styles.top}>
                <div className={styles.p}>
                    <div className={styles.right}>
                        <div className={styles.nickname}>
                            <b>{userData.username}</b>
                            <small>ID: {userData.id}</small>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className={classNames(styles.tab, styles.p)}>
                {tab === 1 ? <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Account Information" /> : 
                <ButtonPrimaryLigth onClick={(e) => tabSet(1)} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Account Information" />}
            </div>
            {tab === 1 ? <div className={classNames(styles.px)} style={{marginBottom:25}}>
                <div className={styles.row}>
                    <div className={classNames(styles.container, styles.wHalf)}>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Status
                            </span>
                            <Toggle styletype="type_4" label={<FormattedMessage id="Register.cinsiyet" />} name="formStatus" disabled />
                        </div>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Nickname
                            </span>
                            <Input styletype="type_4" name="formNickname" type="text" />
                        </div>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Ref
                            </span>
                            <Input styletype="type_4" name="formRef" type="text" />
                        </div>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Role
                            </span>
                            <Input styletype="type_4" name="formRole" type="text" />
                        </div>
                    </div>
                    <div className={classNames(styles.container, styles.wHalf)}>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Email
                            </span>
                            <Input styletype="type_4" name="formEmail" type="text" />
                        </div>
                        {/* <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Password
                            </span>
                            <Input styletype="type_4" name="formPassword" type="text" />
                        </div> */}
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Type
                            </span>
                            <Input styletype="type_4" name="formType" type="text" />
                        </div>
                    </div>
                </div>
            </div> : ""}
            {tab === 2 ? <div className={classNames(styles.tabContent, styles.px)} style={{marginBottom:25}}>
               
            </div> : ""}
            {tab === 3 ? <div className={classNames(styles.px)} style={{marginBottom:25}}>

            </div> : ""}
            {loading ?<div className="loading"></div> : ""}
        </div>
    )
}

export default EditDetail;
