import { createReducer } from 'redux-act';

import {
    START_PAYMENT,
    PAYMENTS_END,
    LAST_PAYMENTS_STATS,
    TOTAL_PAYMENTS_STATS,
    BEST_USER,
    PAYMENTS_DATA,
    PAYMENTS_CHANGE
} from '../actions/payments';

const initialState = {
  loading: false
};

const authReducer = createReducer(
  {
    [LAST_PAYMENTS_STATS]: (state, payload) => ({
      ...state,
      stats: payload.list,
      cats: payload.cats,
      stats_week: payload.lists_week,
      cats_week: payload.cats_week,
      stats_month: payload.lists_month,
      cats_month: payload.cats_month,
      refs: payload.refs,
      refs_week: payload.refs_week,
      refs_month: payload.refs_month,
      pay_type: payload.pay_type,
      packet_type: payload.packet_type,
      topUser: payload.topUser,
      loading: false,

    }),
    [START_PAYMENT]: (state, payload) => ({
      ...state,
      loading: true,
    }),
    [PAYMENTS_END]: (state, payload) => ({
      ...state,
      loading: false,
    }),
    [TOTAL_PAYMENTS_STATS]: (state, payload) => ({
      ...state,
      total: payload,
    }),
    [PAYMENTS_CHANGE]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [BEST_USER]: (state, payload) => ({
      ...state,
      bestUser: payload,
    }),
    [PAYMENTS_DATA]: (state, payload) => ({
      ...state,
      list: payload.list,
    })
  },
  initialState
);

export default authReducer