import React from 'react';
import { onChange } from '../../state/actions/form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './Form.module.scss';
import classNames from 'classnames';

const Toggle = (props) => {
    const dispatch = useDispatch();
    const { form } = useSelector(
        (state) => ({
            form: state.form
        }), shallowEqual
    );
    let _type = props.styletype === "type_2" ? styles.type_2 : props.styletype === "type_3" ? styles.type_3 :  props.styletype === "type_4" ? styles.type_4 : ""
    return(<div  className={classNames(styles.formGroup, styles.active, styles.type_radio, _type)} {...props}>    
        <div>
            {form[props.name] === "true" || form[props.name] === true ? 
            <input type="radio" id="toggle_1" name={props.name} required onChange={(e) => dispatch(onChange(props.name, e.target.value))} value={true} checked {...props} />  :
            <input type="radio" id="toggle_1" name={props.name} required onChange={(e) => dispatch(onChange(props.name, e.target.value))} value={true} {...props} />  
            }
            <label htmlFor="toggle_1">True</label>
        </div>
        <div>
            {form[props.name] === "true" || form[props.name] === true ? 
            <input type="radio" id="toggle_2" name={props.name} required onChange={(e) => dispatch(onChange(props.name, e.target.value))} value={false} {...props} />  :
            <input type="radio" id="toggle_2" name={props.name} required onChange={(e) => dispatch(onChange(props.name, e.target.value))} value={false} {...props} checked />  
            }
            <label htmlFor="toggle_2">False</label>
        </div>
        <span className={classNames(form[props.name] === "true" || form[props.name] === true ? styles.activeBg2 : "", styles.bg)}></span>
    </div> )
}
export default Toggle;