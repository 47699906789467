import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import { getUserDetail, saveFake, getMessagesList } from "../../state/actions/fakes"
import { ButtonPrimary } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import LastMessages from "./LastMessages"
import EditDetail from "./EditDetail"


const Edit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getMessagesList(id))
        dispatch(getUserDetail(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (
        <section className={classNames(styles.Pages, styles.edit)}>

            <div className={styles.headerBottom}>
                <div className={styles.left}>
                    <NavLink to="/fakes">
                        Fakes
                    </NavLink>
                    <img className={styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={styles.right}>
                    <NavLink to={"/fakes"}>
                        Back
                    </NavLink>
                    <ButtonPrimary onClick={(e) => dispatch(saveFake(id))} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13, marginLeft:15}} text="Save" />
                </div>
            </div>

            <div className={styles.row}>
                <div className={classNames(styles.container, styles.full)}>
                <EditDetail id={id}/> 
                </div>
            </div>
            <div className={styles.row}>
                <div className={classNames(styles.container, styles.full)}>
                    <LastMessages id={id} />
                </div>
            </div>
        </section>
    )
}

export default Edit;
