import React from 'react';
import styles from './Table.module.scss';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { ButtonPrimary,ButtonDanger, ButtonPrimaryLigth } from "./buttons"
import { unCheck, Check, sendNewsletter, deleteNewsletter, sendWaiting} from "../../state/actions/newsletters"
import { paymentSuccess} from "../../state/actions/payments"
import { deleteAffiliate} from "../../state/actions/affiliates"
import { deleteMessage} from "../../state/actions/messages"
import InfoIcon from "../../assets/img/icons/info.svg"
import TrashIcon from "../../assets/img/icons/trash.svg"
const Modal = (props) => {
    const dispatch = useDispatch();
    const { unCheckUsers, users } = useSelector(
        (state) => ({
            unCheckUsers: state.newsletters.unCheckUsers ? state.newsletters.unCheckUsers : [],
            users: state.usersList,
        }), shallowEqual
    );
    const tick = <svg style={{width:18, height:18}} viewBox="0 -46 417.81333 417" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"/></svg>;
    const editIcon = <svg style={{width:18, height:18}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1"><g stroke="none" ><path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#fff" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/><rect fill="#fff" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/></g></svg>;
    let pages = []
    for (let index = 0; index < props.totalPage; index++) {
        pages.push(index)
        
    }
    // console.log(props.activePage * 10);
    return (
        <div className={styles.tableContent}>
            <table className={classNames(styles.table, props.class === "type_2" ? styles.type_2 : "")}>
                {props.head && <thead>
                    <tr>
                        {props.head.map((doc, index) =>{
                            let _orderBy = doc.orderBy === "asc" ? "desc" : doc.orderBy === "desc" ? "asc" : doc.orderBy === "" ? "desc" : doc.orderBy
                            if(doc.orderBy === "asc"){
                                return(
                                    <th onClick={(e) => props.headSetFunc(index, _orderBy)} key={index}>
                                        <div className={styles.center}>
                                            {doc.name}
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 401.998 401.998">
                                                <g>
                                                    <path fill="#475F7B" d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                                        c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                                        c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"/>
                                                    <path fill="#ccd2da" d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                                        c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                                        c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </th>
                                )
                            }else if(doc.orderBy === "desc"){
                                return(
                                    <th onClick={(e) => props.headSetFunc(index, _orderBy)} key={index}>
                                        <div className={styles.center}>
                                            {doc.name}
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 401.998 401.998">
                                                <g>
                                                    <path fill="#ccd2da" d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                                        c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                                        c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"/>
                                                    <path fill="#475F7B" d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                                        c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                                        c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </th>
                                )
                            }else if(doc.orderBy === ""){
                                return(
                                    <th onClick={(e) => props.headSetFunc(index, _orderBy)} key={index}>
                                        <div className={styles.center}>
                                            {doc.name}
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 401.998 401.998">
                                                <g>
                                                    <path fill="#ccd2da" d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                                        c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                                        c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"/>
                                                    <path fill="#ccd2da" d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                                        c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                                        c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </th>
                                )
                            }else{
                                return(
                                    <th key={index}>
                                        <div>
                                            {doc.name}
                                        </div>
                                    </th>
                                )
                            }
                        })}
                    </tr>
                </thead> }
                {props.body && <tbody>
                    {props.body.map((userDoc, index) => {
                        if (!userDoc.data){
                            return null
                        }
                        {/* console.log(index) */}
                        if(index >= (((props.activePage+1) * 10) - 10) && index <  ((props.activePage+1) * 10)){
                            return (<tr key={index} className={userDoc.disabled || (unCheckUsers.findIndex(x => x.docId ===userDoc.docId) !== -1 && props.buttonType !== "addNewsletterUsersBanned") ? styles.disabled : ""}>
                                {userDoc.data && userDoc.data.map((doc, index) => {
                                    let date = doc.value
                                    if (doc.type === "avatar"){
                                        if (doc.value === "noavatar.jpg" || !doc.value){
                                            return (<td key={index} style={{width:65}}>
                                                    <div className={styles.avatar}>
                                                        {/* <UsersIcon /> */}
                                                    </div>
                                                </td>) 
                                        }else{
                                            return (<td key={index} style={{ width: 65 }}>
                                                    <div className={styles.avatar}>
                                                        <img src={doc.value} alt="" />
                                                    </div>
                                                </td>)
                                        }
                                    } else if (doc.type === "date") {
                                        return (<td key={index}>
                                                <p>{date}</p>
                                            </td>)
                                    } else if (doc.type === "star") {
                                        return (<td key={index}>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <svg style={{width:14, height:14, marginRight:6}} viewBox="0 -10 511.98685 511" xmlns="http://www.w3.org/2000/svg"><path d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0" fill={doc.class ?"#fd5857" : "#ffc107" }/></svg>
                                                    <p>{doc.value}</p>
                                                </div>
                                            </td>)
                                    } else if (doc.type === "bold") {
                                        return (<td key={index}>
                                                <b>{doc.value}</b>
                                                {doc.sub ? <small>{doc.sub}</small> : ""}
                                            </td>)
                                    } else if (doc.type === "badge") {
                                        return (<td key={index}>
                                                <span className={classNames("badge", doc.class)}>{doc.value}</span>
                                            </td>)
                                    } else if (doc.type === "textAlt") {
                                        return (<td key={index} style={{paddingLeft:10, paddingRight:10}}>
                                                <small className={styles.center}>{doc.value}<span className="tooltip">
                                                    <img src={InfoIcon} alt="" />
                                                {/* <InfoIcon className={styles.icon} /> */}
                                                <span className="tooltiptext">{doc.fullValue}</span></span></small>
                                            </td>)
                                    } else if (doc.type === "name") {
                                        return (<td key={index}>
                                                <p>{users[doc.value] && users[doc.value].nickname}</p>
                                                {doc.sub ? <small>{doc.sub}</small> : ""}
                                            </td>)
                                    }
                                    return (<td key={index}>
                                            <p>{doc.value}</p>
                                            {doc.sub ? <small>{doc.sub}</small> : ""}
                                        </td>)
                                })}
                                {props.buttonType === "newsletter" ? <td key={index} style={{ width: 50, paddingRight:0}}>
                                    <div style={{display:"flex", justifyContent: "flex-end"}}>

                                    <NavLink to={"/newsletters/waiting/" + userDoc.docId} exact><ButtonPrimary 
                                        // onClick={(e) => {setnewsletterModal(true); dispatch(getWaitingNewsletter(userDoc.docId))} } 
                                        text={<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#fff" fillRule="nonzero" opacity="0.3"></path><path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#fff" fillRule="nonzero"></path></g></svg>}
                                        style={{ height: 36, borderRadius:5, marginRight:5, width:40, padding:0 }} 

                                    /></NavLink>
                                        {/* {userDoc.data[9].value === "wait" && <ButtonPrimary onClick={(e) => dispatch(sendNewsletter(userDoc.docId))} text="Start" style={{ height: 36, borderRadius:5, marginRight:5 }} /> } */}
                                        <ButtonDanger text={<img style={{width:20}} src={TrashIcon} alt="" />} onClick={(e) => dispatch(deleteNewsletter(userDoc.docId))} style={{ height: 36, flex:"none", width:36, padding:0, borderRadius:5 }}/>
                                    </div>
                                </td> : null}
                                {props.buttonType ? <td key={index}>
                                {props.buttonType === "addNewsletterUsersBanned" ? <div  className={styles.lastTd}><ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} onClick={(e) => dispatch(unCheck(userDoc.docId))} text={"x"} /></div>  
                                : props.buttonType === "addNewsletterUsers" ? <div  className={styles.lastTd}> <ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} onClick={(e) => dispatch(Check(userDoc.docId))} text={"x"} /></div> 
                                : props.buttonType === "waiting" ? <div  className={styles.lastTd}><ButtonPrimaryLigth onClick={(e) => dispatch(sendWaiting(userDoc.docId))} style={{borderRadius:5,padding:0,width:36,height:36}} text={tick} /></div> 
                                : props.buttonType === "addNewsletter" ? <div  className={styles.lastTd}><NavLink to={"/newsletters/add-newsletter/" + userDoc.docId} exact><ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} text={tick} /></NavLink></div> 
                                : props.buttonType === "users" ? <div  className={styles.lastTd}><NavLink to={"/users/edit/" + userDoc.docId} exact><ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} text={editIcon} /></NavLink></div> 
                                : props.buttonType === "fakes" ? <div  className={styles.lastTd}><NavLink to={"/fakes/edit/" + userDoc.docId} exact><ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} text={editIcon} /></NavLink></div> 
                                : props.buttonType === "callcenters" ? <div  className={styles.lastTd}><NavLink to={"/callcenters/edit/" + userDoc.docId} exact><ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} text={editIcon} /></NavLink></div> 
                                : props.buttonType === "admins" ? <div  className={styles.lastTd}><NavLink to={"/admins/edit/" + userDoc.docId} exact><ButtonPrimaryLigth style={{borderRadius:5,padding:0,width:36,height:36}} text={editIcon} /></NavLink></div> 
                                : props.buttonType === "payment" && userDoc.data[3].value === "new" && userDoc.data[5].value === "prepayment" ? <div  className={styles.lastTd}><ButtonPrimary onClick={(e) => dispatch(paymentSuccess(userDoc.docId))} style={{borderRadius:5,padding:0,width:36,height:36}} text={tick} /></div> 
                                : props.buttonType === "paymentPage" && userDoc.data[4].value === "new" && userDoc.data[1].value === "prepayment" ? <div  className={styles.lastTd}><ButtonPrimary onClick={(e) => dispatch(paymentSuccess(userDoc.docId))} style={{borderRadius:5,padding:0,width:36,height:36}} text={tick} /></div> 
                                : props.buttonType === "affiliate" ? <div  className={styles.lastTd}><ButtonDanger onClick={(e) => dispatch(deleteAffiliate(userDoc.docId))} style={{borderRadius:5,padding:0,width:36,height:36}} text={<img style={{width:20}} src={TrashIcon} alt="" />} /></div> 
                                : props.buttonType === "messages" ? <div  className={styles.lastTd}><ButtonDanger onClick={(e) => deleteMessage(userDoc.docId)} style={{borderRadius:5,padding:0,width:36,height:36}} text={<img style={{width:20}} src={TrashIcon} alt="" />} /></div> : null}
                                </td> : null }
                                {props.buttons && props.buttons.map((doc, index) =>{
                                    return (
                                        <td key={index} style={{width:doc.width}}>
                                            <div style={{display:"flex", justifyContent: "flex-end"}}>
                                            {doc.button}
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>)
                            
                        }
                    })}
                </tbody>}
            </table>
            {pages ? <div className={styles.paginations}>
                <div className={styles.total}>
                    <p>Total <b>{props.body.length}</b> search result</p>
                </div>
                <ul>
                    <li className={ props.activePage === 0 ? styles.disabled : ""} onClick={(e) => props.changePage(("prev"))}>
                        Prev
                    </li>
                    {1 > props.activePage - 6 ? "" : <li>...</li>}
                    {pages.map((doc, index)=>{
                        if((props.activePage + 6) < index){
                            return null
                        }
                        if((props.activePage - 6) > index){
                            return null
                        }
                        return (
                            <li key={index} onClick={(e) => props.changePage(index)} className={props.activePage === (index) ? styles.active : ""}>{index + 1}</li>
                        )
                    })}
                    {pages.length > props.activePage + 6 ? <li>...</li> : ""}
                    <li onClick={(e) => props.changePage("next")}>
                        Next
                    </li>
                </ul>
            </div> : ""}
        </div>
    );
};

export default Modal;