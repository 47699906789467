import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';
import { onChange } from "./form"
import { usersList } from "./usersList"
import ResizeImage from 'image-resize'
import Jimp from "jimp"


export const USERS_START = createAction('USERS_START');
export const USERS_CHANGE = createAction('USERS_CHANGE');
export const USERS_END = createAction('USERS_END');
export const USERS_SEARCH_USER = createAction('USERS_SEARCH_USER');
export const USER_DETAIL = createAction('USER_DETAIL');
export const LAST_MESSAGES = createAction('LAST_MESSAGES');
export const LAST_MESSAGES2 = createAction('LAST_MESSAGES2');
export const USERS_MESSAGES_LIST = createAction('USERS_MESSAGES_LIST');
export const USERS_MESSAGES = createAction('USERS_MESSAGES');
export const USER_HISTORY = createAction('USER_HISTORY');
export const USER_PAYMENT_DATA = createAction('USER_PAYMENT_DATA');

let unsubscribe = ""
let unsubscribe2 = ""

const resize = new ResizeImage({
    format: 'jpg',
    width: 640,
    outputType: "blob"
});

const resize2 = new ResizeImage({
    format: 'jpg',
    width: 300,
    outputType: "blob"
});

const resize3 = new ResizeImage({
    format: 'jpg',
    width: 64,
    outputType: "blob"
});

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}



const usersData = (first, head, total) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let users = getState().users
        let affiliates = getState().general.affiliates ? getState().general.affiliates : {}
        let adminData = getState().auth.userData
        dispatch(USERS_START())
        let fireData = firebase.firestore().collection("users").where("role", "==", 0)
        if (adminData.role === 2) {
            fireData = fireData.where("ref", "==", adminData.ref)
        }
        if (form.search_id) {
            fireData = fireData.where("docId", "==", form.search_id)
        } else {
            if (form.search_nickname) {
                fireData = fireData.where("meta.nickname_search", ">=", form.search_nickname.toLowerCase());
            } if (form.search_email) {
                fireData = fireData.where("meta.email", ">=", form.search_email);
            } else {
                if (form.searchStartDate) {
                    fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
                }
                if (form.searchEndDate) {
                    fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
                }
                if (form.search_verify) {
                    fireData = fireData.where("verify", "==", form.search_verify === "true" ? true : false)
                }
                if (form.search_ref) {
                    fireData = fireData.where("ref", "==", form.search_ref)
                }
                if (form.search_country) {
                    fireData = fireData.where("meta.country", "==", form.search_country)
                }
                if (form.search_city) {
                    fireData = fireData.where("meta.city", "==", form.search_city)
                }
            }
        }
        if (!form.search_nickname && !form.search_email) {
            head.forEach(doc => {
                if (doc.orderBy && doc.orderBy !== "disable") {
                    fireData = fireData.orderBy(doc.key, doc.orderBy)
                }
            })
        }
        if (users.lastData && users.lastData[(users.activePage - 1)] && first === "") {
            fireData = fireData.startAfter(users.lastData[(users.activePage - 1)])
        }
        if (!total) {
            fireData = fireData.limit(10)
        }
        fireData.get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(USERS_SEARCH_USER({
                    list: userList
                }))
                dispatch(USERS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (users.lastData && (users.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    users.lastData.push(_lastData)
                } else if (!users.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["verify"] = doc.data().verify
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.email },
                    { type: "text", value: doc.data().meta.gender ? "Weiblich" : "Mannlich" },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip ? doc.data().meta.zip : "" },
                    { type: "badge", value: doc.data().online ? "Online" : "Offline", class: doc.data().online ? "success" : "danger" },
                    { type: "text", value: affiliates[doc.data().ref] ? affiliates[doc.data().ref] : doc.data().ref },
                    { type: "badge", value: doc.data().verify ? "True" : "False", class: doc.data().verify ? "success" : "danger" },
                    { type: "text", value: doc.data().pay ? doc.data().pay.toFixed(2) : 0 },
                    { type: "star", value: doc.data().coin, class: doc.data().prize.sendMessage ? "" : "1" },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(USERS_SEARCH_USER({
                list: userList
            }))
            if (total) {
                dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            }
            dispatch(USERS_END())
        })
    }
}


const getUserDetail = (id) => {
    return async (dispatch, getState) => {
        if (unsubscribe) {
            unsubscribe()
        }
        unsubscribe = firebase.firestore().collection("users").doc(id).onSnapshot(doc => {
            dispatch(USER_DETAIL(doc.data()))
        })
    }
}


const getLastMessage = (id) => {
    return async (dispatch, getState) => {
        console.log(id);
        let fakeId = []
        firebase.firestore().collection("messages").where("get", "==", id).orderBy("time", "desc").limit(20).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push({
                    message: doc.data().message,
                    time: doc.data().time.toDate(),
                    cc: doc.data().cc,
                    fake: doc.data().post
                })
                if (!fakeId.includes(doc.data().post)) {
                    fakeId.push(doc.data().post)
                }
            })
            fakeId.forEach(doc => {
                firebase.firestore().collection("users").doc(doc).get().then(doc => {
                    if (doc.data()) {
                        dispatch(usersList(doc))
                        // dispatch(USERS_FAKES_DATA({ [doc.id]: {
                        //     id:doc.id,
                        //     nickname:doc.data().meta.nickname,
                        //     avatar:doc.data().meta.avatarThumb,
                        // } }));
                    }
                })
            })
            dispatch(USERS_END())
            dispatch(LAST_MESSAGES(arr))

        })
    }
}

const getLastMessage2 = (id) => {
    return async (dispatch, getState) => {
        let fakeId = []
        firebase.firestore().collection("messages").where("post", "==", id).orderBy("time", "desc").limit(20).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push({
                    message: doc.data().message,
                    time: doc.data().time.toDate(),
                    cc: doc.data().cc,
                    fake: doc.data().get,
                })
                if (!fakeId.includes(doc.data().get)) {
                    fakeId.push(doc.data().get)
                }
            })
            fakeId.forEach(doc => {
                firebase.firestore().collection("users").doc(doc).get().then(doc => {
                    if (doc.data()) {

                        dispatch(usersList(doc))
                        // dispatch(USERS_FAKES_DATA({ [doc.id]: {
                        //     id:doc.id,
                        //     nickname:doc.data().meta.nickname,
                        //     avatar:doc.data().meta.avatarThumb,
                        // } }));
                    }
                })
            })
            dispatch(USERS_END())
            dispatch(LAST_MESSAGES2(arr))

        })
    }
}

const getMessagesList = (id) => {
    return async (dispatch, getState) => {
        firebase.firestore().collection("messagesList").where("to", "array-contains", id).orderBy("time", "desc").limit(30).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push({
                    id: doc.id,
                    avatar: doc.data().users[1].a,
                    nickname: doc.data().users[1].n,
                    fakeId: doc.data().to[1],
                    from: doc.data().allGetMessages,
                    to: doc.data().allSendMessages,
                    time: doc.data().time.toDate(),
                    lastMessage: doc.data().lastMessage
                })
            })
            dispatch(USERS_END())
            dispatch(USERS_MESSAGES_LIST(arr))
        })
    }
}

const getMessages = (id, userId, fakeId) => {
    return async (dispatch, getState) => {

        firebase.firestore().collection("users").doc(fakeId).get().then(doc => {
            if (doc.data()) {
                dispatch(usersList(doc))
            }
        })
        firebase.firestore().collection("messages").where("id", "==", id).orderBy("time", "desc").limit(30).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push(doc.data())
            })
            dispatch(USERS_END())
            dispatch(USERS_MESSAGES(arr))
        })
    }
}

const getCoinHistory = (id) => {
    return async (dispatch) => {
        if (!id) { return null }

        firebase.firestore().collection("history").where("a", "==", id).orderBy("time", "desc").limit(30).get().then(async doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push(doc.data())
            })
            dispatch(USERS_END())
            dispatch(USER_HISTORY(arr))
        })
    }
}

const getPayments = (id) => {
    return async (dispatch, getState) => {
        let payment = getState().general.packets;
        if (!id) { return null }

        let packets = []
        payment.forEach(doc => {
            packets[doc.paket] = doc
        })
        if (unsubscribe2) {
            unsubscribe2()
        }
        unsubscribe2 = firebase.firestore().collection("payments").where("userid", "==", id).orderBy("time", "desc").limit(30).onSnapshot(doc => {
            let list = []
            doc.forEach(doc => {
                let pushArr = []
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    // { type: "avatar", value: doc.data().paytype },
                    { type: "bold", value: packets[doc.data().packet] && packets[doc.data().packet].title },
                    { type: "text", value: doc.data().pay2 + "€" },
                    { type: "bold", value: packets[doc.data().packet] && (packets[doc.data().packet].coin + packets[doc.data().packet].extraCoin) + " Coin" },
                    { type: "badge", value: doc.data().function, class: doc.data().function === "ok" ? "success" : doc.data().function === "new" ? "primary" : "danger" },
                    { type: "text", value: doc.data().time ? doc.data().time.toDate().toLocaleString() : "" },
                    { type: "text", value: doc.data().paytype },
                ]
                list.push(pushArr);
            })
            dispatch(USERS_END())
            dispatch(USER_PAYMENT_DATA(list))
        })
    }
}
const saveUser = (userId) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        const users = getState().users.detail;
        dispatch(USERS_START())
        let hobsArr = [],
            sprachenArr = [],
            sportArr = [],
            musicArr = [],
            filmeArr = [],
            filterArr = [],
            unterhaltungArr = []

        form.formHobs.forEach(doc => {
            hobsArr.push(Number(doc.value))
        })
        form.filterRelation.forEach(doc => {
            filterArr.push(Number(doc.value))
        })
        form.formSprachen.forEach(doc => {
            sprachenArr.push(doc.value)
        })
        form.formSport.forEach(doc => {
            sportArr.push(doc.value)
        })
        form.formMusik.forEach(doc => {
            musicArr.push(doc.value)
        })
        form.formFilme.forEach(doc => {
            filmeArr.push(doc.value)
        })
        form.formUnterhaltung.forEach(doc => {
            unterhaltungArr.push(doc.value)
        })
        if (users.ref !== form.formRef) {
            firebase.firestore().collection('payments').where("userid", "==", userId).get().then(doc => {
                doc.forEach(doc => {
                    firebase.firestore().collection('payments').doc(doc.id).update({
                        ref: form.formRef
                    })
                })
            })
            firebase.firestore().collection('messagesList').where("to", "array-contains", userId).get().then(doc => {
                doc.forEach(doc => {
                    firebase.firestore().collection('messagesList').doc(doc.id).update({
                        ref: form.formRef
                    })
                })
            })
        }

        firebase.firestore().collection('users').doc(userId).update({
            "coin": Number(form.formCoin) ? Number(form.formCoin) : 0,
            "meta.ab": form.formAb ? form.formAb : "noavatar.jpg",
            "meta.avatarUrl": form.formAvatarUrl ? form.formAvatarUrl : "noavatar.jpg",
            "meta.avatarLarge": form.formAvatarLarge ? form.formAvatarLarge : "noavatar.jpg",
            "meta.avatarThumb": form.formAvatarThumb ? form.formAvatarThumb : "noavatar.jpg",
            "ref": form.formRef,
            "notification": form.formNotification,
            "meta.height": form.formHeight,
            "meta.country": form.formCountry,
            "meta.city": form.formCity,
            "meta.gender": Number(form.formGender) ? Number(form.formGender) : 0,
            "meta.body": form.formBody,
            "meta.eyeColor": form.formEyeColor,
            "meta.hairColor": form.formHairColor,
            "meta.style": form.formStyle,
            "meta.extra": form.formExtra,
            "hobs": hobsArr,
            "photos": form.formPhotos,
            "filter.relation": filterArr,
            "filter.gender": Number(form.filterGender) ? Number(form.filterGender) : 0,
            "filter.age": form.filterAge,
            "filter.country": form.filterCountry,
            "filter.city": form.filterCity,
            "info": form.formInfo ? form.formInfo : "",
            "meta.birtDate": new Date(form.birthDate),
            "meta.zip": Number(form.formZip) ? Number(form.formZip) : 0,
            "character.sprachen": sprachenArr,
            "character.sport": sportArr,
            "character.musik": musicArr,
            "character.filme": filmeArr,
            "character.unterhaltung": unterhaltungArr,
            "character.berufsstand": form.formBerufsstand ? form.formBerufsstand : "",
            "character.beziehungsstatus": form.formBeziehungsstatus ? form.formBeziehungsstatus : "",
            "character.kinder": form.formKinder ? form.formKinder : "",
            "character.abschluss": form.formAbschluss ? form.formAbschluss : "",
        }).then(doc => {
            dispatch(USERS_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(USERS_END())
        })
    }
}
const usersEnd = (e) => {
    return async (dispatch, getState) => {
        dispatch(USERS_END())
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(USERS_CHANGE({
            [name]: value
        }))
    }
}
const deleteUser = (userId) => {
    return async (dispatch, getState) => {
        dispatch(USERS_START())
        await firebase.firestore().collection("users").doc(userId).update({
            delete: true
        });

        dispatch(USERS_END())
    }
}




export { deleteUser, changeData, saveUser, getPayments, getCoinHistory, getMessages, getMessagesList, getLastMessage, getLastMessage2, getUserDetail, usersEnd, usersData };
