import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';
import { onChange } from "./form"
import { usersList } from "./usersList"
import ResizeImage from 'image-resize'
import Jimp from "jimp"
import geohash from "ngeohash";
import moment from "moment";
import { uuidv4 } from "../../Hooks"
import { defaultFakeId } from "../../Settings"

export const FAKES_START = createAction('FAKES_START');
export const FAKES_END = createAction('FAKES_END');
export const FAKES_SEARCH_USER = createAction('FAKES_SEARCH_USER');
export const FAKES_MESSAGES = createAction('FAKES_MESSAGES');
export const FAKES_DETAIL = createAction('FAKES_DETAIL');
export const FAKES_LAST_MESSAGES = createAction('FAKES_LAST_MESSAGES');
export const FAKES_LAST_MESSAGES2 = createAction('FAKES_LAST_MESSAGES2');
export const FAKES_MESSAGES_LIST = createAction('FAKES_MESSAGES_LIST');
export const FAKES_CHANGE = createAction('FAKES_CHANGE');


const resize = new ResizeImage({
    format: 'jpg',
    width: 640,
    outputType: "blob"
});

const resize2 = new ResizeImage({
    format: 'jpg',
    width: 300,
    outputType: "blob"
});

const resize3 = new ResizeImage({
    format: 'jpg',
    width: 64,
    outputType: "blob"
});

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

let unsubscribe
const fakesData = (first, head) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let fakes = getState().fakes
        dispatch(FAKES_START())
        let fireData = firebase.firestore().collection("users").where("role", "==", 1)
        if (form.ssId) {
            fireData = fireData.where("docId", "==", form.ssId)
        } else {
            if (form.search_nickname) {
                fireData = fireData.where("meta.nickname_search", ">=", form.search_nickname.toLowerCase());
            } else {
                if (form.searchStartDate) {
                    fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
                }
                if (form.searchEndDate) {
                    fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
                }
                if (form.search_country) {
                    fireData = fireData.where("meta.country", "==", form.search_country)
                }
                if (form.search_city) {
                    fireData = fireData.where("meta.city", "==", form.search_city)
                }
            }
        }
        if (!form.search_nickname) {
            head.forEach(doc => {
                if (doc.orderBy && doc.orderBy !== "disable") {
                    fireData = fireData.orderBy(doc.key, doc.orderBy)
                }
            })
        }
        if (fakes.lastData && fakes.lastData[(fakes.activePage - 1)] && first === "") {
            fireData = fireData.startAfter(fakes.lastData[(fakes.activePage - 1)])
        }
        fireData.limit(10).get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(FAKES_SEARCH_USER({
                    list: userList
                }))
                dispatch(FAKES_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (fakes.lastData && (fakes.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    fakes.lastData.push(_lastData)
                } else if (!fakes.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.gender ? "Weiblich" : "Mannlich" },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().photos && doc.data().photos.length },
                    { type: "text", value: doc.data().photos_callcenter && doc.data().photos_callcenter.length },
                    { type: "text", value: doc.data().note },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(FAKES_SEARCH_USER({
                list: userList
            }))
            dispatch(FAKES_END())
        })
    }
}
const getMessages = (id, userId, fakeId) => {
    return async (dispatch, getState) => {

        firebase.firestore().collection("users").doc(fakeId).get().then(doc => {
            if (doc.data()) {
                dispatch(usersList(doc))
            }
        })
        firebase.firestore().collection("messages").where("id", "==", id).orderBy("time", "desc").limit(30).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push(doc.data())
            })
            dispatch(FAKES_END())
            dispatch(FAKES_MESSAGES(arr))
        })
    }
}

const getLastMessage = (id) => {
    return async (dispatch, getState) => {
        let fakeId = []
        firebase.firestore().collection("messages").where("get", "==", id).orderBy("time", "desc").limit(20).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push({
                    message: doc.data().message,
                    time: doc.data().time.toDate(),
                    cc: doc.data().cc,
                    fake: doc.data().post
                })
                if (!fakeId.includes(doc.data().post)) {
                    fakeId.push(doc.data().post)
                }
            })
            fakeId.forEach(doc => {
                firebase.firestore().collection("users").doc(doc).get().then(doc => {
                    if (doc.data()) {
                        dispatch(usersList(doc))
                    }
                })
            })
            dispatch(FAKES_END())
            dispatch(FAKES_LAST_MESSAGES(arr))

        })
    }
}

const getLastMessage2 = (id) => {
    return async (dispatch, getState) => {
        let fakeId = []
        firebase.firestore().collection("messages").where("post", "==", id).orderBy("time", "desc").limit(20).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push({
                    message: doc.data().message,
                    time: doc.data().time.toDate(),
                    cc: doc.data().cc,
                    fake: doc.data().get,
                })
                if (!fakeId.includes(doc.data().get)) {
                    fakeId.push(doc.data().get)
                }
            })
            fakeId.forEach(doc => {
                firebase.firestore().collection("users").doc(doc).get().then(doc => {
                    if (doc.data()) {

                        dispatch(usersList(doc))
                        // dispatch(FAKES_FAKES_DATA({ [doc.id]: {
                        //     id:doc.id,
                        //     nickname:doc.data().meta.nickname,
                        //     avatar:doc.data().meta.avatarThumb,
                        // } }));
                    }
                })
            })
            dispatch(FAKES_END())
            dispatch(FAKES_LAST_MESSAGES2(arr))

        })
    }
}

const getMessagesList = (id) => {
    return async (dispatch, getState) => {
        firebase.firestore().collection("messagesList").where("to", "array-contains", id).orderBy("time", "desc").limit(30).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push({
                    id: doc.id,
                    avatar: doc.data().users[0].a,
                    nickname: doc.data().users[0].n,
                    fakeId: doc.data().to[0],
                    from: doc.data().allGetMessages,
                    to: doc.data().allSendMessages,
                    time: doc.data().time.toDate(),
                    lastMessage: doc.data().lastMessage
                })
            })
            dispatch(FAKES_END())
            dispatch(FAKES_MESSAGES_LIST(arr))
        })
    }
}


const saveFake = (userId) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        dispatch(FAKES_START())
        let hobsArr = [],
            sprachenArr = [],
            sportArr = [],
            musicArr = [],
            filmeArr = [],
            filterArr = [],
            unterhaltungArr = []

        form.formHobs.forEach(doc => {
            hobsArr.push(Number(doc.value))
        })
        form.filterRelation.forEach(doc => {
            filterArr.push(Number(doc.value))
        })
        form.formSprachen.forEach(doc => {
            sprachenArr.push(doc.value)
        })
        form.formSport.forEach(doc => {
            sportArr.push(doc.value)
        })
        form.formMusik.forEach(doc => {
            musicArr.push(doc.value)
        })
        form.formFilme.forEach(doc => {
            filmeArr.push(doc.value)
        })
        form.formUnterhaltung.forEach(doc => {
            unterhaltungArr.push(doc.value)
        })
        firebase.firestore().collection('users').doc(userId).update({
            "meta.ab": form.formAb ? form.formAb : "noavatar.jpg",
            "meta.avatarUrl": form.formAvatarUrl ? form.formAvatarUrl : "noavatar.jpg",
            "meta.avatarLarge": form.formAvatarLarge ? form.formAvatarLarge : "noavatar.jpg",
            "meta.avatarThumb": form.formAvatarThumb ? form.formAvatarThumb : "noavatar.jpg",
            "note": form.formNote ? form.formNote : "",
            "meta.country": Number(form.formCountry) || Number(form.formCountry) === 0 ? Number(form.formCountry) : form.formCountry,
            "meta.city": Number(form.formCity) || Number(form.formCity) === 0 ? Number(form.formCity) : form.formCity,
            "meta.gender": Number(form.formGender) || Number(form.formGender) === 0 ? Number(form.formGender) : 0,
            "meta.height": form.formHeight,
            "meta.body": form.formBody,
            "meta.eyeColor": form.formEyeColor,
            "meta.hairColor": form.formHairColor,
            "meta.style": form.formStyle,
            "meta.extra": form.formExtra,
            "hobs": hobsArr,
            "filter.relation": filterArr,
            "filter.gender": Number(form.filterGender),
            "filter.age": form.filterAge,
            photos: form.formPhotos ? form.formPhotos : [],
            pBlur: form.formPBlur ? form.formPBlur : [],
            photos_callcenter: form.formPhotos_callcenter ? form.formPhotos_callcenter : [],
            // "filter.country": form.filterCountry,
            // "filter.city": form.filterCity,
            "info": form.formInfo ? form.formInfo : "",
            "meta.birtDate": new Date(form.birthDate),
            "meta.zip": Number(form.formZip) ? Number(form.formZip) : 0,
            "character.sprachen": sprachenArr,
            "character.sport": sportArr,
            "character.musik": musicArr,
            "character.filme": filmeArr,
            "character.unterhaltung": unterhaltungArr,
            "character.berufsstand": form.formBerufsstand,
            "character.beziehungsstatus": form.formBeziehungsstatus,
            "character.kinder": form.formKinder,
            "character.abschluss": form.formAbschluss,
        }).then(doc => {
            dispatch(FAKES_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(FAKES_END())
        })
    }
}

const addFake = (userId) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        if (!form.birthDate) {
            return null
        }
        dispatch(FAKES_START())

        let hash = 0
        let lastLat = 0
        let lastLong = 0
        if (form.formCountry && form.formCity && form.formZip) {
            let _data = await firebase.firestore().collection(form.formCountry).doc(form.formCity).collection("data").where("zip", "==", Number(form.formZip)).limit(1).get()
            if (!_data.docs[0]) {
                _data = await firebase.firestore().collection(form.formCountry).doc(form.formCity).collection("data").limit(1).get()
            }
            lastLat = _data.docs[0] ? _data.docs[0].data().coordinates.latitude : 0;
            lastLong = _data.docs[0] ? _data.docs[0].data().coordinates.longitude : 0;
            hash = _data.docs[0] ? geohash.encode(lastLat, lastLong) : 0;
        }
        let range = 0;
        if (new Date(form.birthDate).getFullYear() >= 1994) {
            range = 0
        } else if (new Date(form.birthDate).getFullYear() >= 1984) {
            range = 1
        } else if (new Date(form.birthDate).getFullYear() >= 1974) {
            range = 2
        } else if (new Date(form.birthDate).getFullYear() >= 1964) {
            range = 3
        } else {
            range = 4
        }
        let user_id = await firebase.firestore().collection("users").orderBy("id", "desc").limit(1).get()
        let length = (Number(user_id.docs[0].data().id) + 1)

        let hobsArr = [],
            sprachenArr = [],
            sportArr = [],
            musicArr = [],
            filmeArr = [],
            filterArr = [],
            unterhaltungArr = []

        form.formHobs.forEach(doc => {
            hobsArr.push(Number(doc.value))
        })
        form.filterRelation.forEach(doc => {
            filterArr.push(Number(doc.value))
        })
        form.formSprachen.forEach(doc => {
            sprachenArr.push(doc.value)
        })
        form.formSport.forEach(doc => {
            sportArr.push(doc.value)
        })
        form.formMusik.forEach(doc => {
            musicArr.push(doc.value)
        })
        form.formFilme.forEach(doc => {
            filmeArr.push(doc.value)
        })
        form.formUnterhaltung.forEach(doc => {
            unterhaltungArr.push(doc.value)
        })
        try {
            firebase.firestore().collection('users').doc(defaultFakeId + "_" + length).set({
                docId: defaultFakeId + "_" + length,
                info: form.formInfo,
                hobs: hobsArr,
                filter: {
                    gender: Number(form.filterGender) ? Number(form.filterGender) : 0,
                    age: form.filterAge ? form.filterAge : "all",
                    // country : form.filterCountry ? form.filterCountry : "",
                    // city    : form.filterCity ? form.filterCity : "",
                    relation: filterArr,
                },
                id: length,
                note: form.formNote,
                meta: {
                    ab: form.formAb ? form.formAb : "noavatar.jpg",
                    avatarUrl: form.formAvatarUrl ? form.formAvatarUrl : "noavatar.jpg",
                    avatarLarge: form.formAvatarLarge ? form.formAvatarLarge : "noavatar.jpg",
                    avatarThumb: form.formAvatarThumb ? form.formAvatarThumb : "noavatar.jpg",
                    nickname: form.formNickname,
                    nickname_search: form.formNickname.toLowerCase(),
                    gender: Number(form.formGender) || Number(form.formGender) === 0 ? Number(form.formGender) : 0,
                    birtDate: new Date(form.birthDate),
                    country: Number(form.formCountry) || Number(form.formCountry) === 0 ? Number(form.formCountry) : form.formCountry,
                    city: Number(form.formCity) || Number(form.formCity) === 0 ? Number(form.formCity) : form.formCity,
                    like: 0,
                    zip: Number(form.formZip) ? Number(form.formZip) : 0,
                    height: form.formHeight ? form.formHeight : "",
                    body: form.formBody ? form.formBody : "",
                    eyeColor: form.formEyeColor ? form.formEyeColor : "",
                    hairColor: form.formHairColor ? form.formHairColor : "",
                    style: form.formStyle ? form.formStyle : "",
                    extra: form.formExtra ? form.formExtra : ""
                },
                online: false,
                photos: form.formPhotos ? form.formPhotos : [],
                pBlur: form.formPBlur ? form.formPBlur : [],
                photos_callcenter: form.formPhotos_callcenter ? form.formPhotos_callcenter : [],
                range: range,
                role: 1,
                timestamp: firebase_app.firestore.FieldValue.serverTimestamp(),
                coordinates: lastLat ? new firebase_app.firestore.GeoPoint(lastLat, lastLong) : 0,
                location: hash,
                character: {
                    abschluss: form.formAbschluss,
                    berufsstand: form.formBerufsstand,
                    beziehungsstatus: form.formBeziehungsstatus,
                    filme: filmeArr,
                    kinder: form.formKinder,
                    musik: musicArr,
                    sport: sportArr,
                    sprachen: sprachenArr,
                    unterhaltung: unterhaltungArr
                }
            }).then(doc => {
                dispatch(onChange("formNickname", ""));
                dispatch(onChange("formAvatarUrl", ""));
                dispatch(onChange("formAb", ""));
                dispatch(onChange("formAvatarLarge", ""));
                dispatch(onChange("formAvatarThumb", ""));
                dispatch(onChange("formPhotos", []));
                dispatch(onChange("formPBlur", []));
                dispatch(onChange("formPhotos_callcenter", []));
                dispatch(onChange("formHeight", ""));
                dispatch(onChange("formNote", ""));
                dispatch(onChange("birthDate", new Date(moment().subtract(18, 'years'))))
                dispatch(onChange("formCountry", 0))
                dispatch(onChange("formCity", 0))
                dispatch(onChange("formGender", "1"))
                dispatch(onChange("formZip", ""))
                dispatch(onChange("formInfo", ""))
                dispatch(onChange("filterRelation", [0]));
                dispatch(onChange("filterAge", "all"));
                dispatch(onChange("filterGender", "0"));
                dispatch(onChange("formBody", ""))
                dispatch(onChange("formEyeColor", ""))
                dispatch(onChange("formHairColor", ""))
                dispatch(onChange("formStyle", ""))
                dispatch(onChange("formExtra", ""))
                dispatch(onChange("formHobs", []));
                dispatch(onChange("formBerufsstand", ""));
                dispatch(onChange("formAbschluss", ""));
                dispatch(onChange("formBeziehungsstatus", ""));
                dispatch(onChange("formKinder", ""));
                dispatch(onChange("formUnterhaltung", []));
                dispatch(onChange("formFilme", []));
                dispatch(onChange("formMusik", []));
                dispatch(onChange("formSprachen", []));
                dispatch(onChange("formSport", []));
                dispatch(FAKES_END())
            })
        } catch (error) {
            console.log(error);
            dispatch(FAKES_END())
        }
    }
}
const getUserDetail = (id) => {
    return async (dispatch, getState) => {
        if (unsubscribe) {
            unsubscribe()
        }
        unsubscribe = firebase.firestore().collection("users").doc(id).onSnapshot(doc => {
            dispatch(FAKES_DETAIL(doc.data()))
        })
    }
}

const fakesEnd = (e) => {
    return async (dispatch, getState) => {
        dispatch(FAKES_END())
    }
}




export const deletePhoto = (e) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        let arr = form.formPhotos
        arr.splice(e, 1)
        dispatch(onChange("formPhotos", arr, true))
        // firebase.firestore().collection('users').doc(docId).update({
        // photos: arr
        // })
    }
}
export const deletePhotoCC = (e) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        let arr = form.formPhotos_callcenter
        arr.splice(e, 1)
        dispatch(onChange("formPhotos_callcenter", arr, true))
        // firebase.firestore().collection('users').doc(docId).update({
        // photos_callcenter: arr
        // })
    }
}
export const photoUpdate3 = (e) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        let id_1 = uuidv4()
        resize.play(e).then(async (response) => {
            firebase.storage().ref('images/' + id_1).put(response).then(doc => {
                firebase.storage().ref('images/' + id_1).getDownloadURL().then(url => {
                    let arr = form.formPhotos_callcenter
                    if (arr) {
                        arr.push(url)
                    } else {
                        arr = [url]
                    }
                    dispatch(onChange("formPhotos_callcenter", arr, true))
                    // firebase.firestore().collection('users').doc(docId).update({
                    //     photos_callcenter: arr
                    // })
                })
            })

        })
    }
}
export const photoUpdate2 = (e) => {
    return async (dispatch, getState) => {
        console.log(e);
        let id_1 = uuidv4()
        let id_2 = uuidv4()
        const form = getState().form;
        resize.play(e).then(async (response) => {
            console.log(response);
            firebase.storage().ref('images/' + id_1).put(response).then(doc => {
                firebase.storage().ref('images/' + id_1).getDownloadURL().then(url => {
                    let arr = form.formPhotos.slice()
                    console.log(arr);
                    if (arr) {
                        arr.push(url)
                    } else {
                        arr = [url]
                    }
                    console.log(arr);
                    dispatch(onChange("formPhotos", arr, true))
                    // firebase.firestore().collection('users').doc(docId).update({
                    //     photos: arr
                    // })
                })
            })
            //blur
            let _image = await Jimp.read(URL.createObjectURL(response))
            _image.blur(40).quality(30).resize(250, Jimp.AUTO)
            let _limage = await _image.getBase64Async("image/JPEG")
            let _data = _limage.replace("data:image/JPEG;base64,", "")
            const contentType = 'image/JPEG';
            const b64Data = _data;
            const _blob = b64toBlob(b64Data, contentType);
            firebase.storage().ref('images/' + id_2).put(_blob).then(doc => {
                firebase.storage().ref('images/' + id_2).getDownloadURL().then(url => {
                    let arr = form.formPBlur
                    if (arr) {
                        arr.push(url)
                    } else {
                        arr = [url]
                    }
                    dispatch(onChange("formPBlur", arr, true))
                    // firebase.firestore().collection('users').doc(docId).update({
                    //     pBlur: arr
                    // })
                }).catch((error) => { console.error(error) });
            }).catch((error) => { console.error(error) });

        })
    }
}
export const photoUpdate = (e) => {
    return async (dispatch, getState) => {
        let id_1 = uuidv4()
        let id_2 = uuidv4()
        let id_3 = uuidv4()
        let id_4 = uuidv4()
        resize.play(e).then((response) => {
            if (response.size < 200000) {
                firebase.storage().ref('images/' + id_1).put(response).then(doc => {
                    firebase.storage().ref('images/' + id_1).getDownloadURL().then(url => {
                        console.log(url)
                        dispatch(onChange("formAvatarUrl", url))
                        // firebase.firestore().collection('users').doc(docId).update({
                        // "meta.avatarUrl": url
                        // }).catch(doc => { console.log(doc.message) })
                    }).catch(doc => { console.log(doc.message) });
                }).catch(doc => { console.log(doc.message) });
            }
        }).catch(doc => { console.log(doc.message) })

        resize2.play(e).then(async (response) => {
            firebase.storage().ref('images/' + id_2).put(response).then(doc => {
                firebase.storage().ref('images/' + id_2).getDownloadURL().then(url => {
                    dispatch(onChange("formAvatarLarge", url))
                    // firebase.firestore().collection('users').doc(docId).update({
                    //     "meta.avatarLarge": url
                    // }).catch(doc => { console.log(doc.message) })
                }).catch(doc => { console.log(doc.message) });
            }).catch(doc => { console.log(doc.message) });

            //blur
            let _image = await Jimp.read(URL.createObjectURL(response))
            _image.blur(40).quality(30).resize(250, Jimp.AUTO)
            let _limage = await _image.getBase64Async("image/JPEG")
            let _data = _limage.replace("data:image/JPEG;base64,", "")
            const contentType = 'image/JPEG';
            const b64Data = _data;
            const _blob = b64toBlob(b64Data, contentType);
            firebase.storage().ref('images/' + id_3 + '.jpg').put(_blob).then(doc => {
                firebase.storage().ref('images/' + id_3 + '.jpg').getDownloadURL().then(url => {
                    dispatch(onChange("formAb", url))
                    // firebase.firestore().collection('users').doc(docId).update({
                    //     "meta.ab": url
                    // })
                });
            });
        }).catch(doc => { console.log(doc.message) })

        resize3.play(e).then((response) => {
            firebase.storage().ref('images/' + id_4).put(response).then(doc => {
                firebase.storage().ref('images/' + id_4).getDownloadURL().then(url => {
                    dispatch(onChange("formAvatarThumb", url))
                    // firebase.firestore().collection('users').doc(docId).update({
                    //     "meta.avatarThumb": url,
                    // }).catch(doc => { console.log(doc.message) })
                }).catch(doc => { console.log(doc.message) });
            }).catch(doc => { console.log(doc.message) });
        }).catch(doc => { console.log(doc.message) })
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(FAKES_CHANGE({
            [name]: value
        }))
    }
}



export { changeData, addFake, getLastMessage2, getMessagesList, getLastMessage, getUserDetail, getMessages, saveFake, fakesEnd, fakesData };
