import React, { useEffect} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import DateRange from "../../components/Items/dateRange"
// import { getRandomFake } from '../../state/actions/users';
import {FormattedMessage, useIntl} from 'react-intl';

const Search = (props) => {
    const {  citys } = useSelector(
        (state) => ({
        citys: state.form.sCitys ? state.form.sCitys : []
        }), shallowEqual
    );
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, [dispatch]);
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="sId" type="text" placeholder="ID" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="sNickname" type="text" placeholder={intl.formatMessage({id:"Dashboard.nickname"})} bar="false"/>
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="sRange" placeholder="Im Alter Von" items={[{ label: "18 - 25", value: "0" }, { label: "25 - 35", value: "1" }, { label: "35 - 45", value: "2" }, { label: "55 - 65", value: "3" }]} />
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="sGender" placeholder="Gender" items={[{ label: "Weiblich", value: 1 }, { label: "Männlich", value: 0 }]} />
            </li>
            <li>
            <Select style={{marginBottom:0}} styletype="type_2" name="sCountry" label="Land" placeholder="Land" items={[{label:"Österreich", value:"at"}, {label:"Deutschland", value:"de"}, {label:"Schweiz", value:"ch"}]} />
            </li>
            <li>
            <Select style={{marginBottom:0}} styletype="type_2" name="sCity" label="Region" placeholder="Region" items={citys} />
            </li>
            <li>
                <DateRange name1="sStartDate" name2="sEndDate" styletype="type_2" />
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
        </ul>
    </div>
  )
}

export default Search;
