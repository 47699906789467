import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

// import { authReducer } from './auth';
import { preferencesReducer } from './preferences';
import { authReducer } from './auth';
import { formReducer } from './form';
import { logsReducer } from './logs';
import payments from './payments';
import online from './online';
import affiliates from './affiliates';
import affirmations from './affirmations';
import messages from './messages';
import dashboard from './dashboard';
import general from './general';
import users from './users';
import usersList from './usersList';
import fakes from './fakes';
import callcenters from './callcenters';
import admins from './admins';
import contacts from './contacts';
import settings from './settings';
import users_deleted from './users_deleted';
import { newsletters } from './newsletters';
import auto_messages from './auto_messages';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  users_deleted: persistReducer(
    { key: 'users_deleted', storage },
    users_deleted
  ),
  settings: persistReducer(
    { key: 'settings', storage },
    settings
  ),
  contacts: persistReducer(
    { key: 'contacts', storage },
    contacts
  ),
  admins: persistReducer(
    { key: 'admins', storage },
    admins
  ),
  callcenters: persistReducer(
    { key: 'callcenters', storage },
    callcenters
  ),
  users: persistReducer(
    { key: 'users', storage },
    users
  ),
  fakes: persistReducer(
    { key: 'fakes', storage },
    fakes
  ),
  form: persistReducer(
    { key: 'form', storage },
    formReducer
  ),
  logs: persistReducer(
    { key: 'logs', storage },
    logsReducer
  ),
  payments: persistReducer(
    { key: 'payments', storage },
    payments
  ),
  online: persistReducer(
    { key: 'online', storage },
    online
  ),
  affiliates: persistReducer(
    { key: 'affiliates', storage },
    affiliates
  ),
  messages: persistReducer(
    { key: 'messages', storage },
    messages
  ),
  dashboard: persistReducer(
    { key: 'dashboard', storage },
    dashboard
  ),
  general: persistReducer(
    { key: 'general', storage },
    general
  ),
  newsletters: persistReducer(
    { key: 'newsletters', storage },
    newsletters
  ),
  usersList: persistReducer(
    { key: 'usersList', storage },
    usersList
  ),
  auto_messages: persistReducer(
    { key: 'auto_messages', storage },
    auto_messages
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  affirmations: persistReducer(
    { key: 'affirmations', storage },
    affirmations
  ),
  toastr: toastrReducer,
});
