import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import paths from './paths';
import Layout from "../components/Layout/Layout"

const PrivateRoute = ({ path, component: Component }) => {
  const { id } = useSelector(
    state => ({
      id: state.auth.userData.docId
    }),
    shallowEqual
  );


  return (
    <Route
      exact
      path={path}
      render={() => (id ? <Layout><Component /></Layout> : <Navigate to={paths.ROOT} />)}
    />
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default PrivateRoute;
