import { createReducer } from 'redux-act';

import {
    LOGS_DEVICE_STAT,
    LOGS_END,
    LOGS_DATA,
    LOGS_START,
    LOGS_WEB_STAT
} from '../actions/logs';

const initialState = {};

export const logsReducer = createReducer(
    {
        [LOGS_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [LOGS_END]: (state, payload) => {
            return ({
                ...state,
                loading: false
            })
        },
        [LOGS_WEB_STAT]: (state, payload) => {
            return ({
                ...state,
                web: payload,
                loading: false
            })
        },
        [LOGS_DEVICE_STAT]: (state, payload) => {
            return ({
                ...state,
                device: payload,
                loading: false
            })
        },
        [LOGS_DATA]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
    },
    initialState
);
