import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import { usersList } from "./usersList"


export const USERS_DELETED_START = createAction('USERS_DELETED_START');
export const USERS_DELETED_END = createAction('USERS_DELETED_END');
export const USERS_DELETED_DATA = createAction('USERS_DELETED_DATA');
export const USERS_DELETED_CHANGE = createAction('USERS_DELETED_CHANGE');
export const USER_DELETED_DETAIL = createAction('USER_DELETED_DETAIL');
export const LAST_MESSAGES_DELETED = createAction('LAST_MESSAGES_DELETED');
export const LAST_MESSAGES2_DELETED = createAction('LAST_MESSAGES2_DELETED');
export const USERS_DELETED_MESSAGES_LIST = createAction('USERS_DELETED_MESSAGES_LIST');
export const USERS_DELETED_MESSAGES = createAction('USERS_DELETED_MESSAGES');
export const USER_DELETED_HISTORY = createAction('USER_DELETED_HISTORY');
export const USER_DELETED_PAYMENT_DATA = createAction('USER_DELETED_PAYMENT_DATA');

let unsubscribe = ""
let unsubscribe2 = ""



const usersData = (first, head) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let users = getState().users_deleted
        let adminData = getState().auth.userData
        dispatch(USERS_DELETED_START())
        let fireData = firebase.firestore().collection("usersDeleted")
        if(adminData.role === 2){
            fireData = fireData.where("ref", "==", adminData.ref)
        }
        if(form.search_id){
            fireData = fireData.where("docId", "==", form.search_id)
        }else{
            if (form.search_nickname) {
                fireData = fireData.where("meta.nickname_search", "==", form.search_nickname.toLowerCase());
            }else{
                if(form.searchStartDate){
                    fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
                }
                if(form.searchEndDate){
                    fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
                }
                if (form.search_ref) {
                    fireData = fireData.where("ref", "==", form.search_ref)
                }
            }
        }
        head.forEach(doc=>{
            if(doc.orderBy && doc.orderBy !== "disable"){
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            } 
        })
        if(users.lastData && users.lastData[(users.activePage - 1)] && first === ""){
            console.log(users.lastData[(users.activePage - 1)]);
            fireData = fireData.startAfter(users.lastData[(users.activePage - 1)])
        }
        fireData.limit(10).get().then(async doc => {
            let userList = []
            if(!doc.size){
                dispatch(USERS_DELETED_DATA({
                    list: userList
                }))
                dispatch(USERS_DELETED_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if(doc && _lastData){
                if(users.lastData && (users.lastData.findIndex(x => x.id === _lastData.id) === -1)){
                    users.lastData.push(_lastData)
                    console.log(users);
                }else if(!users.lastData){
                    console.log(_lastData);
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc=>{
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["verify"] = doc.data().verify
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.email},
                    { type: "text", value: doc.data().meta.gender ? "Weiblich" : "Mannlich" },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip ? doc.data().meta.zip : "" },
                    { type: "badge", value: doc.data().online ? "Online" : "Offline", class:doc.data().online ? "success" : "danger"},
                    { type: "text", value: doc.data().ref},
                    { type: "badge", value: doc.data().verify ? "True" : "False", class:doc.data().verify ? "success" : "danger"},
                    { type: "text", value: doc.data().pay ? doc.data().pay.toFixed(2) : 0},
                    { type: "star", value: doc.data().coin, class:doc.data().prize.sendMessage ? "" : "1"},
                    { type: "date", value:  new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(USERS_DELETED_DATA({
                list: userList
            }))
            dispatch(USERS_DELETED_END())
        })
    }
}

const getUserDetail = (id) => {
    return async (dispatch, getState) => {
        if(unsubscribe){
            unsubscribe()
        }
        unsubscribe = firebase.firestore().collection("usersDeleted").doc(id).onSnapshot(doc=>{
            dispatch(USER_DELETED_DETAIL(doc.data()))
        })
    }
}


const usersEnd = (e) => {
    return async (dispatch, getState) => {
        dispatch(USERS_DELETED_END())
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(USERS_DELETED_CHANGE({
            [name]: value
        }))
    }
}





const getLastMessage = (id) => {
    return async (dispatch, getState) => {
        console.log(id);
        let fakeId = []
        firebase.firestore().collection("messages").where("get", "==", id).orderBy("time", "desc").limit(20).get().then(doc=>{
            let arr = []
            doc.forEach(doc=>{
                arr.push({
                    message:doc.data().message,
                    time:doc.data().time.toDate(),
                    cc:doc.data().cc,
                    fake:doc.data().post
                })
                if(!fakeId.includes(doc.data().post)){
                    fakeId.push(doc.data().post)
                }
            })
            fakeId.forEach(doc=>{
                firebase.firestore().collection("users").doc(doc).get().then(doc=>{
                    if(doc.data()){
                        dispatch(usersList(doc))
                        // dispatch(USERS_FAKES_DATA({ [doc.id]: {
                        //     id:doc.id,
                        //     nickname:doc.data().meta.nickname,
                        //     avatar:doc.data().meta.avatarThumb,
                        // } }));
                    }
                })
            })
            dispatch(USERS_DELETED_END())
            dispatch(LAST_MESSAGES_DELETED(arr))

        })
    }
}

const getLastMessage2 = (id) => {
    return async (dispatch, getState) => {
        let fakeId = []
        firebase.firestore().collection("messages").where("post", "==", id).orderBy("time", "desc").limit(20).get().then(doc=>{
            let arr = []
            doc.forEach(doc=>{
                arr.push({
                    message:doc.data().message,
                    time:doc.data().time.toDate(),
                    cc:doc.data().cc,
                    fake:doc.data().get,
                })
                if(!fakeId.includes(doc.data().get)){
                    fakeId.push(doc.data().get)
                }
            })
            fakeId.forEach(doc=>{
                firebase.firestore().collection("users").doc(doc).get().then(doc=>{
                    if(doc.data()){

                        dispatch(usersList(doc))
                        // dispatch(USERS_FAKES_DATA({ [doc.id]: {
                        //     id:doc.id,
                        //     nickname:doc.data().meta.nickname,
                        //     avatar:doc.data().meta.avatarThumb,
                        // } }));
                    }
                })
            })
            dispatch(USERS_DELETED_END())
            dispatch(LAST_MESSAGES2_DELETED(arr))
            
        })
    }
}

const getMessagesList = (id) => {
    return async (dispatch, getState) => {
        firebase.firestore().collection("messagesList").where("to", "array-contains", id).orderBy("time", "desc").limit(30).get().then(doc=>{
            let arr = []
            doc.forEach(doc=>{
                arr.push({
                    id:doc.id,
                    avatar:doc.data().users[1].a,
                    nickname:doc.data().users[1].n,
                    fakeId:doc.data().to[1],
                    from:doc.data().allGetMessages,
                    to:doc.data().allSendMessages,
                    time:doc.data().time.toDate(),
                    lastMessage: doc.data().lastMessage
                })
            })
            dispatch(USERS_DELETED_END())
            dispatch(USERS_DELETED_MESSAGES_LIST(arr))
        })
    }
}

const getMessages = (id, userId, fakeId) => {
    return async (dispatch, getState) => {

        firebase.firestore().collection("usersDeleted").doc(fakeId).get().then(doc=>{
            if(doc.data()){
                dispatch(usersList(doc))
            }
        })
        firebase.firestore().collection("messages").where("id", "==", id).orderBy("time", "desc").limit(30).get().then(doc=>{
            let arr = []
            doc.forEach(doc=>{
                arr.push(doc.data())
            })
            dispatch(USERS_DELETED_END())
            dispatch(USERS_DELETED_MESSAGES(arr))
        })
    }
}

const getCoinHistory = (id) => {
    return async (dispatch) => {
      if (!id){return null}
  
      firebase.firestore().collection("history").where("a", "==", id).orderBy("time", "desc").limit(30).get().then(async doc => {
        let arr = []
        doc.forEach(doc=>{
            arr.push(doc.data())
        })
        dispatch(USERS_DELETED_END())
        dispatch(USER_DELETED_HISTORY(arr))
      })
    }
}

const getPayments = (id) => {
    return async (dispatch, getState) => {
        let payment = getState().general.packets;
        if (!id){return null}

        let packets = []
        payment.forEach(doc=>{
            packets[doc.paket] = doc
        })
        if(unsubscribe2){
            unsubscribe2()
        }
        unsubscribe2 = firebase.firestore().collection("payments").where("userid", "==", id).orderBy("time", "desc").limit(30).onSnapshot(doc => {
            let list = []
            doc.forEach(doc=>{
                let pushArr = []
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                // { type: "avatar", value: doc.data().paytype },
                { type: "bold", value: packets[doc.data().packet] && packets[doc.data().packet].title },
                { type: "text", value: doc.data().pay2 + "€" },
                { type: "bold", value: packets[doc.data().packet] && (packets[doc.data().packet].coin + packets[doc.data().packet].extraCoin) + " Coin" },
                { type: "badge", value: doc.data().function, class: doc.data().function === "ok" ? "success" : doc.data().function === "new" ? "primary" : "danger"  },
                { type: "text", value: doc.data().time ? doc.data().time.toDate().toLocaleString() : "" },
                { type: "text", value: doc.data().paytype },
                ]
                list.push(pushArr);
            })
            dispatch(USERS_DELETED_END())
            dispatch(USER_DELETED_PAYMENT_DATA(list))
        })
    }
}
export { getMessagesList, getLastMessage2, getMessages, getCoinHistory, getPayments, getLastMessage, getUserDetail, changeData, usersEnd, usersData};
