import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import { onChange } from "./form"
import firebase_app from 'firebase/compat/app';


export const ADMINS_START = createAction('ADMINS_START');
export const ADMINS_END = createAction('ADMINS_END');
export const ADMINS_SEARCH_USER = createAction('ADMINS_SEARCH_USER');
export const ADMINS_USER_DETAIL = createAction('ADMINS_USER_DETAIL');

let unsubscribe = ""

const usersData = () => {
    return async (dispatch, getState) => {
        let form = getState().form
    }
}


const getUserDetail = (id) => {
    return async (dispatch, getState) => {
        if (unsubscribe) {
            unsubscribe()
        }
    }
}


const saveUser = (userId) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        dispatch(ADMINS_START())
        firebase.firestore().collection('admins').doc(userId).update({
            "username": form.formNickname,
            "email": form.formEmail,
            "ref": form.formRef,
            "type": form.formType,
            "role": Number(form.formRole),
            "password": form.formPassword,
            "status": form.formStatus ? true : false
        }).then(doc => {
            dispatch(ADMINS_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(ADMINS_END())
        })
    }
}
const addAdmin = () => {
    return async (dispatch, getState) => {
        const form = getState().form;
        if (!form.formNickname || !form.formEmail || !form.formPassword) {
            return null
        }
        dispatch(ADMINS_START())
        firebase.firestore().collection('admins').add({
            "email": form.formEmail,
            "password": form.formPassword,
            "ref": form.formRef,
            "type": form.formType,
            "role": form.formRole,
            "status": form.formStatus ? true : false,
            "username": form.formNickname,
            "timestamp": firebase_app.firestore.FieldValue.serverTimestamp(),
        }).then(doc => {
            firebase.firestore().collection('admins').doc(doc.id).update({
                docId: doc.id,
                id: doc.id
            })
            dispatch(onChange("formRef", ""))
            dispatch(onChange("formNickname", ""))
            dispatch(onChange("formEmail", ""))
            dispatch(onChange("formPassword", ""))
            dispatch(onChange("formStatus", ""))
            dispatch(onChange("formType", ""))
            dispatch(onChange("formRole", ""))
            dispatch(ADMINS_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(ADMINS_END())
        })
    }
}
const usersEnd = (e) => {
    return async (dispatch, getState) => {
        dispatch(ADMINS_END())
    }
}
export { addAdmin, saveUser, getUserDetail, usersEnd, usersData };
