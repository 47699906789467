
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import { readAndCompressImage } from 'browser-image-resizer';
import {defaultProject} from "../../Settings"

const config = {
    quality: 1,
    maxWidth: 1920,
    maxHeight: 1920,
    autoRotate: true,
    debug: true
};


export const SETTINGS_START = createAction('SETTINGS_START');
export const SETTINGS_END = createAction('SETTINGS_END');
export const SETTINGS_DATA = createAction('SETTINGS_DATA');
export const PACKET_CHANGE = createAction('PACKET_CHANGE');


export const onChange = (name, value) => {
    return async (dispatch, getState) => {
        const settings = getState().settings;
        // console.log(settings[name]);
        // console.log(value);
        if(settings[name] !== value){
            // console.log(name);
            // console.log(value);
            return dispatch(SETTINGS_DATA({[name]:value}));
        }
    }
}

export const changePacket = (name, value, index) => {
    return async (dispatch, getState) => {
        // const settings = getState().settings;
        // settings.packets[index][name] = index
        return dispatch(PACKET_CHANGE({
            name:name,
            index:index,
            value:value  
        }));
    }
}

export const deletePacket = (index, docId) => {
    return async (dispatch, getState) => {
        let settings = getState().settings;
        if(docId){
            try {
                firebase.firestore().collection("settings").doc("packets").collection("data").doc(docId).delete();
            } catch (error) {
                
            }
        }
        const cloneRecipes = [...settings.packets];
        cloneRecipes.splice(index, 1);
        return dispatch(SETTINGS_DATA({packets:cloneRecipes}));
    }
}

export const addPacket = () => {
    return async (dispatch, getState) => {
        let settings = getState().settings;
        const cloneRecipes = [...settings.packets];
        cloneRecipes.push({
            docId:"",
            id:0,
            title:"",
            coin:0,
            discount:0,
            extraCoin:0,
            freeImage:0,
            list:[],
            month:0,
            mountly:"",
            paket:0,
            pay:0,
            payStr:"",
            payText:0,
            type:""
        });
        return dispatch(SETTINGS_DATA({packets:cloneRecipes}));
    }
}

export const SavePacket = () => {
    return async (dispatch, getState) => {
        let settings = getState().settings;
        settings.packets.map(doc=>{
            if(doc.docId){
                firebase.firestore().collection("settings").doc("packets").collection("data").doc(doc.docId).update({
                    id:Number(doc.id),
                    title:doc.title,
                    coin:Number(doc.coin),
                    discount:Number(doc.discount),
                    extraCoin:Number(doc.extraCoin),
                    freeImage:Number(doc.freeImage),
                    list:[],
                    month:Number(doc.month),
                    mountly:doc.mountly,
                    paket:Number(doc.paket),
                    pay:Number(doc.pay),
                    payStr:doc.payStr,
                    payText:Number(doc.payText),
                    type:doc.type 
                })
            }else{
                firebase.firestore().collection("settings").doc("packets").collection("data").add({
                    id:Number(doc.id),
                    title:doc.title,
                    coin:Number(doc.coin),
                    discount:Number(doc.discount),
                    extraCoin:Number(doc.extraCoin),
                    freeImage:Number(doc.freeImage),
                    list:[],
                    month:Number(doc.month),
                    mountly:doc.mountly,
                    paket:Number(doc.paket),
                    pay:Number(doc.pay),
                    payStr:doc.payStr,
                    payText:Number(doc.payText),
                    type:doc.type 
                })  
            }
        })
    }
}

export const photoUpdate = (e, template) => {
    return async (dispatch, getState) => {
        // const edit_ref = getState().settings.edit_ref;
        let id = defaultProject
        let tem = (template + 1).toString();
        readAndCompressImage(e.files[0], config)
            .then(resizedImage => {
                firebase.storage().ref('sites/' + id + '/' + tem + "-" + e.files[0].name).put(resizedImage).then(doc => {
                    firebase.storage().ref('sites/' + id + '/' + tem + "-" + e.files[0].name).getDownloadURL().then(url => {
                        console.log(url);
                        console.log(tem);
                        firebase.firestore().collection("settings").doc("templates").collection("data").doc(tem).update({
                            bg: url
                        })
                    }).catch((error) => { console.error(error) });
                }).catch((error) => { console.error(error) });
            });
    }
}


export const settingsSave = () => {
    return async (dispatch, getState) => {
        const settings = getState().settings;
        dispatch(SETTINGS_START())
        settings.templates.forEach((doc, index)=>{
            console.log(index);
            firebase.firestore().collection("settings").doc("templates").collection("data").doc(doc.id).update({
                bg: settings["temp"+index+"_bg"],
                text_1: settings["temp"+index+"_1"],
                text_2: settings["temp"+index+"_2"],
                text_3: settings["temp"+index+"_3"],
                text_4: settings["temp"+index+"_4"],
                text_5: settings["temp"+index+"_5"],
                text_6: settings["temp"+index+"_6"],
            })
        })
        await firebase.firestore().collection("settings").doc("general").update({
            title: settings.title,
            template: settings.template,
            facebook: settings.facebook,
            instagram: settings.instagram,
            freeCredit: Number(settings.freeCredit),
            messageCoin: Number(settings.messageCoin),
            messagePhotoCoin: Number(settings.messagePhotoCoin),
            profilePhotoCoin: Number(settings.profilePhotoCoin),
            gifCoin: Number(settings.gifCoin),
        })

        await firebase.firestore().collection("settings").doc("prize").update({
            login: Number(settings.prize_login),
            loginCoin: Number(settings.prize_loginCoin),
            profile: Number(settings.prize_profile),
            profilePhotoCoin: Number(settings.prize_profilePhotoCoin),
            sendMessage: Number(settings.prize_sendMessage),
            sendMessageCoin: Number(settings.prize_sendMessageCoin),
        })

        await firebase.firestore().collection("settings").doc("info").update({
            Datenschutz: settings.Datenschutz,
            Impressum: settings.Impressum,
            Nutzungdbedingungen: settings.Nutzungdbedingungen,
        })

        await firebase.firestore().collection("settings").doc("faq").update({
            faqTitle1: settings.faqTitle1,
            faqTitle2: settings.faqTitle2,
            faqTitle3: settings.faqTitle3,
            faqTitle4: settings.faqTitle4,
            faq1: settings.faq1,
            faq2: settings.faq2,
            faq3: settings.faq3,
            faq4: settings.faq4,
        })
        dispatch(SETTINGS_END())
    }
}


export const getSettings = () => {
    return async (dispatch, getState) => {

        dispatch(SETTINGS_START())
        await firebase.firestore().collection("settings").doc("templates").collection("data").get().then(doc=>{
            let templates = []
            let index = 0
            doc.forEach((doc)=>{
                templates.push({id:doc.id})
                dispatch(onChange("temp"+index+"_bg", doc.data().bg))
                dispatch(onChange("temp"+index+"_1", doc.data().text_1))
                dispatch(onChange("temp"+index+"_2", doc.data().text_2))
                dispatch(onChange("temp"+index+"_2", doc.data().text_2))
                dispatch(onChange("temp"+index+"_3", doc.data().text_3))
                dispatch(onChange("temp"+index+"_4", doc.data().text_4))
                dispatch(onChange("temp"+index+"_5", doc.data().text_5))
                dispatch(onChange("temp"+index+"_6", doc.data().text_6))
                index++
            })
            dispatch(onChange("templates", templates))
        })

        await firebase.firestore().collection("settings").doc("faq").get().then(doc=>{
            dispatch(onChange("faq1", doc.data().faq1))
            dispatch(onChange("faq2", doc.data().faq2))
            dispatch(onChange("faq3", doc.data().faq3))
            dispatch(onChange("faq4", doc.data().faq4))
            dispatch(onChange("faqTitle1", doc.data().faqTitle1))
            dispatch(onChange("faqTitle2", doc.data().faqTitle2))
            dispatch(onChange("faqTitle3", doc.data().faqTitle3))
            dispatch(onChange("faqTitle4", doc.data().faqTitle4))
        })

        await firebase.firestore().collection("settings").doc("info").get().then(doc=>{
            dispatch(onChange("Datenschutz", doc.data().Datenschutz))
            dispatch(onChange("Impressum", doc.data().Impressum))
            dispatch(onChange("Nutzungdbedingungen", doc.data().Nutzungdbedingungen))
        })

        await firebase.firestore().collection("settings").doc("prize").get().then(doc=>{
            dispatch(onChange("prize_login", doc.data().login))
            dispatch(onChange("prize_loginCoin", doc.data().loginCoin))
            dispatch(onChange("prize_profile", doc.data().profile))
            dispatch(onChange("prize_profilePhotoCoin", doc.data().profilePhotoCoin))
            dispatch(onChange("prize_sendMessage", doc.data().sendMessage))
            dispatch(onChange("prize_sendMessageCoin", doc.data().sendMessageCoin))
        })

        await firebase.firestore().collection("settings").doc("general").get().then(doc=>{
            dispatch(onChange("title", doc.data().title))
            dispatch(onChange("template", doc.data().template))
            dispatch(onChange("facebook", doc.data().facebook))
            dispatch(onChange("instagram", doc.data().instagram))
            dispatch(onChange("freeCredit", doc.data().freeCredit))
            dispatch(onChange("messageCoin", doc.data().messageCoin))
            dispatch(onChange("messagePhotoCoin", doc.data().messagePhotoCoin))
            dispatch(onChange("profilePhotoCoin", doc.data().profilePhotoCoin))
            dispatch(onChange("gifCoin", doc.data().gifCoin))
        })
        await firebase.firestore().collection("settings").doc("packets").collection("data").orderBy("id").get().then(doc=>{
            let arr = []
            doc.forEach(doc=>{
                let a = {
                    docId:doc.id ? doc.id : "",
                    id:Number(doc.data().id) ? Number(doc.data().id) : "",
                    title:doc.data().title ? doc.data().title : "",
                    coin:Number(doc.data().coin) ? Number(doc.data().coin) : 0,
                    discount:Number(doc.data().discount) ? Number(doc.data().discount) : 0,
                    extraCoin:Number(doc.data().extraCoin) ? Number(doc.data().extraCoin) : 0,
                    freeImage:Number(doc.data().freeImage) ? Number(doc.data().freeImage) : 0,
                    list:doc.data().list ? doc.data().list : [],
                    month:Number(doc.data().month) ? Number(doc.data().month) : 0,
                    mountly:doc.data().mountly ? doc.data().mountly : "",
                    paket:Number(doc.data().paket) ? Number(doc.data().paket) : 0,
                    pay:Number(doc.data().pay) ? Number(doc.data().pay) : 0,
                    payStr:doc.data().payStr ? doc.data().payStr : "",
                    payText:Number(doc.data().payText) ? Number(doc.data().payText) : 0,
                    type:doc.data().type ? doc.data().type : ""
                } ;
                arr.push(a)
            })
            dispatch(onChange("packets", arr))
        })
        dispatch(SETTINGS_END())
    }
}