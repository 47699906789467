import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import Login from "../../components/auth/Login"
import { Navigate } from 'react-router-dom';
import paths from '../../Router/paths';
import { verifyAuth } from "../../state/actions/auth"
import { defaultLandingBg, defaultLandingImg } from "../../Settings"
import Modals from "../../components/Layout/Modals/Index"

const Index = () => {
  const { isAuth } = useSelector(
    (state) => ({
      isAuth: state.auth.userData.id
    }), shallowEqual
  );


  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(verifyAuth())
  // }, [dispatch]);
  // const redirect = isAuth && <Navigate to={paths.DASHBOARD} />;
  return (
    <div id="home" className={styles.home} style={{ minHeight: window.innerHeight, backgroundImage: "url(" + defaultLandingBg + ")" }}>
      {/* {redirect} */}
      {/* style={{ backgroundImage: `url(${Img})` }} */}
      <div className={styles.content}>
        <div className={classNames(styles.left, "text-center")}>
          <div className={styles.login}>
            <Login />
          </div>
        </div>
        <div className={classNames(styles.right)} id="right">
          <img src={defaultLandingImg} alt="" />
        </div>
      </div>
      <Modals />
    </div>
  );
};

export default Index;
