import { createReducer } from 'redux-act';

import {
    USERSLIST,
} from '../actions/usersList';

const initialState = {};

const newsletters = createReducer(
    {
        [USERSLIST]: (state, payload) => {
            return ({
                ...state,
                ...payload
            })
        }
    },
    initialState
);
export default newsletters