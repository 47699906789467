import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import DateRange from "../../components/Items/dateRange"
// import { getRandomFake } from '../../state/actions/users';
import {FormattedMessage, useIntl} from 'react-intl';
import {defaultNewsletterOptions} from "../../Settings"

const Search = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, [dispatch]);
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="nNickname" type="text" placeholder={intl.formatMessage({id:"Dashboard.nickname"})} bar="false"/>
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="nType" placeholder={intl.formatMessage({id:"Dashboard.type"})} items={
                    defaultNewsletterOptions.map(doc=>{
                        return {
                            label:doc.name,
                            value:doc.id,
                        }
                    })} bar="false" />
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="sType" placeholder={"Status"} items={[{label:"Sending", value:"Sending"}, {label:"Ok", value:"Ok"}]} bar="false" />
            </li>
            <li>
                <DateRange name1="nStartDate" name2="nEndDate" styletype="type_2" />
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
        </ul>
    </div>
  )
}

export default Search;
