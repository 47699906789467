import { createReducer } from 'redux-act';

import {
    FAKES_START,
    FAKES_END,
    FAKES_SEARCH_USER,
    FAKES_DETAIL,
    FAKES_MESSAGES,
    FAKES_LAST_MESSAGES,
    FAKES_LAST_MESSAGES2,
    FAKES_MESSAGES_LIST,
    FAKES_CHANGE
} from '../actions/fakes';

const initialState = {};

const newsletters = createReducer(
    {
        [FAKES_CHANGE]: (state, payload) => {
            return ({
                ...state,
                ...payload
            })
        },
        [FAKES_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [FAKES_LAST_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                lastMessages: payload,
            })
        },
        [FAKES_LAST_MESSAGES2]: (state, payload) => {
            return ({
                ...state,
                lastMessages2: payload,
            })
        },
        [FAKES_MESSAGES_LIST]: (state, payload) => {
            return ({
                ...state,
                messagesList: payload,
            })
        },
        [FAKES_END]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                error: ""
            })
        },
        [FAKES_DETAIL]: (state, payload) => {
            return ({
                ...state,
                detail:payload
            })
        },
        [FAKES_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                messages: payload,
            })
        },
        [FAKES_SEARCH_USER]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
    },
    initialState
);
export default newsletters