import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
// import { logUser} from "./logs"
import { onChange } from "./form"
import { logsData2 } from "./logs.js"
// import { logsUpdate} from "./logs"

export const AUTH_START = createAction('AUTH_START');
export const AUTH_END = createAction('AUTH_END');
export const AUTH_FAIL = createAction('AUTH_FAIL');
export const AUTH_RESET = createAction('AUTH_RESET');
export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');
export const AUTH_USER_DATA = createAction('AUTH_USER_DATA');
export const COINS_DATA = createAction('COINS_DATA');

let unsubscribe = ""
const logOut = () => {
  return async (dispatch) => {
    dispatch(AUTH_START());
    dispatch(onChange("email", ""))
    dispatch(onChange("password", ""))
    dispatch(AUTH_RESET())
  };
};

const fetchUserData = (W) => {

  return async (dispatch, getState) => {
    dispatch(AUTH_START());
    // const { W } = firebase.auth();





    if (W) {
      let first = true
      firebase.firestore().collection("admins").doc(W).onSnapshot(doc => {
        if (doc.data()) {
          dispatch(
            AUTH_USER_DATA({
              id: doc.id,
              ...doc.data(),
            })
          );
          setTimeout(() => {

            dispatch(logsData2())
            // dispatch(logUser())
          }, 1000)
          if (first) {
            first = false;
          }
        } else {
          firebase.auth().signOut();
          return dispatch(AUTH_RESET());
        }
      })
    }
  };
};
const sendEmail = (e) => {
  return (dispatch, getState) => {
    const { general } = getState().general;
    try {
      var actionCodeSettings = {
        url: general.link + '?' + firebase.auth().W
      };
      firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
    } catch (err) {
      console.log(err)
    }
  }
}
const deleteProfile = (e) => {
  return (dispatch, getState) => {
    const { docId } = getState().auth.userData;
    try {
      firebase.firestore().collection('users').doc(docId).update({
        delete: true,
        online: false
      }).then(doc => {
        firebase.auth().signOut();
      })
    } catch (err) {
      console.log(err)
    }
  }
}

const verifyAuth = () => {
  return async (dispatch, getState) => {

    const { email, password } = getState().form;
    dispatch(AUTH_START());
    var __url_string = window.location.href;
    var __url = new URL(__url_string);
    if (__url.searchParams.get("email") && __url.searchParams.get("password")) {
      dispatch(onChange("email", __url.searchParams.get("email")))
      dispatch(onChange("password", __url.searchParams.get("password")))
      dispatch(loginAuth())
    } else if (email && password) {
      dispatch(loginAuth())
    } else {
      dispatch({
        type: "AUTH_USER_DATA",
        payload: { id: "" }
      })
      dispatch(AUTH_USER_DATA({ id: "" }));
      dispatch(AUTH_END());
      firebase.auth().signOut()
    }
  };
};
const loginAuth = () => {
  return async (dispatch, getState) => {
    const { email, password } = getState().form;
    dispatch(AUTH_START());
    // let user = await firebase.auth().signInWithEmailAndPassword(email, password)
    if (unsubscribe) {
      unsubscribe()
    }
    dispatch(logsData2())
    // let user = await firebase.auth().signInWithEmailAndPassword(email, password)
    // if(user){
    //   dispatch(logsUpdate(user.user.uid))
    //   let userData = await firebase.firestore().collection("users").doc(user.user.uid).get()
    firebase.auth().signInWithEmailAndPassword(email, password).then(user => {
      if (user) {
        unsubscribe = firebase.firestore().collection("admins").doc(user.user.uid).onSnapshot(doc => {
          if (!doc.data()) {
            firebase.auth().signOut()
            dispatch(AUTH_FAIL({ error: { type: "error", message: "Benutzer wurde nicht gefunden." } }));
            dispatch(AUTH_USER_DATA({ id: "" }));
            dispatch(AUTH_END());
            return null
          }
          if (doc.data().disabled || doc.data().deleted) {
            firebase.auth().signOut()
            dispatch(AUTH_FAIL({ error: { type: "error", message: "Dieser Benutzer wurde gelöscht oder blockiert." } }));
            dispatch(AUTH_USER_DATA({ id: "" }));
            dispatch(AUTH_END());
          } else if (doc.data()) {
            // dispatch(logsUpdate(doc.id))
            dispatch(AUTH_END());
            dispatch(
              AUTH_USER_DATA({
                id: doc.id,
                ...doc.data(),
              })
            );
            dispatch(fetchUserData(doc.id))


          } else {
            firebase.auth().signOut()
            dispatch(AUTH_END());
            dispatch(AUTH_USER_DATA({ id: "" }));
          }
        })
      } else {
        dispatch(AUTH_FAIL({ error: { type: "error", message: "Benutzer wurde nicht gefunden." } }));
        dispatch(AUTH_USER_DATA({ id: "" }));
        dispatch(AUTH_END());
      }
    }).catch(error => {
      dispatch(AUTH_FAIL({ error: { type: "error", message: "error" } }));
    })
  };
};

const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());
const cleanErrorAuth = () => (dispatch) => dispatch(AUTH_FAIL({ error: "" }));


export { logOut, fetchUserData, sendEmail, deleteProfile, loginAuth, authCleanUp, cleanErrorAuth, verifyAuth };
