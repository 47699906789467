import { createReducer } from 'redux-act';

import {
    CONTACTS_END,
    CONTACTS_DATA,
    CONTACTS_START,
} from '../actions/contacts';

const initialState = {};

const contacts = createReducer(
    {
        [CONTACTS_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [CONTACTS_END]: (state, payload) => {
            return ({
                ...state,
                loading: false
            })
        },
        [CONTACTS_DATA]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
    },
    initialState
);

export default contacts