import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { paymentsData, changeData} from "../../state/actions/payments"
import Table from "../../components/Items/table"
import TableTotal from "../../components/Items/tableTotal"
import TableHead from "./TableHead"
import Stats from "./Stats"
import Affiliates from "./Affiliates"

import {onChange} from "../../state/actions/form"
import classNames from "classnames"

const Users = () => {
    const { loading, list, lastData, activePage, totalPage} = useSelector(
        (state) => ({
            loading: state.payments.loading,
            list: state.payments.list ? state.payments.list : [],
            lastData: state.payments.lastData ? state.payments.lastData : [],
            activePage: state.payments.activePage ? state.payments.activePage : 0,
            totalPage: state.payments.totalPage ? state.payments.totalPage : 0,
        }), shallowEqual
    );
    const dispatch = useDispatch();
    // const [lastData, lastDataSet] = useState([])
    // const [perPage, perPageSet] = useState(10)
    // const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Nickname", orderBy:"", key:"nickname"}, 
        {name:"Id", orderBy:"disable", key:"id"}, 
        {name:"Type", orderBy:"", key:"paytype"}, 
        {name:"Value", orderBy:"", key:"pay2"}, 
        {name:"Packet", orderBy:"", key:"packet"}, 
        {name:"Result", orderBy:"", key:"function"}, 
        {name:"Ref", orderBy:"disable", key:"ref"}, 
        {name:"Date", orderBy:"desc", key:"time"}, 
        {name:"Settings", orderBy:"disable", key:""}
    ])

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("search_id", ""))
        dispatch(onChange("search_nickname", ""))
        dispatch(onChange("search_user_id", ""))
        dispatch(onChange("searchStartDate", ""))
        dispatch(onChange("searchEndDate", ""))
        dispatch(onChange("search_paytype", ""))
        dispatch(onChange("search_packet", ""))
        dispatch(onChange("search_function", ""))
        setTimeout(() => {
            dispatch(changeData("activePage", 0))
            dispatch(changeData("totalPage", 0))
            dispatch(changeData("lastData", 0))
            dispatch(paymentsData("first", head))
            // dispatch(paymentsData("first", lastData, lastDataSet, perPage, head, false, activePage))
        }, 1000);
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        dispatch(changeData("activePage", 0))
        dispatch(changeData("totalPage", 0))
        dispatch(changeData("lastData", 0))
        dispatch(paymentsData("first", change))
        // activePageSet(0)
        // lastDataSet(0)
        // dispatch(paymentsData("first", 0, lastDataSet, perPage, change, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            dispatch(changeData("activePage", activePage - 1))
            if(!totalPage){
                dispatch(paymentsData("", head))
            }
        }else if(type === "next"){
            dispatch(changeData("activePage", activePage + 1))
            if(!totalPage){
                dispatch(paymentsData("", head))
            }
        }else{
            dispatch(changeData("activePage", type))
            if(!totalPage){
                dispatch(paymentsData("", head))
            }
        }
    }
    return (
        <section className={Styles.Pages}>
            {/* <div className={Styles.headerBottom}>
                <div className="left">
                </div>
                <div className="right">
                    <NavLink to={"/callcenters/add"}>
                        <ButtonSuccess style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0, marginTop:0 }}   text={"Add Callcenter"} />
                    </NavLink> 
                </div>
            </div> */}
            <div className={Styles.row}>
                {totalPage ? <div className={classNames(Styles.container, Styles.wHalf)}><div className={Styles.contentWhite} style={{marginBottom:20}}>
                    <Stats list={list} />
                </div></div> : ""}
                {totalPage ? <div className={classNames(Styles.container, Styles.wHalf)}><div className={Styles.contentWhite} style={{marginBottom:20}}>
                    <Affiliates list={list} />
                </div></div> : ""}
            </div>
            <div className={Styles.contentWhite}>

                <TableHead onsearch={(e) => {dispatch(changeData("activePage", 0));dispatch(changeData(0));dispatch(paymentsData("first", head))}} onsearchtotal={(e) => {dispatch(changeData("activePage", 0));dispatch(changeData(0));dispatch(changeData("totalPage", 0));dispatch(paymentsData("first", head, true))}} />
                {!totalPage ?<Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="paymentPage" />
                : <TableTotal class={"type_2"} head={head} lastData={lastData} activePage={activePage} totalPage={totalPage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="paymentPage" />}
                {loading ?<div className="loading"></div> : ""}
                {/* <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(paymentsData("first", 0, lastDataSet, perPage, head, 0))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="paymentPage" />
                {loading ?<div className="loading"></div> : ""} */}
            </div>
        </section>
    )
}

export default Users;
