import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import DateRange from "../../components/Items/dateRange"
import {FormattedMessage, useIntl} from 'react-intl';

const Head = (props) => {
    const { citys } = useSelector(
        (state) => ({
        citys: state.form.edit_filter_citys ? state.form.edit_filter_citys : []
        }), shallowEqual
    );
  const intl = useIntl();
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_id" type="text" placeholder="User ID" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_nickname" size="12" type="text" placeholder={intl.formatMessage({id:"Dashboard.nickname"})} bar="false"/>
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="search_country" placeholder="Land" items={[{label:"Österreich", value:"at"}, {label:"Deutschland", value:"de"}, {label:"Schweiz", value:"ch"}]} />
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="search_city" placeholder="Region" items={citys} />
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_ref" type="text" placeholder="Ref"  size="12" />
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="search_verify" placeholder="Verify" items={[{label:"True", value:"true"}, {label:"False", value:"false"}]} />
            </li>
            <li>
                <DateRange name1="searchStartDate" name2="searchEndDate" styletype="type_2" />
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
        </ul>
    </div>
  )
}

export default Head;
