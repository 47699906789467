import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch} from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import { getCoinHistory } from "../../state/actions/users"
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const ListActivity = ({id}) => {
    const {  historyList} = useSelector(
        (state) => ({
            historyList: state.users.historyList ? state.users.historyList : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCoinHistory(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (<div className={classNames(styles.tabContent)}>
        <div className={classNames(styles.title, styles.p)}>
            <h3>List Activity</h3>
        </div>
        <ul className={styles.listActivity}>
            {historyList.map((doc, index) => {
                return (<li key={index}>
                    <div className={styles.list}>
                        <span className={classNames(styles.badge, doc.t === "profile_image" ? styles.type_1 : doc.t === "message_image" ? styles.type_2 : doc.t === "buy_coin" ? styles.type_3 : "")}>
                            <i></i>
                        </span>
                        <span>
                            <p>{doc.t}</p>
                            <p><span className={classNames(styles.a)}>{doc.u}</span> - <span className={classNames(styles.danger, styles.a)}>{doc.c} Coin</span></p>
                            <small>{timeAgo.format(new Date(doc.time.seconds * 1000), 'twitter') }</small>
                        </span>
                    </div>
                </li>)
            })}
        </ul>

    </div>
    )
}

export default ListActivity;
