import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './Form.module.scss';
import Input from "../../components/Items/input"
import {ButtonPrimary} from "../../components/Items/buttons"
import {loginAuth} from "../../state/actions/auth"
import {defaultVersion, defaultProject, defaultName} from "../../Settings"
const Login = () => {
    const { loading} = useSelector(
        (state) => ({
        loading: state.auth.loading,
        }), shallowEqual
    );

  const dispatch = useDispatch();
  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(loginAuth());
  };
  return (
      <div className={styles.content}>
        <div className={styles.header}>
          <b>Welcome to {defaultName}</b>
          <p>{defaultProject} {defaultVersion}</p>
        </div>
      
        <form>
          <Input styletype="type_1" size="large" name="email" type="email" label="E-Mail Adresse" />
          <Input styletype="type_1" size="large" name="password" type="password" label="Passwort" />
          <ButtonPrimary styletype="type_1" onClick={onSubmitHandler} classnamess="mt-4" text={"Einloggen"} loading={loading ? "true" : ""} disabled={loading} />
        </form>
      </div>
  );
};

export default Login;