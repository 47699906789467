import React, { useEffect } from 'react';
import { onChange } from '../../state/actions/form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './Form.module.scss';
import classNames from 'classnames';
import moment from 'moment';
import {ButtonPrimaryLigth} from "../../components/Items/buttons"
import TrashIcon from "../../assets/img/icons/trash.svg"
let date = moment(new Date(new Date())).format('YYYY-MM-DD')
const Input = (props) => {
    const dispatch = useDispatch();
    const { form } = useSelector(
        (state) => ({
            form: state.form
        }), shallowEqual
    );
    useEffect(() => {
        // if (!form.birthDate){
        //     dispatch(onChange("props.name", eighteenYearsAgo))
        // }
        // eslint-disable-next-line
    }, [dispatch]);

    let _type = props.styletype === "type_1" ? styles.type_1 : props.styletype === "type_2" ? styles.type_2 : ""
    return(<div>    
    {/* {form[props.name2] ? moment(new Date(form[props.name2])).format('YYYY-MM-DD') : date} */}
        <div className={classNames(styles.formGroup, styles.dateRange, _type)}> 
            <input type="date" id={props.name1} name={props.name1} value={form[props.name1] && moment(new Date(form[props.name1])).format('YYYY-MM-DD')}  max={form[props.name2] ? moment(new Date(form[props.name2])).format('YYYY-MM-DD') : date} required onChange={(e) => dispatch(onChange(props.name1, moment(new Date(e.target.value)).startOf('day')))}></input>
            <input type="date" id={props.name2} name={props.name2} value={form[props.name2] && moment(new Date(form[props.name2])).format('YYYY-MM-DD')}  min={form[props.name1] ? moment(new Date(form[props.name1])).format('YYYY-MM-DD') : date} required onChange={(e) => dispatch(onChange(props.name2, moment(new Date(e.target.value)).endOf('day')))}></input>
            {props.styletype === "type_1" ? <ButtonPrimaryLigth onClick={props.onsearch2 ? props.onsearch2 : (e) => dispatch(props.onsearch(true))} style={{height:30, width:70, fontSize:12, borderTopLeftRadius:0, borderBottomLeftRadius:0}} styletype="type_1" text={"Apply"} /> : ""}
            {props.styletype === "type_2" ? <ButtonPrimaryLigth onClick={(e) => {dispatch(onChange(props.name1, "")); dispatch(onChange(props.name2, ""))}} style={{height:42, width:42, paddingLeft:0, paddingRight:0, fontSize:12, borderTopLeftRadius:0, borderBottomLeftRadius:0}} styletype="type_1" text={<img style={{width:18}} src={TrashIcon} alt="" />} /> : ""}
        </div>
    </div> )
}
export default Input;