import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import {ccStat } from "../../state/actions/callcenters"
import DateRange from "../../components/Items/dateRange"
import classNames from "classnames"
import TableStyle from '../../components/Items/Table.module.scss';
import firebase from "../../firebase"

const Stats = ({list}) => {
    const dispatch = useDispatch();
    const [affiliate, affiliateSet] = useState([])
    const [affiliateName, affiliateNameSet] = useState([])
    useEffect(() => {
        let arr = []
        // console.log(list);
        list.forEach(doc=>{
            let i = arr.findIndex(x => x.name === doc.data[4].value)
            if(i !== -1){
                if(doc.data[5].value === "new"){
                    arr[i].newPacket = arr[i].newPacket + 1
                }else if(doc.data[5].value === "ok"){
                    arr[i].totalPayment = arr[i].totalPayment + Number(doc.data[3].value)
                    arr[i].buyPacket = arr[i].buyPacket + 1
                }else{
                    console.log(doc.data[5].value)
                }
            }else{
                arr.push({
                    name:doc.data[4].value,
                    newPacket:doc.data[5].value === "new" ? 1 : 0,
                    totalPayment:doc.data[5].value === "ok" ? Number(doc.data[3].value) : 0,
                    buyPacket: doc.data[5].value === "ok" ? 1 : 0
                })
            }
        })
        affiliateSet(arr)
        // eslint-disable-next-line
    }, [list]);
    let total = 0
    affiliate.forEach(doc=>{
        total+=doc.totalPayment
    })
    return (<div>
        <div className={classNames(styles.tabContent)} style={{paddingBottom:20, paddingTop:20}}>
            <div className={TableStyle.tableContent}>
                <table className={classNames(TableStyle.table)}>
                    <thead>
                        <tr>
                            <th>Packet</th>
                            <th>New</th>
                            <th>Ok</th>
                            <th>Total Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {affiliate.map((doc,index) =>{
                            return <tr>
                            <td>{affiliateName[doc.name] ? affiliateName[doc.name] : doc.name}</td>
                            <td>{doc.newPacket}</td>
                            <td>{doc.buyPacket}</td>
                            <td>{Number(doc.totalPayment).toFixed(2)}€</td>
                        </tr>
                        })}
                        
                    </tbody>
                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td>{Number(total).toFixed(2)}€</td>
                            </tr>
                        </tfoot>
                </table>
            </div>
        </div>
    </div>
    )
}

export default Stats;
