import { createReducer } from 'redux-act';

import {
    AFFILLIATES_STATS,
    AFFILIATE_START,
    AFFILIATE_END,
    AFFILIATES_SEARCH_USER,
    AFFILIATES_SEARCH_USER_DELETED,
    AFFILIATE_DATA,
    AFFILIATES_CHANGE
} from '../actions/affiliates';

const initialState = {
  loading: false
};

const authReducer = createReducer(
  {
    [AFFILLIATES_STATS]: (state, payload) => ({
      ...state,
      stats: payload
    }),
    [AFFILIATE_START]: (state, payload) => ({
      ...state,
      loading: true
    }),
    [AFFILIATE_END]: (state, payload) => ({
      ...state,
      loading: false
    }),
    [AFFILIATES_SEARCH_USER_DELETED]: (state, payload) => {
        return ({
            ...state,
            list_deleted: payload.list,
        })
    },
    [AFFILIATES_SEARCH_USER]: (state, payload) => {
        return ({
            ...state,
            list: payload.list,
        })
    },
    [AFFILIATES_CHANGE]: (state, payload) => {
        return ({
            ...state,
            ...payload
        })
    },
    [AFFILIATE_DATA]: (state, payload) => ({
      ...state,
      list: payload.list
    })
  },
  initialState
);

export default authReducer