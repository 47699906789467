import { createReducer } from 'redux-act';

import {
    WAITING_MESSAGES,
    TOTAL_MESSAGES,
    MESSAGES_DATA,
    MESSAGES_START,
    MESSAGES_END,
} from '../actions/messages';
import {
  MESSAGES_IMAGE_DATA,
  MESSAGES_CHANGE
} from '../actions/messages_image';

const initialState = {
  loading: false
};

const authReducer = createReducer(
  {
    [MESSAGES_START]: (state, payload) => ({
      ...state,
      loading:true
    }),
    [MESSAGES_END]: (state, payload) => ({
      ...state,
      loading:false
    }),
    [MESSAGES_CHANGE]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [WAITING_MESSAGES]: (state, payload) => ({
      ...state,
      waiting: payload

    }),
    [TOTAL_MESSAGES]: (state, payload) => ({
      ...state,
      total: payload
    }),
    [MESSAGES_DATA]: (state, payload) => ({
      ...state,
      list: payload.list
    }),
    [MESSAGES_IMAGE_DATA]: (state, payload) => ({
      ...state,
      list2: payload.list
    })
  },
  initialState
);

export default authReducer