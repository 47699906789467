import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Styles from "../../assets/styles/Pages.module.scss"
import {  search } from "../../state/actions/newsletters"
import {  onChange } from "../../state/actions/form"
import Table from "../../components/Items/table"
import TableHead2 from "./TableHead2"
import Arrow from "../../assets/img/icons/arrow.svg"

const AddNewsletter = () => {
    const { searchList } = useSelector(
        (state) => ({
            searchList: state.newsletters.searchList ? state.newsletters.searchList : []
        }), shallowEqual
    );
    // const { id } = useParams();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"", key:"meta.nickname"}, 
        {name:"Country", orderBy:"", key:"meta.country"}, 
        {name:"City", orderBy:"", key:"meta.city"}, 
        {name:"Zip", orderBy:"", key:"meta.zip"}, 
        {name:"Send", orderBy:"disable", key:""}, 
        {name:"Time", orderBy:"desc", key:"timestamp"}, 
        {name:"Settings", orderBy:"disable"}
    ])
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("sId", ""))
        dispatch(onChange("sNickname", ""))
        dispatch(onChange("sGender", ""))
        dispatch(onChange("sRange", ""))
        dispatch(onChange("sCountry", ""))
        dispatch(onChange("sCity", ""))
        dispatch(onChange("sStartDate", ""))
        dispatch(onChange("sEndDate", ""))
        dispatch(onChange("edit_search_nickname_newsletter", ""))
        dispatch(onChange("ssStartDate", ""))
        dispatch(onChange("ssEndDate", ""))
        dispatch(onChange("edit_search_online", ""))
        dispatch(onChange("edit_search_range2", ""))
        dispatch(onChange("edit_search_avatar", ""))
        dispatch(onChange("edit_filter_payment", ""))
        dispatch(onChange("edit_filter_coin_up", ""))
        dispatch(onChange("edit_filter_coin_down", ""))
        dispatch(onChange("edit_filter_userId", ""))
        dispatch(onChange("edit_filter_ref", ""))
        dispatch(onChange("edit_filter_country", ""))
        dispatch(onChange("edit_filter_city", ""))
        dispatch(onChange("edit_search_gender2", ""))

        setTimeout(() => {
            dispatch(search("first", lastData, lastDataSet, perPage, head, false, activePage))
        }, 1000);
        // eslint-disable-next-line
    }, [dispatch]);

    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(search("", lastData, lastDataSet, perPage, head, false, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(search("", lastData, lastDataSet, perPage, head, false, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(search("", lastData, lastDataSet, perPage, head, false, type))
        }
    }

    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(search("first", 0, lastDataSet, perPage, change, false, 0))
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className={Styles.left}>
                    <NavLink to="/newsletters">
                        Newsletters
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <span>
                        Select Fake
                    </span>
                </div>
                <div className={Styles.right}>
                </div>
            </div>
            <div className={Styles.contentWhite} style={{marginBottom:25, paddingBottom:1}}>
                <TableHead2  onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(search("first", 0, lastDataSet, perPage, head, false, 0))}} />
                {searchList.length ? <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={searchList} buttonType="addNewsletter" /> : ""}
            </div>
        </section>
    )
}

export default AddNewsletter;
