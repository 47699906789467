import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import {ccStat } from "../../state/actions/callcenters"
import DateRange from "../../components/Items/dateRange"
import classNames from "classnames"

const Stats = ({id}) => {
    const { stats} = useSelector(
        (state) => ({
            stats: state.callcenters.stats ? state.callcenters.stats : []
        }), shallowEqual
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ccStat(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (<div>
        <div className={classNames(styles.tabContent)}>
            <div className={classNames(styles.title, styles.p)}>
                <h3>Stats</h3>
                <div className={styles.buttons}>
                    <DateRange name1="startDateCC" name2="endDateCC" styletype="type_1" onsearch2={(e) => dispatch(ccStat(id))} />
                </div>
            </div>
            <div className={classNames(styles.total)}>
                <ul>
                    <li>
                        <span>Total Out</span>
                        <b>{stats.tg}</b>
                    </li>
                    <li>
                        <span>Total In</span>
                        <b>{stats.ta}</b>
                    </li>
                    <li>
                        <span>Asa 1</span>
                        <b>{stats.asa1}</b>
                    </li>
                    <li>
                        <span>Asa 2</span>
                        <b>{stats.asa2}</b>
                    </li>
                    <li>
                        <span>Asa 3</span>
                        <b>{stats.asa3}</b>
                    </li>
                    <li>
                        <span>Waiting</span>
                        <b>{stats.waiting}</b>
                    </li>
                    <li>
                        <span>Auto</span>
                        <b>{stats.auto}</b>
                    </li>
                    <li>
                        <span>Match</span>
                        <b>{stats.match}</b>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default Stats;
