import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import Table from "../../components/Items/table"
import classNames from "classnames"
import { getPayments } from "../../state/actions/users"

const Payments = ({id}) => {
    const {payments} = useSelector(
        (state) => ({
            payments: state.users.payments ? state.users.payments : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getPayments(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (<div className={classNames(styles.tabContent)}>
        <div className={classNames(styles.title, styles.p)}>
            <h3>Payments</h3>
        </div>
        <div className={styles.payments}>
            <Table class={"type_2"} break={true} body={payments} buttonType="payment" />
        </div>
    </div>
    )
}

export default Payments;
