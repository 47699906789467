import React, { useEffect} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './Style.module.scss';
import { verifyAuth} from "../../state/actions/auth"
import { onlineUsers} from "../../state/actions/online"
// import { lastPayments} from "../../state/actions/payments"
import { messagesStat, waitingStat} from "../../state/actions/messages"
import Payments from "./Payments"
import PaymentPacket from "./PaymentPacket"
import OnlineUsers from "./OnlineUsers"
// import Affiliate from "./Affiliate"
import PaymentType from "./PaymentType"
import WaitingMessages from "./WaitingMessages"
import BestUser from "./BestUser"
import NewUsers from "./NewUsers"
import NewMessages from "./NewMessages"
import WebAnalytics from "./WebAnalytics"
import classNames from 'classnames';
const Index = () => {
  const { userData} = useSelector(
    (state) => ({
      userData: state.auth.userData
    }), shallowEqual
  );


  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(verifyAuth())
    dispatch(onlineUsers())
    dispatch(messagesStat())
    setTimeout(() => {
      dispatch(waitingStat())
    }, 5000);
  }, [dispatch]);

  return (
    <div id="dashboard" className={styles.dashboard}>
      <div className={styles.row}>
        <div className={styles.container}>
          <BestUser />
        </div>
        <div className={styles.container}>
          <PaymentType />
        </div>
        <div className={styles.half}>
          <div className={styles.row}>
        {userData.role === 1 ? <div className={classNames(styles.container, styles.full)}>
              <NewMessages />
            </div> : ""}
            
            <div className={classNames(styles.container, styles.full)}>
              <NewUsers />
            </div>
          </div>
          <WaitingMessages />
        </div>
      </div>
      <div className={styles.row}>
        <div className={classNames(styles.container, styles.w2)}>
        <Payments />
        </div>

        <div className={styles.container}>
        <OnlineUsers />
        </div>
      </div>
      <div className={styles.row}>
      {userData.role === 1 ? <div className={classNames(styles.container, styles.wHalf)}>
          <WebAnalytics />
        </div> : ""}
        <div className={classNames(styles.container, styles.wHalf)}>
          <PaymentPacket />
        </div>
      </div>
    </div>
  );
};

export default Index;
