import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { usersData} from "../../state/actions/admins"
import Table from "../../components/Items/table"
import TableHead from "./TableHead"
import { NavLink } from 'react-router-dom';
import { ButtonSuccess } from "../../components/Items/buttons"
import {onChange} from "../../state/actions/form"

const Admins = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.admins.loading,
            list: state.admins && state.admins.list ? state.admins.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    
    const [head, headSet] = useState([
        {name:"Nickname", orderBy:"", key:"nickname"}, 
        {name:"Email", orderBy:"", key:"email"}, 
        {name:"Ref", orderBy:"", key:"ref"}, 
        {name:"Role", orderBy:"", key:"role"}, 
        {name:"Status", orderBy:"", key:"status"},
        {name:"Settings", orderBy:"disable", key:""}
    ])

    useEffect(() => {
        window.scrollTo(0, 0);
        
        dispatch(onChange("search_id", ""))
        dispatch(onChange("search_nickname", ""))

        dispatch(usersData())
        // eslint-disable-next-line
    }, [dispatch]);
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className="left">
                </div>
                <div className="right">
                    <NavLink to={"/admins/add"}>
                        <ButtonSuccess style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0, marginTop:0 }}   text={"Add Admin"} />
                    </NavLink> 
                </div>
            </div>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {dispatch(usersData())}} />
                <Table class={"type_2"} head={head} body={list} buttonType="admins" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Admins;
