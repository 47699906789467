import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import moment from "moment"
import { onChange } from './form.js';
export const START_PAYMENT = createAction('START_PAYMENT');
export const PAYMENTS_END = createAction('PAYMENTS_END');
export const LAST_PAYMENTS_STATS = createAction('LAST_PAYMENTS_STATS');
export const TOTAL_PAYMENTS_STATS = createAction('TOTAL_PAYMENTS_STATS');
export const BEST_USER = createAction('BEST_USER');
export const PAYMENTS_DATA = createAction('PAYMENTS_DATA');
export const PAYMENTS_CHANGE = createAction('PAYMENTS_CHANGE');
let monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

let unsubscribe = ""
let unsubscribe2 = ""
Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
  }
Date.prototype.downHours = function(h) {
    this.setTime(this.getTime() - (h*60*60*1000));
    return this;
  }
const lastPayments = (type) => {
    return (dispatch, getState) => {
        dispatch(START_PAYMENT())
        let form = getState().form
        let userData = getState().auth.userData
        let startDate = new Date(moment().subtract(31, 'days').startOf('day'))
        let endDate = new Date(moment().endOf('day'))
        if(!type){
            dispatch(onChange("startDate", startDate))
            dispatch(onChange("endDate", endDate))
        }else if(form.startDate && form.endDate){
            startDate = new Date(moment(form.startDate).startOf('day'))
            endDate = new Date(moment(form.endDate).endOf('day'))
        }
        let startMonth = new Date(moment().startOf('month'))
        if(unsubscribe){
            unsubscribe()
        }
        let fire = firebase.firestore().collection("payments")
        if(type && form.startDate && form.endDate){
            fire = fire.where("time", "<=", endDate)
        }
        if(userData.role === 2){
            fire = fire.where("ref", "==", userData.ref)
        }
        console.log(userData.role);
        unsubscribe = fire.where("time", ">=", startDate).where("function", "==", "ok").onSnapshot(doc=>{
            let series = [];
            let series_week = [];
            let series_month = [];
            let refs = []
            let refs_week = []
            let refs_month = []
            let pay_type = []
            let packet_type = []
            let findBestUser = []
            doc.forEach(doc=>{
                let time = doc.data().time.toDate();
                if(!time){
                    time = doc.data().time.toDate()
                }
                time = time.downHours(1)
                time = monthName[time.getMonth()] + " " + time.getDate()

                let date = doc.data().time;
                if(!date){
                    date = doc.data().time
                }
                date = date.toDate()

                //pay_type
                if (packet_type.findIndex(x => x.name === doc.data().packet) === -1){
                    packet_type.push({
                        value:1,
                        name:doc.data().packet
                    })
                }else{
                    packet_type[packet_type.findIndex(x => x.name === doc.data().packet)].value += 1 
                }
                //pay_type
                if(doc.data().paytype){
                    // !refs[doc.data().ref]
                    if (pay_type.findIndex(x => x.name === doc.data().paytype) === -1){
                        pay_type.push({
                            value:1,
                            name:doc.data().paytype
                        })
                    }else{
                        pay_type[pay_type.findIndex(x => x.name === doc.data().paytype)].value += 1 
                    }
                }
                //refs
                if(doc.data().ref){
                    if (!refs[doc.data().ref]){
                        refs[doc.data().ref] = Number(doc.data().pay2)
                    }else{
                        refs[doc.data().ref] = refs[doc.data().ref] + Number(doc.data().pay2)
                    }
                    if(date > startMonth){
                        if (!refs_month[doc.data().ref]){
                            refs_month[doc.data().ref] = Number(doc.data().pay2)
                        }else{
                            refs_month[doc.data().ref] = refs_month[doc.data().ref] + Number(doc.data().pay2)
                        }
                    }
                    if(date > new Date(moment().startOf('week'))){
                        if (!refs_week[doc.data().ref]){
                            refs_week[doc.data().ref] = Number(doc.data().pay2)
                        }else{
                            refs_week[doc.data().ref] = refs_week[doc.data().ref] + Number(doc.data().pay2)
                        }
                    }
                }
                //pay stat
                findBestUser[doc.data().userid] = findBestUser[doc.data().userid] ? findBestUser[doc.data().userid] + Number(doc.data().pay2) : Number(doc.data().pay2)
                if (series.findIndex(x => x.x === time) !== -1){
                    series[series.findIndex(x => x.x === time)].y = (Number(series[series.findIndex(x => x.x === time)].y) + Number(doc.data().pay2)).toFixed(2)
                }else{
                    series.push({
                        x: time,
                        y: doc.data().pay2
                    });
                }
                if(date > startMonth){
                    if (series_month.findIndex(x => x.x === time) !== -1){
                        series_month[series_month.findIndex(x => x.x === time)].y = (Number(series_month[series_month.findIndex(x => x.x === time)].y) + Number(doc.data().pay2)).toFixed(2)
                    }else{
                        series_month.push({
                            x: time,
                            y: doc.data().pay2
                        });
                    }
                }
                if(date > new Date(moment().startOf('week'))){
                    if (series_week.findIndex(x => x.x === time) !== -1){
                        series_week[series_week.findIndex(x => x.x === time)].y = (Number(series_week[series_week.findIndex(x => x.x === time)].y) + Number(doc.data().pay2)).toFixed(2)
                    }else{
                        series_week.push({
                            x: time,
                            y: doc.data().pay2
                        });
                    }
                }
            })
            if(series.findIndex(x => x.x === monthName[new Date().getMonth()]  + " " + new Date().getDate()) === -1){
                series.push({
                    x: monthName[new Date().getMonth()]  + " " + new Date().getDate(),
                    y:0
                })
                series_week.push({
                    x: monthName[new Date().getMonth()]  + " " + new Date().getDate(),
                    y:0
                })
                series_month.push({
                    x: monthName[new Date().getMonth()]  + " " + new Date().getDate(),
                    y:0
                })
            }
            if(findBestUser){
                let best = Object.keys(findBestUser).reduce((a, b) => findBestUser[a] > findBestUser[b] ? a : b, 0)
                if(best){
                    firebase.firestore().collection("users").doc(best).get().then(doc=>{
                        if(doc.data()){
                            let arr = {
                                nickname: doc.data().meta.nickname,
                                cost: findBestUser[doc.id]
                            }
                            dispatch(BEST_USER(arr))
                        }
                    })
                }
            }
            dispatch(LAST_PAYMENTS_STATS({
                list: series,
                lists_week: series_week,
                lists_month: series_month,
                refs: refs,
                refs_week: refs_week,
                refs_month: refs_month,
                pay_type: pay_type,
                packet_type: packet_type,
                topUser:findBestUser
            }))
      })
    }
}
const totalPayment = (type) => {
    return (dispatch, getState) => {
    firebase.firestore().collection("statistics").doc("totalPayment").onSnapshot(doc=>{
        dispatch(TOTAL_PAYMENTS_STATS(doc.data().value))
    })
    }
}
const totalReload = (type) => {
    return (dispatch, getState) => {
        let userData = getState().auth.userData
        let _fire = firebase.firestore().collection("payments").where("function", "==", "ok")

        if(userData.role === 2){
            _fire = _fire.where("ref", "==", userData.ref)
        }
        _fire.get().then(doc=>{
            let pay = 0
            doc.forEach(doc=>{
                pay += doc.data().pay2
            })
            firebase.firestore().collection("statistics").doc("totalPayment").update({
                value:pay
            })
        })
    }
}
  
const paymentSuccess = (id) => {
    return (dispatch, getState) => {
        firebase.firestore().collection("payments").doc(id).update({
            function:"ok",
            s:true
        })
    }
}
  



const paymentsData = (first, head, total) => {
    return async (dispatch, getState) => {

        let form = getState().form
        let payments = getState().payments
        let packets = getState().general.packets ? getState().general.packets : []
        let affiliates = getState().general.affiliates ? getState().general.affiliates : {}
        let adminData = getState().auth.userData
        dispatch(START_PAYMENT())
        let fireData = firebase.firestore().collection("payments")

        if(adminData.role === 2){
            fireData = fireData.where("ref", "==", adminData.ref)
        }
        if(form.search_id){
            fireData = fireData.where("id", "==", form.search_id)
        }else{
            if (form.search_nickname) {
                fireData = fireData.where("nickname", "==", form.search_nickname.toLowerCase());
            }else if (form.search_user_id) {
                fireData = fireData.where("userid", "==", form.search_user_id);
            }else{
                if(form.searchStartDate){
                    fireData = fireData.where("time", ">=", new Date(form.searchStartDate))
                }
                if(form.searchEndDate){
                    fireData = fireData.where("time", "<=", new Date(form.searchEndDate))
                }
                if(form.search_paytype){
                    fireData = fireData.where("paytype", "==", form.search_paytype)
                }
                if(form.search_packet){
                    fireData = fireData.where("packet", "==", Number(form.search_packet))
                }
                if(form.search_function){
                    fireData = fireData.where("function", "==", form.search_function)
                }
            }
        }
        head.forEach(doc=>{
            if(doc.orderBy && doc.orderBy !== "disable"){
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            } 
        })

        if(payments.lastData && payments.lastData[(payments.activePage - 1)] && first === ""){
            fireData = fireData.startAfter(payments.lastData[(payments.activePage - 1)])
        }
        if(!total){
            fireData = fireData.limit(10) 
        }
        
        if(unsubscribe2){
            unsubscribe2()
        }
        unsubscribe2 = fireData.onSnapshot(async doc => {
            let userList = []
            if(!doc.size){
                dispatch(PAYMENTS_DATA({
                    list: userList
                }))
                dispatch(PAYMENTS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            // if(doc && _lastData){
            //     if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
            //         lastData.push(_lastData)
            //         lastDataSet(lastData)
            //     }else if(!lastData){
            //         lastDataSet([_lastData])
            //     }
            // }

            if(doc && _lastData){
                if(payments.lastData && (payments.lastData.findIndex(x => x.id === _lastData.id) === -1)){
                    payments.lastData.push(_lastData)
                }else if(!payments.lastData){
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            // if(doc && _lastData){
            //     if(activePage >= lastData.length){
            //         if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
            //             lastData.push(_lastData)
            //             lastDataSet(lastData)
            //         }else if(!lastData){
            //             lastDataSet([_lastData])
            //         }
            //     }else{
            //         if(lastData){
            //             lastData.pop()
            //             lastData.push(_lastData)
            //             lastDataSet(lastData)
            //         }else{
            //             lastDataSet([_lastData])
            //         }
            //     }
            // }
            doc.forEach(doc=>{
                let packetName = ""
                packets.forEach(doc2=>{
                    if(doc2.id === doc.data().packet){
                        packetName = doc2.title
                    }
                })
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "bold", value: doc.data().nickname, sub: doc.data().userid },
                    { type: "text", value: doc.id},
                    { type: "text", value: doc.data().paytype },
                    { type: "text", value: doc.data().pay2 },
                    { type: "text", value: packetName },
                    { type: "badge", value: doc.data().function, class: doc.data().function === "ok" ? "success" : doc.data().function === "new" ? "primary" : "danger" },
                    { type: "text", value: affiliates[doc.data().ref] ? affiliates[doc.data().ref] : doc.data().ref },
                    { type: "date", value:  new Date(doc.data().time.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(PAYMENTS_DATA({
                list: userList
            }))
            if(total){
                dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            }
            dispatch(PAYMENTS_END())
        })
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(PAYMENTS_CHANGE({
            [name]: value
        }))
    }
}


export { changeData, paymentsData, lastPayments, totalPayment, totalReload, paymentSuccess};
