import { useIntl } from 'react-intl';
const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
const getParams = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
}
const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
const getParameterByName = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);

  if (results == null)
    return "";
  else
    return decodeURIComponent(results[1].replace(/\+/g, " "));
}
// const openModal = setState => {
//     // setState(prevState => ({ ...prevState, modal_1: "" }))

//     const onChangeHandler = event => {
//       setState(prevState => ({...prevState, modal_1: "true"}));
//     };
//     return onChangeHandler;
// };
const useChangeHandler = setState => {
  const onChangeHandler = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setState(prevState => ({ ...prevState, [`${name}`]: value }));
  };

  return onChangeHandler;
};

const useFormatMessage = (
  id,
  values = {},
  defaultMessage = '',
  description = ''
) => {
  const intl = useIntl();
  return intl.formatMessage({ id, defaultMessage, description }, values);
};

const useFormatDate = (value, options = {}) => {
  const intl = useIntl();
  return intl.formatDate(value, options);
};

export { getParams, uuidv4, useChangeHandler, useFormatMessage, useFormatDate, getParameterByName, getAge, makeid };
