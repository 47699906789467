import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import firebase from '../../firebase.js';
import moment from "moment"
const Index = () => {

  const {userData} = useSelector(
    (state) => ({
      userData: state.auth.userData,
    }), shallowEqual
  );

  const [size, sizeSet] = useState(0)
  useEffect(() => {
    let fire = firebase.firestore().collection("users").where("role", "==", 0).where("timestamp", ">=", new Date(moment().startOf('day')))
    if(userData.role === 2){
      fire = fire.where("ref", "==", userData.ref)
    }
    fire.onSnapshot(doc=>{
        sizeSet(doc.size)
    })
  }, []);
  return (
    <div id="newUsers" className={classNames(styles.newUsers, styles.box)}>
        
        <div className={styles.iconHeader}>

            <div className={styles.icon}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 460.8 460.8">
                        <path fill="" d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641
                            S296.261,0,230.432,0z"/>
                        <path fill="" d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784
                            c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555
                            c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943
                            c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167
                            c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02
                            c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898
                            C436.8,341.682,437.322,338.024,435.755,334.89z"/>
                </svg>
            </div>
            <p>New Users</p>
            <b>{size}</b>
        </div>
    </div>
  );
};

export default Index;
