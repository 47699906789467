import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';

export const AFFIRMATIONS_START = createAction('AFFIRMATIONS_START');
export const AFFIRMATIONS_END = createAction('AFFIRMATIONS_END');
export const AFFIRMATIONS_DATA = createAction('AFFIRMATIONS_DATA');
export const AFFIRMATIONS_CHANGE = createAction('AFFIRMATIONS_CHANGE');

// let unsubscribe2 = ""


const deleteAffirmations = (id) => {
    firebase.firestore().collection("affirmations").doc(id).delete()
}

const verifyAffirmations = async (userData, id) => {
    if (userData.avatarUrl) {

        await firebase.firestore().collection("users").doc(userData.userId).update({
            "meta.avatarUrl": userData.avatarUrl,
            "meta.avatarThumb": userData.avatarThumb,
            "meta.avatarLarge": userData.avatarLarge,
            "meta.ab": userData.ab,
        })
        firebase.firestore().collection("users").doc(userData.userId).get().then(doc => {
            if (!doc.data()) {
                return null
            }
            if (doc.data().prize.profilePhoto === 0) {
                firebase.firestore().collection("settings").doc("prize").get().then(prize => {
                    if (Number(prize.data().profilePhotoCoin)) {
                        firebase.firestore().collection('users').doc(doc.data().docId).update({
                            "prize.profilePhoto": 1,
                            coin: Number(doc.data().coin) + Number(prize.data().profilePhotoCoin)
                        }).then(doc2 => {
                            //history
                            firebase.firestore().collection("history").add({
                                t: "p_profile_photo",
                                a: doc.data().docId,
                                c: prize.data().profilePhotoCoin,
                                u: Number(doc.data().coin) + Number(prize.data().profilePhotoCoin),
                                time: firebase_app.firestore.FieldValue.serverTimestamp()
                            })
                        }).catch(doc => { console.log(doc.message) })
                    }
                })
            }
        })
        firebase.firestore().collection("notifications").add({
            a: userData.userId,
            t: "p_v",
            time: new Date(),
            v: false
        })
    } else if (userData.photo) {
        await firebase.firestore().collection("users").doc(userData.userId).get().then(doc => {
            let arr = doc.data().photos ? doc.data().photos : []
            arr.push(userData.photo)
            let arr_blur = doc.data().pBlur ? doc.data().pBlur : []
            arr_blur.push(userData.blur)

            firebase.firestore().collection('users').doc(userData.userId).update({
                photos: arr,
                pBlur: arr_blur
            })
        })
        firebase.firestore().collection("notifications").add({
            a: userData.userId,
            t: "p_v",
            time: new Date(),
            v: false
        })
    } else if (userData.info) {
        await firebase.firestore().collection('users').doc(userData.userId).update({
            info: userData.info
        })
    }

    firebase.firestore().collection("affirmations").doc(id).delete()
    // firebase.firestore().collection("affirmations").doc(id).delete()
}


const Data = (first, head, ref) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let users = getState().users
        dispatch(AFFIRMATIONS_START())
        let fireData = firebase.firestore().collection("affirmations")
        if (form.search_nickname) {
            fireData = fireData.where("nickname", "==", form.search_nickname.toLowerCase());
        } else if (form.search_id) {
            fireData = fireData.where("userId", "==", form.search_id.toLowerCase());
        } else {
            if (form.searchStartDate) {
                fireData = fireData.where("time", ">=", new Date(form.searchStartDate))
            }
            if (form.searchEndDate) {
                fireData = fireData.where("time", "<=", new Date(form.searchEndDate))
            }
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (users.lastData && users.lastData[(users.activePage - 1)] && first === "") {
            fireData = fireData.startAfter(users.lastData[(users.activePage - 1)])
        }
        fireData.onSnapshot(doc => {
            let userList = []
            if (!doc.size) {
                dispatch(AFFIRMATIONS_DATA({
                    list: userList
                }))
                dispatch(AFFIRMATIONS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (users.lastData && (users.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    users.lastData.push(_lastData)
                } else if (!users.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["docData"] = doc.data()
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().avatarThumb ? doc.data().avatarThumb : doc.data().photo },
                    { type: "bold", value: doc.data().nickname, sub: doc.data().userId },
                    { type: "textAlt", value: doc.data().info ? doc.data().info : "" },
                    { type: "date", value: new Date(doc.data().time.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(AFFIRMATIONS_DATA({
                list: userList
            }))
            dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            dispatch(AFFIRMATIONS_END())
        })
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(AFFIRMATIONS_CHANGE({
            [name]: value
        }))
    }
}

export { changeData, Data, deleteAffirmations, verifyAffirmations };
