import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import {getMessages, getMessagesList } from "../../state/actions/callcenters"
import Modal from "../../components/Items/modal"
import {ButtonPrimaryLigth, ButtonPrimary } from "../../components/Items/buttons"
import MessageBox from "../../components/Layout/MessageBox/MessageBox_2"
import {defaultLAvatar} from "../../Settings"
import classNames from "classnames"

const MessagesList = ({id}) => {
    const { messagesList, users, messages} = useSelector(
        (state) => ({
            users: state.usersList,
            messagesList: state.callcenters.messagesList ? state.callcenters.messagesList : [],
            messages: state.callcenters.messages ? state.callcenters.messages : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [modal_2, modal_2Status] = useState("")
    const [toggle, toggleSet] = useState("Messages")
    const openMessage = (element, fakeid, userid) => {
        dispatch(getMessages(element))
        modal_2Status({fake:fakeid, user:userid})
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getMessagesList(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (<div>
        <div className={classNames(styles.tabContent)}>
            <div className={classNames(styles.title, styles.p)}>
                <h3>Last Messages</h3>
                <div className={styles.buttons}>
                {toggle === "Messages" ? <ButtonPrimary style={{height:30, width:70, fontSize:12}} styletype="type_1" text={"Messages"} /> :
                <ButtonPrimaryLigth style={{height:30, width:70, fontSize:12}} styletype="type_1" text={"Messages"} />}
                </div>
            </div>
            {toggle === "Messages" ? 
            <ul className={styles.lastMessagesList}>
                {messagesList.map((doc, index) =>{
                    return <li key={index} className={doc.send === "user" ? styles.left : styles.right}>
                    <div className={styles.avatar}>
                        <img src={users[doc.user] && users[doc.user].avatar ? users[doc.user].avatar === "noavatar.jpg" ? defaultLAvatar :  users[doc.user].avatar : defaultLAvatar} alt="" />
                    </div>
                    <div className={styles.messageContent}>
                    <b><span>{users[doc.user] && users[doc.user].nickname}</span> <ButtonPrimaryLigth onClick={(e) =>  openMessage(doc.id, doc.fake, doc.user)} style={{height:30, width:70, fontSize:12, marginLeft:"auto"}} styletype="type_1" text={"Detail"} /> <span>{users[doc.fake] && users[doc.fake].nickname}</span></b>
                        <div className={styles.message}>
                            {doc.message}
                            <small>{doc.time.toLocaleString()}</small>
                        </div>
                    </div>
                    <div className={styles.avatar}>
                        <img src={users[doc.fake] && users[doc.fake].avatar ? users[doc.fake].avatar === "noavatar.jpg" ? defaultLAvatar :  users[doc.fake].avatar : defaultLAvatar} alt="" />
                    </div>
                    
                </li>
                })}
            </ul> : ""}
            
        </div>
        {modal_2 && modal_2.user ? <Modal padding={0} content={<MessageBox userId={modal_2.fake} messageList={messages} fakeId={modal_2.user} />} status={modal_2} width={500} close={(e) => modal_2Status("")} /> : ""}
    </div>
    )
}

export default MessagesList;
