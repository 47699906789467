import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import Input from "../../components/Items/input"
import Toggle from "../../components/Items/toggle"
import {addCC} from "../../state/actions/callcenters"
import { ButtonPrimary, ButtonPrimaryLigth } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import {onChange} from "../../state/actions/form"
import {FormattedMessage} from 'react-intl';
import Modal from "../../components/Items/modal"


const AddCallcenters = () => {
    const dispatch = useDispatch();
    const { loading} = useSelector(
        (state) => ({
            loading: state.fakes.loading
        }), shallowEqual
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("formCadmin", ""))
        dispatch(onChange("formNickname", ""))
        dispatch(onChange("formEmail", ""))
        dispatch(onChange("formPassword", ""))
        dispatch(onChange("formDisable", ""))
        dispatch(onChange("formCadmin", ""))

    }, [dispatch]);

    const [tab, tabSet] = useState(1)
    const [modal_1, modal_1Status] = useState("")

    return (
        <section className={classNames(styles.Pages, styles.edit)}>

            <div className={styles.headerBottom}>
                <div className={styles.left}>
                    <NavLink to="/callcenters">
                        Callcenters
                    </NavLink>
                    <img className={styles.arrow} src={Arrow} alt="" />
                    <span>
                        Add
                    </span>
                </div>
                <div className={styles.right}>
                    <NavLink to={"/callcenters"}>
                        Back
                    </NavLink>
                    <ButtonPrimary onClick={(e) => dispatch(addCC())} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13, marginLeft:15}} text="Add" />
                </div>
            </div>
            <div className={styles.row}>
                <div className={classNames(styles.container, styles.full)}>
                    <div className={styles.contentWhite}>
                        <div className={classNames(styles.tab, styles.p)}>
                            {tab === 1 ? <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Account Information" /> : 
                            <ButtonPrimaryLigth onClick={(e) => tabSet(1)} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Account Information" />}
                           
                        </div>
                        {tab === 1 ? <div className={classNames(styles.px)} style={{marginBottom:25}}>
                            <div className={styles.row}>
                                <div className={classNames(styles.container, styles.wHalf)}>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Disabled
                                        </span>
                                        <Toggle styletype="type_4" label={<FormattedMessage id="Register.cinsiyet" />} name="formDisable" disabled />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Nickname
                                        </span>
                                        <Input styletype="type_4" name="formNickname" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Cadmin
                                        </span>
                                        <Input styletype="type_4" name="formCadmin" type="text" />
                                    </div>
                                </div>
                                <div className={classNames(styles.container, styles.wHalf)}>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Email
                                        </span>
                                        <Input styletype="type_4" name="formEmail" type="text" />
                                    </div>
                                    <div className={styles.submit}>
                                        <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                            Password
                                        </span>
                                        <Input styletype="type_4" name="formPassword" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div> : ""}
                        {loading ?<div className="loading"></div> : ""}
                        {modal_1 ? <Modal title="PHOTO" content={<img src={modal_1} alt="" />} status={modal_1} width={500} close={(e) => modal_1Status("")} /> : ""}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddCallcenters;
