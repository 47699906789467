import { createReducer } from 'redux-act';

import {
    SETTINGS_START,
    SETTINGS_END,
    SETTINGS_DATA,
    PACKET_CHANGE
} from '../actions/settings';

const initialState = {
  loading: false
};

const authReducer = createReducer(
  {
    [SETTINGS_START]: (state, payload) => ({
      ...state,
      loading: true
    }),
    [SETTINGS_END]: (state, payload) => ({
      ...state,
      loading: false
    }),
    [SETTINGS_DATA]: (state, payload) => ({
      ...state,
      ...payload
    }),
    [PACKET_CHANGE]: (state, payload) => ({
      
      ...state, 
      packets: state.packets.map(
          (content, i) => i === payload.index ? {...content, [payload.name]: payload.value}
                                  : content
      )
    }),
  },
  initialState
);

export default authReducer