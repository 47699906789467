import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import Input from "../../components/Items/input"
import Toggle from "../../components/Items/toggle"
import { ButtonPrimary, ButtonPrimaryLigth} from "../../components/Items/buttons"
import {onChange} from "../../state/actions/form"
import {FormattedMessage} from 'react-intl';

const EditDetail = () => {
    const { loading, userData} = useSelector(
        (state) => ({
            loading: state.callcenters.loading,
            userData: state.callcenters.detail,
        }), shallowEqual
    );

    useEffect(() => {
    window.scrollTo(0, 0);
        if (userData){
            dispatch(onChange("formNickname", userData.nickname));
            dispatch(onChange("formCadmin", userData.cadmin));
            dispatch(onChange("formEmail", userData.email));
            dispatch(onChange("formPassword", userData.password));
            dispatch(onChange("formDisable", userData.disable));
        }
        // eslint-disable-next-line
    }, [userData]);


    const dispatch = useDispatch();
    const [tab, tabSet] = useState(1)
    if(!userData){
        return null
    }

    return (
        <div className={styles.contentWhite}>
            <div className={styles.top}>
                <div className={styles.p}>
                    <div className={styles.right}>
                        <div className={styles.nickname}>
                            <b>{userData.nickname}</b>
                            <small>ID: {userData.docId}</small>
                        </div>
                        <div className={styles.bottom}>
                            <div>
                                <small>EMAIL</small>
                                <b>{userData.email}</b>
                            </div>
                            <div>
                                <small>CCADMIN</small>
                                <b>{userData.cadmin ? userData.cadmin : "-"}</b>
                            </div>
                            <div>
                                <small>PASSWORD</small>
                                <b>{userData.password}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.stats}>
                    <div className={styles.header}>
                       <div className={styles.item}>
                            <b>{userData.total_post}</b>
                            <p>TOTAL POST</p>
                       </div> 
                       <div className={styles.item}>
                            <b>{userData.total_get}</b>
                            <p>TOTAL GET</p>
                       </div> 
                       <div className={styles.item}>
                            <b>{userData.total_automessage}</b>
                            <p>TOTAL AUTOMESSAGE</p>
                       </div> 
                    </div>
                    <div className={styles.header}>
                       <div className={styles.item}>
                            <b>{userData.total_asa1}</b>
                            <p>ASA 1</p>
                       </div> 
                       <div className={styles.item}>
                            <b>{userData.total_asa2}</b>
                            <p>ASA 2</p>
                       </div> 
                       <div className={styles.item}>
                            <b>{userData.total_asa3}</b>
                            <p>ASA 3</p>
                       </div> 
                    </div>
                </div>
            </div>
            <hr />
            <div className={classNames(styles.tab, styles.p)}>
                {tab === 1 ? <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Account Information" /> : 
                <ButtonPrimaryLigth onClick={(e) => tabSet(1)} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text="Account Information" />}
            </div>
            {tab === 1 ? <div className={classNames(styles.px)} style={{marginBottom:25}}>
                <div className={styles.row}>
                    <div className={classNames(styles.container, styles.wHalf)}>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Disabled
                            </span>
                            <Toggle styletype="type_4" label={<FormattedMessage id="Register.cinsiyet" />} name="formDisable" />
                        </div>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Nickname
                            </span>
                            <Input styletype="type_4" name="formNickname" type="text" />
                        </div>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Cadmin
                            </span>
                            <Input styletype="type_4" name="formCadmin" type="text" />
                        </div>
                    </div>
                    <div className={classNames(styles.container, styles.wHalf)}>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Email
                            </span>
                            <Input styletype="type_4" name="formEmail" type="text" />
                        </div>
                        <div className={styles.submit}>
                            <span style={{width:"28%", flex:"none", paddingRight:15}}>
                                Password
                            </span>
                            <Input styletype="type_4" name="formPassword" type="text" />
                        </div>
                    </div>
                </div>
            </div> : ""}
            {tab === 2 ? <div className={classNames(styles.tabContent, styles.px)} style={{marginBottom:25}}>
               
            </div> : ""}
            {tab === 3 ? <div className={classNames(styles.px)} style={{marginBottom:25}}>

            </div> : ""}
            {loading ?<div className="loading"></div> : ""}
        </div>
    )
}

export default EditDetail;
