import { createReducer } from 'redux-act';

import {
    AUTO_MESSAGES_START,
    AUTO_MESSAGES_END,
    AUTO_MESSAGES_DATA,
    AUTO_MESSAGES_SEARCH_FAKE,
    AUTO_MESSAGES_CHANGE
} from '../actions/auto_messages';

const initialState = {
  loading: false
};

const authReducer = createReducer(
    {
        [AUTO_MESSAGES_START]: (state, payload) => ({
        ...state,
        loading: true
        }),
        [AUTO_MESSAGES_DATA]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
        [AUTO_MESSAGES_SEARCH_FAKE]: (state, payload) => {
            return ({
                ...state,
                searchList: payload.list,
            })
        },
        [AUTO_MESSAGES_END]: (state, payload) => ({
        ...state,
        loading: false
        }),
        [AUTO_MESSAGES_CHANGE]: (state, payload) => {
            return ({
                ...state,
                ...payload
            })
        },
    },
    initialState
);

export default authReducer