
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';


export const CONTACTS_START = createAction('CONTACTS_START');
export const CONTACTS_END = createAction('CONTACTS_END');
export const CONTACTS_DATA = createAction('CONTACTS_DATA');


export const contactsData = (first, lastData, lastDataSet, perPage, head, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form
        dispatch(CONTACTS_START())
        let fireData = firebase.firestore().collection("contact")
        if(form.searchStartDate){
            fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
        }
        if(form.searchEndDate){
            fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
        }
        if (form.search_userId) {
            fireData = fireData.where("userId", "==", form.search_userId);
        }
        if (form.search_email) {
            fireData = fireData.where("email", "==", form.search_email);
        }
        if (form.search_username) {
            fireData = fireData.where("username", "==", form.search_username);
        }
        head.forEach(doc=>{
            if(doc.orderBy && doc.orderBy !== "disable"){
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            } 
        })
        if(lastData && lastData[(activePage - 1)] && first === ""){
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        fireData.limit(perPage).get().then(async doc => {
            let userList = []
            if(!doc.size){
                dispatch(CONTACTS_DATA({
                    list: userList
                }))
                dispatch(CONTACTS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if(doc && _lastData){
                if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                }else if(!lastData){
                    lastDataSet([_lastData])
                }
            }
            doc.forEach(doc=>{
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "text", value: doc.data().username, sub:doc.data().userUd },
                    { type: "text", value: doc.data().email },
                    { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
                    { type: "date", value:  new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(CONTACTS_DATA({
                list: userList
            }))
            dispatch(CONTACTS_END())
        })
    }
}