import { createReducer } from 'redux-act';

import {
    USERS_START,
    USERS_END,
    USERS_SEARCH_USER,
    USER_DETAIL,
    LAST_MESSAGES,
    LAST_MESSAGES2,
    USERS_MESSAGES_LIST,
    USERS_MESSAGES,
    USER_HISTORY,
    USER_PAYMENT_DATA,
    USERS_CHANGE
} from '../actions/users';

const initialState = {};

const newsletters = createReducer(
    {
        [USERS_CHANGE]: (state, payload) => {
            return ({
                ...state,
                ...payload
            })
        },
        [USERS_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [USERS_END]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                error: ""
            })
        },
        [USER_DETAIL]: (state, payload) => {
            return ({
                ...state,
                detail:payload
            })
        },
        [USERS_SEARCH_USER]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
        [LAST_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                lastMessages: payload,
            })
        },
        [LAST_MESSAGES2]: (state, payload) => {
            return ({
                ...state,
                lastMessages2: payload,
            })
        },
        [USERS_MESSAGES_LIST]: (state, payload) => {
            return ({
                ...state,
                messagesList: payload,
            })
        },
        [USERS_MESSAGES]: (state, payload) => {
            return ({
                ...state,
                messages: payload,
            })
        },
        [USER_PAYMENT_DATA]: (state, payload) => {
            return ({
                ...state,
                payments: payload,
            })
        },
        [USER_HISTORY]: (state, payload) => {
            return ({
                ...state,
                historyList: payload,
            })
        },
    },
    initialState
);
export default newsletters