import { createReducer } from 'redux-act';

import {
    NEWSLETTERS_START,
    NEWSLETTERS_END,
    NEWSLETTERS_DATA,
    NEWSLETTERS_WAITING_DATA,
    NEWSLETTERS_SEARCH_FAKE,
    NEWSLETTERS_UNCHECK,
    NEWSLETTERS_CHECK,
    NEWSLETTERS_WAITING_RESET,
    NEWSLETTERS_CHANGE,
    NEWSLETTERS_SEARCH_USER
} from '../actions/newsletters';

const initialState = {};

export const newsletters = createReducer(
    {
        [NEWSLETTERS_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [NEWSLETTERS_END]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                error: ""
            })
        },
        [NEWSLETTERS_DATA]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        },
        [NEWSLETTERS_WAITING_DATA]: (state, payload) => {
            return ({
                ...state,
                waiting: payload.list,
            })
        },
        [NEWSLETTERS_SEARCH_FAKE]: (state, payload) => {
            return ({
                ...state,
                searchList: payload.list,
            })
        },
        [NEWSLETTERS_SEARCH_USER]: (state, payload) => {
            return ({
                ...state,
                searchListUsers: payload.list,
            })
        },
        [NEWSLETTERS_WAITING_RESET]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                unCheckUsers: [],
            })
        },
        [NEWSLETTERS_UNCHECK]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                unCheckUsers: state.unCheckUsers.filter(item => item.docId !== payload),
            })
        },
        [NEWSLETTERS_CHANGE]: (state, payload) => {
            return ({
                ...state,
                ...payload
            })
        },
        [NEWSLETTERS_CHECK]: (state, payload) => {
            if(state.unCheckUsers){
                return ({
                    ...state,
                    loading: false,
                    unCheckUsers: [...state.unCheckUsers, payload],
                }) 
            }else{
                return ({
                    ...state,
                    loading: false,
                    unCheckUsers: [payload],
                }) 
            }
        },
    },
    initialState
);
