import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';
import { onChange } from "./form"
import { makeid } from "../../Hooks"
import { defaultNewsletterOptions } from "../../Settings"
import ResizeImage from 'image-resize'

export const NEWSLETTERS_START = createAction('NEWSLETTERS_START');
export const NEWSLETTERS_END = createAction('NEWSLETTERS_END');
export const NEWSLETTERS_DATA = createAction('NEWSLETTERS_DATA');
export const NEWSLETTERS_WAITING_DATA = createAction('NEWSLETTERS_WAITING_DATA');
export const NEWSLETTERS_SEARCH_FAKE = createAction('NEWSLETTERS_SEARCH_FAKE');
export const NEWSLETTERS_SEARCH_USER = createAction('NEWSLETTERS_SEARCH_USER');
export const NEWSLETTERS_WAITING_RESET = createAction('NEWSLETTERS_WAITING_RESET');
export const NEWSLETTERS_CHECK = createAction('NEWSLETTERS_CHECK');
export const NEWSLETTERS_UNCHECK = createAction('NEWSLETTERS_UNCHECK');
export const NEWSLETTERS_CHANGE = createAction('NEWSLETTERS_CHANGE');

let unsubscribe = ""
let unsubscribe2 = ""
const resize = new ResizeImage({
    format: 'jpg',
    width: 700,
    outputType: "blob"
});

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}
export const photoDelete = (e) => {
    return async (dispatch, getState) => {
        dispatch(onChange("newsletter_image", ""))
    }
}
export const saveData = (fakeId, history) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let unCheckUsers = getState().newsletters.unCheckUsers ? getState().newsletters.unCheckUsers : []
        dispatch(NEWSLETTERS_START())
        firebase.firestore().collection("users").doc(fakeId).get().then(fakeData => {
            firebase.firestore().collection('newsletter').add({
                timestamp: firebase_app.firestore.FieldValue.serverTimestamp(),
                fakeId: fakeId,
                fakeNickneme: fakeData.data().meta.nickname,
                fakeImage: fakeData.data().meta.avatarThumb,
                message: form.newsletter_message,
                photo: form.newsletter_image ? form.newsletter_image : "",
                status: "wait"
            }).then(async doc => {
                let total = 0;


                let fireData = firebase.firestore().collection("users").where("role", "==", 0).where("verify", "==", true)
                if (form.ssId) {
                    fireData = fireData.where("docId", "==", form.ssId)
                } else {
                    if (form.edit_search_nickname_newsletter) {
                        fireData = fireData.where("meta.nickname_search", "==", form.edit_search_nickname_newsletter.toLowerCase());
                    }
                    if (form.ssStartDate) {
                        fireData = fireData.where("timestamp", ">=", new Date(form.ssStartDate))
                    }
                    if (form.ssEndDate) {
                        fireData = fireData.where("timestamp", "<=", new Date(form.ssEndDate))
                    }
                    if (form.edit_search_online) {
                        fireData = fireData.where("online", "==", true)
                    }
                    if (form.edit_search_range2 || form.edit_search_range2 === 0) {
                        fireData = fireData.where("range", "==", Number(form.edit_search_range2))
                    }
                    if (!form.ssStartDate && !form.ssStartDate) {
                        if (form.edit_search_avatar) {
                            fireData = fireData.where("meta.avatarUrl", "!=", "noavatar.jpg")
                        } else if (form.edit_filter_payment) {
                            fireData = fireData.where("packet", "!=", 0)
                        } else if (form.edit_filter_coin_up) {
                            fireData = fireData.where("coin", ">=", Number(form.edit_filter_coin_up))
                            if (form.edit_filter_coin_down) {
                                fireData = fireData.where("coin", ">=", Number(form.edit_filter_coin_down))
                            }
                        } else if (form.edit_filter_coin_down) {
                            fireData = fireData.where("coin", ">=", Number(form.edit_filter_coin_down))
                        }
                    }
                    if (form.edit_filter_userId) {
                        fireData = fireData.where("docId", "==", form.edit_filter_userId)
                    }
                    if (form.edit_filter_ref) {
                        fireData = fireData.where("ref", "==", form.edit_filter_ref)
                    }
                    if (form.edit_filter_country) {
                        fireData = fireData.where("meta.country", "==", form.edit_filter_country)
                    }
                    if (form.edit_filter_city) {
                        fireData = fireData.where("meta.city", "==", form.edit_filter_city)
                    }
                    if (form.edit_search_gender2 || form.edit_search_gender2 === 0) {
                        fireData = fireData.where("meta.gender", "==", Number(form.edit_search_gender2))
                    }
                }
                fireData.get().then(async doc2 => {
                    console.log(doc2.size);
                    doc2.forEach((doc3, index) => {
                        if ((unCheckUsers.findIndex(x => x.docId === doc3.id) === -1)) {
                            let message = form.newsletter_message
                            let _birtDate = doc3.data().meta.birtDate.toDate()
                            message = message.split("%username%").join(doc3.data().meta.nickname)
                            message = message.split("%region%").join(doc3.data().meta.city)
                            message = message.split("%alt%").join(getAge(_birtDate))
                            firebase.firestore().collection('newsletterWaiting').add({
                                i: doc.id,
                                u: doc3.id,
                                f: fakeId,
                                d: [
                                    {
                                        a: doc3.data().meta.avatarThumb,
                                        n: doc3.data().meta.nickname,
                                    },
                                    {
                                        a: fakeData.data().meta.avatarThumb,
                                        n: fakeData.data().meta.nickname,
                                    }
                                ],
                                t: form.newsletter_type === true ? "1" : "0",
                                time: 0,
                                m: message,
                                p: form.newsletter_image ? form.newsletter_image : ""
                            }).then(doc4 => {
                                total++
                                if ((unCheckUsers.length + total) === doc2.size) {
                                    // console.log("son")
                                    firebase.firestore().collection('newsletter').doc(doc.id).update({
                                        docId: doc.id,
                                        totalUser: total
                                    })
                                    dispatch(NEWSLETTERS_END())

                                    dispatch(onChange("nNickname", ""))
                                    dispatch(onChange("sType", ""))
                                    dispatch(onChange("nStartDate", ""))
                                    dispatch(onChange("nEndDate", ""))
                                    dispatch(onChange("sId", ""))
                                    dispatch(onChange("sNickname", ""))
                                    dispatch(onChange("sRange", ""))
                                    dispatch(onChange("sGender", ""))
                                    dispatch(onChange("sCountry", ""))
                                    dispatch(onChange("sCity", ""))
                                    dispatch(onChange("sStartDate", ""))
                                    dispatch(onChange("sEndDate", ""))
                                    dispatch(onChange("ssId", ""))
                                    dispatch(onChange("edit_search_online", ""))
                                    dispatch(onChange("edit_filter_country", ""))
                                    dispatch(onChange("edit_filter_city", ""))
                                    dispatch(onChange("edit_search_nickname_newsletter", ""))
                                    dispatch(onChange("edit_search_range2", ""))
                                    dispatch(onChange("edit_search_gender2", ""))
                                    dispatch(onChange("edit_search_avatar", ""))
                                    dispatch(onChange("edit_filter_payment", ""))
                                    dispatch(onChange("edit_filter_coin_up", ""))
                                    dispatch(onChange("edit_filter_coin_down", ""))
                                    dispatch(onChange("ssStartDate", ""))
                                    dispatch(onChange("ssEndDate", ""))

                                    setTimeout(doc => { history.push("/newsletters") }, 500)
                                }
                            })
                        } else {
                            if ((unCheckUsers.length + total) === doc2.size) {
                                // console.log("son")
                                firebase.firestore().collection('newsletter').doc(doc.id).update({
                                    docId: doc.id,
                                    totalUser: total
                                })
                                dispatch(NEWSLETTERS_END())


                                dispatch(onChange("nNickname", ""))
                                dispatch(onChange("sType", ""))
                                dispatch(onChange("nStartDate", ""))
                                dispatch(onChange("nEndDate", ""))
                                dispatch(onChange("sId", ""))
                                dispatch(onChange("sNickname", ""))
                                dispatch(onChange("sRange", ""))
                                dispatch(onChange("sGender", ""))
                                dispatch(onChange("sCountry", ""))
                                dispatch(onChange("sCity", ""))
                                dispatch(onChange("sStartDate", ""))
                                dispatch(onChange("sEndDate", ""))
                                dispatch(onChange("ssId", ""))
                                dispatch(onChange("edit_search_online", ""))
                                dispatch(onChange("edit_filter_country", ""))
                                dispatch(onChange("edit_filter_city", ""))
                                dispatch(onChange("edit_search_nickname_newsletter", ""))
                                dispatch(onChange("edit_search_range2", ""))
                                dispatch(onChange("edit_search_gender2", ""))
                                dispatch(onChange("edit_search_avatar", ""))
                                dispatch(onChange("edit_filter_payment", ""))
                                dispatch(onChange("edit_filter_coin_up", ""))
                                dispatch(onChange("edit_filter_coin_down", ""))
                                dispatch(onChange("ssStartDate", ""))
                                dispatch(onChange("ssEndDate", ""))

                                setTimeout(doc => { history.push("/newsletters") }, 500)
                            }
                        }
                    })

                })

            })
        })
    }
}
const getData = (first, lastData, lastDataSet, perPage, head, tekrar, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form;
        dispatch(NEWSLETTERS_START())
        let fireData = firebase.firestore().collection("newsletter")
        if (form.nNickname) {
            fireData = fireData.where("fakeNickneme", "==", form.nNickname)
        }
        if (form.nType) {
            fireData = fireData.where("setting", "==", form.nType)
        }
        if (form.sType) {
            fireData = fireData.where("status", "==", form.sType)
        }
        if (form.nStartDate) {
            fireData = fireData.where("timestamp", ">=", new Date(form.nStartDate))
        }
        if (form.nEndDate) {
            fireData = fireData.where("timestamp", "<=", new Date(form.nEndDate))
        }

        head && head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }


        if (unsubscribe) {
            unsubscribe()
        }
        let onlyFirst = true
        unsubscribe = fireData.limit(perPage).onSnapshot(async doc => {
            let list = [];
            if (!doc.size) {
                dispatch(NEWSLETTERS_DATA({
                    list: list
                }))
                dispatch(NEWSLETTERS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (!tekrar && doc && _lastData) {
                if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                } else if (!lastData) {
                    lastDataSet([_lastData])
                }
            } else {
                return null
            }
            doc.forEach(doc => {
                if (doc.data()) {
                    if (!list.some(item => item.docId === doc.data().docId)) {
                        let pushArr = []
                        pushArr["docId"] = doc.id
                        pushArr["data"] = [
                            { type: "avatar", value: doc.data().fakeImage },
                            { type: "bold", value: doc.data().fakeNickneme },
                            { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
                            { type: "text", value: defaultNewsletterOptions[doc.data().setting] ? defaultNewsletterOptions[doc.data().setting].name : 0 },
                            { type: "date", value: doc.data().timestamp ? new Date(doc.data().timestamp.toDate()).toLocaleString() : "" },
                            { type: "text", value: doc.data().totalUser },
                            { type: "text", value: doc.data().visible },
                            { type: "text", value: doc.data().paymentLength },
                            { type: "text", value: doc.data().payment ? doc.data().payment + "€" : "0" },
                            { type: "badge", value: doc.data().status, class: doc.data().status === "Ok" ? "success" : "danger" },
                        ]
                        list.push(pushArr);
                    }
                }
            })
            dispatch(NEWSLETTERS_DATA({
                list: list
            }))
            if (onlyFirst) {
                onlyFirst = false
                dispatch(NEWSLETTERS_END())
            }
        })
    }
}
const getWaitingNewsletter = (id) => {
    return async (dispatch, getState) => {
        let newsletters = getState().newsletters
        dispatch(NEWSLETTERS_START())
        if (unsubscribe2) {
            unsubscribe2()
        }
        let fireData = firebase.firestore().collection("newsletterWaiting")
        if (newsletters.lastData && newsletters.lastData[(newsletters.activePage - 1)]) {
            fireData = fireData.startAfter(newsletters.lastData[(newsletters.activePage - 1)])
        }
        let onlyFirst = true
        unsubscribe2 = fireData.where("i", "==", id).onSnapshot(async doc => {
            let list = [];
            if (!doc.size) {
                dispatch(NEWSLETTERS_WAITING_DATA({
                    list: list
                }))
                dispatch(NEWSLETTERS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (newsletters.lastData && (newsletters.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    newsletters.lastData.push(_lastData)
                } else if (!newsletters.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            // if(doc && _lastData){
            //     if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
            //         lastData.push(_lastData)
            //         lastDataSet(lastData)
            //     }else if(!lastData){
            //         lastDataSet([_lastData])
            //     }
            // }else {
            //     return null
            // }
            doc.forEach(doc => {
                if (doc.data()) {
                    if (!list.some(item => item.docId === doc.data().docId)) {
                        let pushArr = []
                        pushArr["docId"] = doc.id
                        pushArr["data"] = [
                            { type: "avatar", value: doc.data().d[0].a },
                            { type: "bold", value: doc.data().d[0].n },
                            { type: "badge", value: doc.data().s ? "Sending" : "Waiting", class: doc.data().s ? "success" : "danger" },
                        ]
                        list.push(pushArr);
                    }
                }
            })
            dispatch(NEWSLETTERS_WAITING_DATA({
                list: list
            }))
            dispatch(changeData("totalPage", Math.floor(list.length / 10) ? Math.floor(list.length / 10) : 1))
            if (onlyFirst) {
                onlyFirst = false
                dispatch(NEWSLETTERS_END())
            }
        })
    }
}

const search = (first, lastData, lastDataSet, perPage, head, tekrar, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form

        let fireData = firebase.firestore().collection("users").where("role", "==", 1)

        if (form.sId) {
            fireData = fireData.where("docId", "==", form.sId)
        } else {
            if (form.sNickname) {
                fireData = fireData.where("meta.nickname_search", "==", form.sNickname.toLowerCase())
            }
            if (form.sGender) {
                fireData = fireData.where("meta.gender", "==", Number(form.sGender))
            }
            if (form.sRange) {
                fireData = fireData.where("range", "==", Number(form.sRange))
            }
            if (form.sCountry) {
                fireData = fireData.where("meta.country", "==", form.sCountry.toLowerCase())
            }
            if (form.sCity) {
                fireData = fireData.where("meta.city", "==", form.sCity.toLowerCase())
            }
            if (form.sStartDate) {
                fireData = fireData.where("timestamp", ">=", new Date(form.sStartDate))
            }
            if (form.sEndDate) {
                fireData = fireData.where("timestamp", "<=", new Date(form.sEndDate))
            }
        }

        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        fireData.limit(perPage).get().then(doc => {
            let userList = []
            if (!doc.size) {
                dispatch(NEWSLETTERS_SEARCH_FAKE({
                    list: userList
                }))
                dispatch(NEWSLETTERS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (!tekrar && doc && _lastData) {
                if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                } else if (!lastData) {
                    lastDataSet([_lastData])
                }
            } else {
                return null
            }

            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip },
                    { type: "text", value: doc.data().newsletter ? "Yes" : "" },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(NEWSLETTERS_SEARCH_FAKE({
                list: userList
            }))
        })
    }
}
const searchUsers = (first, lastData, lastDataSet, perPage, head, tekrar, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form
        dispatch(NEWSLETTERS_START())
        if (first) {
            dispatch(NEWSLETTERS_WAITING_RESET())
        }
        let fireData = firebase.firestore().collection("users").where("role", "==", 0).where("verify", "==", true)
        if (form.ssId) {
            fireData = fireData.where("docId", "==", form.ssId)
        } else {
            if (form.edit_search_nickname_newsletter) {
                fireData = fireData.where("meta.nickname_search", "==", form.edit_search_nickname_newsletter.toLowerCase());
            } else {
                if (form.ssStartDate) {
                    fireData = fireData.where("timestamp", ">=", new Date(form.ssStartDate))
                }
                if (form.ssEndDate) {
                    fireData = fireData.where("timestamp", "<=", new Date(form.ssEndDate))
                }
                if (form.edit_search_online) {
                    fireData = fireData.where("online", "==", true)
                }
                if (form.edit_search_range2 || form.edit_search_range2 === 0) {
                    fireData = fireData.where("range", "==", Number(form.edit_search_range2))
                }
                if (!form.ssStartDate && !form.ssStartDate) {
                    if (form.edit_search_avatar) {
                        fireData = fireData.where("meta.avatarUrl", "!=", "noavatar.jpg")
                    } else if (form.edit_filter_payment) {
                        fireData = fireData.where("packet", "!=", 0)
                    } else if (form.edit_filter_coin_up) {
                        fireData = fireData.where("coin", ">=", Number(form.edit_filter_coin_up))
                        if (form.edit_filter_coin_down) {
                            fireData = fireData.where("coin", ">=", Number(form.edit_filter_coin_down))
                        }
                    } else if (form.edit_filter_coin_down) {
                        fireData = fireData.where("coin", ">=", Number(form.edit_filter_coin_down))
                    }
                }
                if (form.edit_filter_userId) {
                    fireData = fireData.where("docId", "==", form.edit_filter_userId)
                }
                if (form.edit_filter_ref) {
                    fireData = fireData.where("ref", "==", form.edit_filter_ref)
                }
                if (form.edit_filter_country) {
                    fireData = fireData.where("meta.country", "==", form.edit_filter_country)
                }
                if (form.edit_filter_city) {
                    fireData = fireData.where("meta.city", "==", form.edit_filter_city)
                }
                if (form.edit_search_gender2 || form.edit_search_gender2 === 0) {
                    fireData = fireData.where("meta.gender", "==", Number(form.edit_search_gender2))
                }
            }
        }
        if (!form.edit_search_avatar && !form.edit_filter_payment && !form.edit_filter_coin_up && !form.edit_filter_coin_down) {
            head.forEach(doc => {
                if (doc.orderBy && doc.orderBy !== "disable") {
                    fireData = fireData.orderBy(doc.key, doc.orderBy)
                }
            })
        }
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        fireData.limit(perPage).get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(NEWSLETTERS_SEARCH_USER({
                    list: userList
                }))
                dispatch(NEWSLETTERS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (!tekrar && doc && _lastData) {
                if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                } else if (!lastData) {
                    lastDataSet([_lastData])
                }
            } else if (!tekrar) {
                return null
            }
            await asyncForEach(doc.docs, async (doc) => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["verify"] = doc.data().verify
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip ? doc.data().meta.zip : "" },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(NEWSLETTERS_SEARCH_USER({
                list: userList
            }))
            dispatch(NEWSLETTERS_END())
        })
    }
}
const unCheck = (userId) => {
    return async (dispatch, getState) => {
        // let unCheckUsers = getState().newsletters.unCheckUsers ? getState().newsletters.unCheckUsers : []
        // console.log(unCheckUsers.findIndex(x => x.docId ===userId));
        // if(unCheckUsers.findIndex(x => x.docId ===userId) !== -1){
        // unCheckUsers.splice(unCheckUsers.findIndex(x => x.docId ===userId), 1)
        // console.log(unCheckUsers);
        // unCheckUsers.splice(userId, 1)
        dispatch(NEWSLETTERS_UNCHECK(userId))
        // dispatch(onChange("unCheckUsers", unCheckUsers))
        // setTimeout(() => {
        //     dispatch(searchUsers())
        // }, 500)
        // }
    }
}

const sendAll = (docId) => {
    return async (dispatch, getState) => {
        let total = 0
        let totalSize = 0
        // const sendTen = async (arr) => {
        //     console.log(total);
        //     let element = arr[total]
        //     // const element = doc.docs[total];
        //     if(element.data().s){
        //         await firebase.firestore().collection("newsletterWaiting").doc(element.id).update({
        //             s:false
        //         })
        //         await firebase.firestore().collection("newsletterWaiting").doc(element.id).update({
        //             s:true
        //         })
        //     }else{
        //         await firebase.firestore().collection("newsletterWaiting").doc(element.id).update({
        //             s:true
        //         })
        //     }
        //     total++;
        //     if(total === totalSize){
        //         console.log("son");
        //         await firebase.firestore().collection('newsletter').doc(element.data().i).update({
        //             status: "Ok"
        //         })
        //         await firebase.firestore().collection('newsletter').doc(element.data().i).get().then(doc=>{
        //             if(doc.data().fakeId){
        //                 firebase.firestore().collection('users').doc(doc.data().fakeId).update({
        //                     newsletter: true
        //                 })
        //             }
        //         })
        //         dispatch(NEWSLETTERS_END())
        //     }else{
        //         setTimeout(() => {
        //             sendTen(arr) 
        //         }, 100);
        //     }

        // }


        dispatch(NEWSLETTERS_START())

        firebase.firestore().collection("newsletterWaiting").where("i", "==", docId).get().then(async doc => {
            if (doc.size) {
                // sendTen(doc.docs)
                for (let index = 0; index < doc.size; index++) {
                    const element = doc.docs[index];
                    if (element.data().s) {
                        await firebase.firestore().collection("newsletterWaiting").doc(element.id).update({
                            s: false
                        })
                        await firebase.firestore().collection("newsletterWaiting").doc(element.id).update({
                            s: true
                        })
                    } else {
                        await firebase.firestore().collection("newsletterWaiting").doc(element.id).update({
                            s: true
                        })
                    }
                    console.log("send")
                }
                await firebase.firestore().collection('newsletter').doc(docId).update({
                    status: "Ok"
                })
                await firebase.firestore().collection('newsletter').doc(docId).get().then(doc => {
                    if (doc.data().fakeId) {
                        firebase.firestore().collection('users').doc(doc.data().fakeId).update({
                            newsletter: true
                        })
                    }
                })
            }
            console.log("son");
            dispatch(NEWSLETTERS_END())
        })
        // dispatch(getData("first"))
        // dispatch(NEWSLETTERS_END())
    }
}
const sendWaiting = (docId) => {
    return async (dispatch, getState) => {
        dispatch(NEWSLETTERS_START())
        let newsWaitintGet = await firebase.firestore().collection("newsletterWaiting").doc(docId).get()
        // firebase.firestore().collection("newsletterWaiting").doc(docId).get().then(async doc => {
        if (newsWaitintGet.data()) {
            if (newsWaitintGet.data().s) {
                await firebase.firestore().collection("newsletterWaiting").doc(newsWaitintGet.id).update({
                    s: false
                })
                await firebase.firestore().collection("newsletterWaiting").doc(newsWaitintGet.id).update({
                    s: true
                })
            } else {
                await firebase.firestore().collection("newsletterWaiting").doc(newsWaitintGet.id).update({
                    s: true
                })
            }
        }

        console.log("son");
        dispatch(NEWSLETTERS_END())
        // })
        // dispatch(getData("first"))
        // dispatch(NEWSLETTERS_END())
    }
}

const sendNewsletter = (docId) => {
    return async (dispatch, getState) => {
        dispatch(NEWSLETTERS_START())
        firebase.firestore().collection("newsletterWaiting").where("i", "==", docId).get().then(async doc => {
            let total = 0
            let totalSize = doc.size
            if (doc.size) {
                doc.forEach(doc => {
                    firebase.firestore().collection("newsletterWaiting").doc(doc.id).update({
                        s: true
                    }).then(doc => {
                        total++;
                        if (total === totalSize) {
                            console.log("son");
                            dispatch(NEWSLETTERS_END())
                        }
                    })
                })
            } else {
                console.log("son");
                dispatch(NEWSLETTERS_END())
            }
        })
        await firebase.firestore().collection('newsletter').doc(docId).update({
            status: "Ok"
        })
        firebase.firestore().collection('newsletter').doc(docId).get().then(doc => {
            if (doc.data().fakeId) {
                firebase.firestore().collection('users').doc(doc.data().fakeId).update({
                    newsletter: true
                })
            }
        })
        // dispatch(getData("first"))
        dispatch(NEWSLETTERS_END())
    }
}
const deleteNewsletter = (docId) => {
    return async (dispatch, getState) => {
        dispatch(NEWSLETTERS_START())
        try {
            await firebase.firestore().collection("newsletter").doc(docId).delete()
            firebase.firestore().collection("newsletterWaiting").where("i", "==", docId).get().then(async doc => {
                let total = 0
                let totalSize = doc.size
                if (!doc.size) {
                    console.log("son");
                    dispatch(NEWSLETTERS_END())
                } else {
                    doc.forEach(doc2 => {
                        firebase.firestore().collection("newsletterWaiting").doc(doc2.id).delete().then(doc2 => {
                            total++
                            if (total === totalSize) {
                                console.log("son");
                                dispatch(NEWSLETTERS_END())
                            }
                        })
                    })
                }
            })
        } catch (error) {
            console.log("son");
            dispatch(NEWSLETTERS_END())
            console.log(error);
        }
        // dispatch(getData("first"))
    }
}
const Check = (userId) => {
    return async (dispatch, getState) => {
        let unCheckUsers = getState().newsletters.unCheckUsers ? getState().newsletters.unCheckUsers : []
        let searchListUsers = getState().newsletters.searchListUsers ? getState().newsletters.searchListUsers : []
        if (unCheckUsers.findIndex(x => x.docId === userId) === -1) {
            let data = searchListUsers[searchListUsers.findIndex(x => x.docId === userId)]
            dispatch(NEWSLETTERS_CHECK(data))
        }
        // dispatch(onChange("unCheckUsers", unCheckUsers))
        // dispatch(onChange("edit_search_nickname_newsletter", ""))
        // setTimeout(() => {
        //     dispatch(searchUsers())
        // }, 500)
    }
}
const photoUpdate = (e) => {
    return async (dispatch, getState) => {
        // const edit_ref = getState().form.edit_ref;
        resize.play(e).then((response) => {
            let randomid = makeid(12)
            firebase.storage().ref('newsletters/' + randomid + '' + e.files[0].name).put(response).then(doc => {
                firebase.storage().ref('newsletters/' + randomid + '' + e.files[0].name).getDownloadURL().then(url => {
                    dispatch(onChange("newsletter_image", url))
                })
            })
        })
    }
}
const newsletterEnd = (e) => {
    return async (dispatch, getState) => {
        dispatch(NEWSLETTERS_END())
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(NEWSLETTERS_CHANGE({
            [name]: value
        }))
    }
}
export { sendWaiting, sendAll, changeData, getWaitingNewsletter, newsletterEnd, Check, unCheck, searchUsers, getData, search, photoUpdate, sendNewsletter, deleteNewsletter };
