import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';
import { onChange } from "./form"
import { usersList } from "./usersList"
import ResizeImage from 'image-resize'
import moment from "moment"
import { defaultFakeId } from "../../Settings"


export const CALLCENTERS_START = createAction('CALLCENTERS_START');
export const CALLCENTERS_END = createAction('CALLCENTERS_END');
export const CALLCENTERS_SEARCH_USER = createAction('CALLCENTERS_SEARCH_USER');
export const CALLCENTERS_USER_DETAIL = createAction('CALLCENTERS_USER_DETAIL');
export const CALLCENTERS_LAST_MESSAGES = createAction('CALLCENTERS_LAST_MESSAGES');
export const CALLCENTERS_LAST_MESSAGES2 = createAction('CALLCENTERS_LAST_MESSAGES2');
export const CALLCENTERS_MESSAGES_LIST = createAction('CALLCENTERS_MESSAGES_LIST');
export const CALLCENTERS_MESSAGES = createAction('CALLCENTERS_MESSAGES');
export const CALLCENTERS_STATS = createAction('CALLCENTERS_STATS');

let unsubscribe = ""
let unsubscribe2 = ""

const resize = new ResizeImage({
    format: 'jpg',
    width: 640,
    outputType: "blob"
});

const resize2 = new ResizeImage({
    format: 'jpg',
    width: 300,
    outputType: "blob"
});

const resize3 = new ResizeImage({
    format: 'jpg',
    width: 64,
    outputType: "blob"
});

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}



const usersData = (first, lastData, lastDataSet, perPage, head, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form
        dispatch(CALLCENTERS_START())
        let fireData = firebase.firestore().collection("callcenters")
        if (form.search_id) {
            fireData = fireData.where("docId", "==", form.search_id)
        } else {
            if (form.search_nickname) {
                fireData = fireData.where("nickname", "==", form.search_nickname.toLowerCase());
            } else {
                if (form.search_cadmin) {
                    fireData = fireData.where("cadmin", "==", form.search_cadmin)
                }
            }
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        fireData.limit(perPage).get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(CALLCENTERS_SEARCH_USER({
                    list: userList
                }))
                dispatch(CALLCENTERS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                } else if (!lastData) {
                    lastDataSet([_lastData])
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "bold", value: doc.data().nickname, sub: doc.id },
                    { type: "text", value: doc.data().email },
                    { type: "text", value: doc.data().online ? "Online" : "Offline" },
                    { type: "text", value: doc.data().cadmin },
                    { type: "text", value: doc.data().total_get },
                    { type: "text", value: doc.data().total_post },
                    { type: "text", value: doc.data().total_asa1 + doc.data().total_asa2 + doc.data().total_asa3 + doc.data().total_automessage },
                    { type: "date", value: doc.data().lastLogin ? new Date(doc.data().lastLogin.toDate()).toLocaleString() : "-" },
                    // { type: "date", value:  doc.data().timestamp ? new Date(doc.data().timestamp.toDate()).toLocaleString() : "-" },
                ]
                userList.push(pushArr);
            })
            dispatch(CALLCENTERS_SEARCH_USER({
                list: userList
            }))
            dispatch(CALLCENTERS_END())
        })
    }
}


const getUserDetail = (id) => {
    return async (dispatch, getState) => {
        if (unsubscribe) {
            unsubscribe()
        }
        unsubscribe = firebase.firestore().collection("callcenters").doc(id).onSnapshot(doc => {
            dispatch(CALLCENTERS_USER_DETAIL(doc.data()))
        })
    }
}


const getMessagesList = (id) => {
    return async (dispatch, getState) => {

        let fakeArr = []
        let userArr = []
        firebase.firestore().collection("messages").where("cc", "==", id).limit(20).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                let fakeId = doc.data().get;
                let userId = doc.data().post;
                let send = "user";
                if (fakeId.split("_")[0] !== defaultFakeId) {
                    fakeId = doc.data().post;
                    userId = doc.data().get;
                    send = "cc";
                }
                arr.push({
                    id: doc.data().id,
                    message: doc.data().message,
                    time: doc.data().time.toDate(),
                    cc: doc.data().cc,
                    fake: fakeId,
                    user: userId,
                    send: send,
                })
                if (!fakeArr.includes(fakeId)) {
                    fakeArr.push(fakeId)
                }
                if (!userArr.includes(userId)) {
                    userArr.push(userId)
                }
            })
            fakeArr.forEach(doc => {
                firebase.firestore().collection("users").doc(doc).get().then(doc => {
                    if (doc.data()) {

                        dispatch(usersList(doc))
                    }
                })
            })
            userArr.forEach(doc => {
                firebase.firestore().collection("users").doc(doc).get().then(doc => {
                    if (doc.data()) {

                        dispatch(usersList(doc))
                    }
                })
            })
            dispatch(CALLCENTERS_END())
            dispatch(CALLCENTERS_MESSAGES_LIST(arr))

        })
    }
}


const saveUser = (userId) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        if (!form.formNickname || !form.formEmail || !form.formPassword) {
            return null
        }
        console.log(form.formDisable);
        dispatch(CALLCENTERS_START())
        firebase.firestore().collection('callcenters').doc(userId).update({
            "cadmin": form.formCadmin,
            "nickname": form.formNickname,
            "email": form.formEmail,
            "password": form.formPassword,
            "disable": form.formDisable === "true" ? true : false
        }).then(doc => {
            dispatch(CALLCENTERS_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(CALLCENTERS_END())
        })
    }
}

const addCC = () => {
    return async (dispatch, getState) => {
        const form = getState().form;
        if (!form.formNickname || !form.formEmail || !form.formPassword) {
            return null
        }
        dispatch(CALLCENTERS_START())
        firebase.firestore().collection('callcenters').add({
            "cadmin": form.formCadmin,
            "nickname": form.formNickname,
            "email": form.formEmail,
            "password": form.formPassword,
            "disable": form.formDisable ? true : false,
            "activeMessageId": "",
            "online": false,
            "lastLogin": firebase_app.firestore.FieldValue.serverTimestamp(),
            "timestamp": firebase_app.firestore.FieldValue.serverTimestamp(),
            "total_asa1": 0,
            "total_asa2": 0,
            "total_asa3": 0,
            "total_get": 0,
            "total_post": 0,
        }).then(doc => {
            firebase.firestore().collection('callcenters').doc(doc.id).update({
                docId: doc.id
            })
            dispatch(onChange("formCadmin", ""))
            dispatch(onChange("formNickname", ""))
            dispatch(onChange("formEmail", ""))
            dispatch(onChange("formPassword", ""))
            dispatch(onChange("formDisable", ""))
            dispatch(onChange("formCadmin", ""))
            dispatch(CALLCENTERS_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(CALLCENTERS_END())
        })
    }
}
const usersEnd = (e) => {
    return async (dispatch, getState) => {
        dispatch(CALLCENTERS_END())
    }
}

const getMessages = (id) => {
    return async (dispatch, getState) => {
        firebase.firestore().collection("messages").where("id", "==", id).orderBy("time", "desc").limit(30).get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push(doc.data())
            })
            dispatch(CALLCENTERS_MESSAGES(arr))
            dispatch(CALLCENTERS_END())
        })
    }
}

const ccStat = (id) => {
    return async (dispatch, getState) => {
        dispatch(CALLCENTERS_START())
        let form = getState().form
        let startDate = new Date(moment().startOf('day'))
        let endDate = new Date(moment().endOf('day'))
        if (form.startDateCC && form.endDateCC) {
            startDate = new Date(moment(form.startDateCC).startOf('day'))
            endDate = new Date(moment(form.endDateCC).endOf('day'))
        }

        let fire = firebase.firestore().collection("messages").where("cc", "==", id)
        if (form.startDateCC && form.endDateCC) {
            fire = fire.where("time", "<=", endDate)
        }
        fire.where("time", ">=", startDate).get().then(doc => {
            let tg = 0,
                ta = 0,
                asa1 = 0,
                asa2 = 0,
                asa3 = 0,
                auto = 0,
                waiting = 0,
                match = 0;
            doc.forEach(doc => {
                if (doc.data().post.split("_")[0] === defaultFakeId) {
                    if (doc.data().asa === "Asa1") {
                        asa1++
                    } else if (doc.data().asa === "Asa2") {
                        asa2++
                    } else if (doc.data().asa === "Asa3") {
                        asa3++
                    } else if (doc.data().asa === "match") {
                        match++
                    } else if (doc.data().asa === "" && doc.data().type !== "automessage") {
                        tg++
                    } else if (doc.data().asa === "automessage") {
                        auto++
                    } else if (doc.data().asa === "waiting") {
                        waiting++
                    } else {
                        console.log(doc.data())
                    }
                } else {
                    ta++
                }
            })
            dispatch(CALLCENTERS_STATS({
                tg: tg,
                ta: ta,
                asa1: asa1,
                asa2: asa2,
                asa3: asa3,
                auto: auto,
                waiting: waiting,
                match: match
            }))
            dispatch(CALLCENTERS_END())
        })
    }
}
const checkStats = (id) => {
    return async (dispatch, getState) => {
        dispatch(CALLCENTERS_START())

        let fire = firebase.firestore().collection("messages").where("cc", "==", id)
        await fire.get().then(doc => {
            let tg = 0,
                ta = 0,
                asa1 = 0,
                asa2 = 0,
                asa3 = 0,
                auto = 0,
                waiting = 0,
                match = 0;
            doc.forEach(doc => {
                if (doc.data().post.split("_")[0] === defaultFakeId) {
                    if (doc.data().asa === "Asa1") {
                        asa1++
                    } else if (doc.data().asa === "Asa2") {
                        asa2++
                    } else if (doc.data().asa === "Asa3") {
                        asa3++
                    } else if (doc.data().asa === "match") {
                        match++
                    } else if (doc.data().asa === "" && doc.data().type !== "automessage") {
                        tg++
                    } else if (doc.data().asa === "automessage") {
                        auto++
                    } else if (doc.data().asa === "waiting") {
                        waiting++
                    } else {
                        console.log(doc.data().asa)
                        console.log(doc.data().type)
                    }
                } else {
                    ta++
                }
            })
            firebase.firestore().collection("callcenters").doc(id).update({
                total_post: tg,
                total_get: ta,
                total_asa1: asa1,
                total_asa2: asa2,
                total_asa3: asa3,
                total_automessage: auto,
                total_waiting: waiting,
                total_match: match,
            })
            console.log(tg);
            console.log(ta);
            console.log(asa1);
            console.log(asa2);
            console.log(asa3);
            console.log(auto);
            console.log(waiting);
            console.log(match);
            dispatch(CALLCENTERS_END())
        })
    }
}
export { checkStats, addCC, ccStat, getMessages, saveUser, getMessagesList, getUserDetail, usersEnd, usersData };
