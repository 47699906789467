import { createReducer } from 'redux-act';

import {
  GIFTS_DATA,
  PACKETS_DATA,
  AFFILIATES_NAME
} from '../actions/general';
const initialState = {};

const formReducer = createReducer(
  {
    [AFFILIATES_NAME]: (state, payload) => {
      return({
        ...state,
        affiliates:payload,
      })
    },
    [GIFTS_DATA]: (state, payload) => {
      return({
        ...state,
        gifts: payload,
      })
    },
    [PACKETS_DATA]: (state, payload) => {
      return({
        ...state,
        packets: payload.list1,
        packets2: payload.list2,
        loading: false
      })
    },
  },
  initialState
);

export default formReducer