import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import { onChange } from "./form"
import firebase_app from 'firebase/compat/app';
import { defaultLink } from "../../Settings"

export const AFFILIATE_START = createAction('AFFILIATE_START');
export const AFFILIATE_END = createAction('AFFILIATE_END');
export const AFFILIATE_DATA = createAction('AFFILIATE_DATA');
export const AFFILLIATES_STATS = createAction('AFFILLIATES_STATS');
export const AFFILIATES_SEARCH_USER = createAction('AFFILIATES_SEARCH_USER');
export const AFFILIATES_SEARCH_USER_DELETED = createAction('AFFILIATES_SEARCH_USER_DELETED');
export const AFFILIATES_CHANGE = createAction('AFFILIATES_CHANGE');

let unsubscribe = ""
let unsubscribe2 = ""
const addAffiliate = (type) => {
    return async (dispatch, getState) => {
        const form = getState().form;
        // if(!form.formNickname || !form.formEmail || !form.formPassword){
        //     return null
        // }
        dispatch(AFFILIATE_START())
        firebase.firestore().collection('affiliate').doc(form.formLink).set({
            docId: form.formLink,
            link: defaultLink + "/?ref=" + form.formLink,
            "count": Number(form.formCount),
            "countSafe": Number(form.formCountSafe),
            "name": form.formName,
            "totalPacket": Number(form.formTotalPacket),
            "totalPay": Number(form.formTotalPay),
            "totalUser": Number(form.formTotalUser),
            "timestamp": firebase_app.firestore.FieldValue.serverTimestamp(),
        }).then(doc => {
            dispatch(onChange("formCount", ""))
            dispatch(onChange("formCountSafe", ""))
            dispatch(onChange("formName", ""))
            dispatch(onChange("formLink", ""))
            dispatch(onChange("formTotalPacket", ""))
            dispatch(onChange("formTotalPay", ""))
            dispatch(onChange("formTotalUser", ""))
            dispatch(AFFILIATE_END())
        }).catch(doc => {
            console.log(doc);
            dispatch(AFFILIATE_END())
        })
    }
}
const affiliatesStats = (type) => {
    return (dispatch, getState) => {
        if (unsubscribe) {
            unsubscribe()
        }
        unsubscribe = firebase.firestore().collection("affiliate").onSnapshot(doc => {
            let arr = [];
            doc.forEach(doc => {
                arr.push(doc.data())
            })
            dispatch(AFFILLIATES_STATS(arr))
        })
    }
}

const deleteAffiliate = (id) => {
    firebase.firestore().collection("affiliate").doc(id).delete()
}



const affiliateData = (first, lastData, lastDataSet, perPage, head, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form
        dispatch(AFFILIATE_START())
        let fireData = firebase.firestore().collection("affiliate")
        if (form.search_id) {
            fireData = fireData.where("id", "==", form.search_id)
        } else {
            if (form.search_docId) {
                fireData = fireData.where("docId", "==", form.search_docId.toLowerCase());
            } else if (form.search_name) {
                fireData = fireData.where("name", "==", form.search_name.toLowerCase());
            } else {
                if (form.searchStartDate) {
                    fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
                }
                if (form.searchEndDate) {
                    fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
                }
            }
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        if (unsubscribe2) {
            unsubscribe2()
        }
        unsubscribe2 = fireData.limit(perPage).onSnapshot(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(AFFILIATE_DATA({
                    list: userList
                }))
                dispatch(AFFILIATE_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (activePage >= lastData.length) {
                    if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                        lastData.push(_lastData)
                        lastDataSet(lastData)
                    } else if (!lastData) {
                        lastDataSet([_lastData])
                    }
                } else {
                    if (lastData) {
                        lastData.pop()
                        lastData.push(_lastData)
                        lastDataSet(lastData)
                    } else {
                        lastDataSet([_lastData])
                    }
                }
            }
            // if(doc && _lastData){
            //     if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
            //         lastData.push(_lastData)
            //         lastDataSet(lastData)
            //     }else if(!lastData){
            //         lastDataSet([_lastData])
            //     }
            // }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "bold", value: doc.data().name, sub: doc.data().docId },
                    { type: "text", value: doc.data().link },
                    { type: "text", value: doc.data().count },
                    { type: "text", value: doc.data().countSafe },
                    { type: "text", value: doc.data().totalPacket },
                    { type: "text", value: doc.data().totalPay.toFixed(2) },
                    { type: "text", value: doc.data().totalUser },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(AFFILIATE_DATA({
                list: userList
            }))
            dispatch(AFFILIATE_END())
        })
    }
}


const usersData = (first, head, ref) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let users = getState().users
        let affiliates = getState().general.affiliates ? getState().general.affiliates : {}
        dispatch(AFFILIATE_START())
        let fireData = firebase.firestore().collection("users").where("role", "==", 0).where("ref", "==", ref)
        if (form.search_id) {
            fireData = fireData.where("docId", "==", form.search_id)
        } else {
            if (form.search_nickname) {
                fireData = fireData.where("meta.nickname_search", "==", form.search_nickname.toLowerCase());
            } else {
                if (form.searchStartDate) {
                    fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
                }
                if (form.searchEndDate) {
                    fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
                }
                if (form.search_verify) {
                    fireData = fireData.where("verify", "==", form.search_verify === "true" ? true : false)
                }
                if (form.search_country) {
                    fireData = fireData.where("meta.country", "==", form.search_country)
                }
                if (form.search_city) {
                    fireData = fireData.where("meta.city", "==", form.search_city)
                }
            }
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (users.lastData && users.lastData[(users.activePage - 1)] && first === "") {
            fireData = fireData.startAfter(users.lastData[(users.activePage - 1)])
        }
        fireData.get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(AFFILIATES_SEARCH_USER({
                    list: userList
                }))
                dispatch(AFFILIATE_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (users.lastData && (users.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    users.lastData.push(_lastData)
                } else if (!users.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["verify"] = doc.data().verify
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.email },
                    { type: "text", value: doc.data().meta.gender ? "Weiblich" : "Mannlich" },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip ? doc.data().meta.zip : "" },
                    { type: "badge", value: doc.data().online ? "Online" : "Offline", class: doc.data().online ? "success" : "danger" },
                    { type: "text", value: affiliates[doc.data().ref] ? affiliates[doc.data().ref] : doc.data().ref },
                    { type: "badge", value: doc.data().verify ? "True" : "False", class: doc.data().verify ? "success" : "danger" },
                    { type: "text", value: doc.data().pay ? doc.data().pay.toFixed(2) : 0 },
                    { type: "star", value: doc.data().coin, class: doc.data().prize.sendMessage ? "" : "1" },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(AFFILIATES_SEARCH_USER({
                list: userList
            }))
            dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            dispatch(AFFILIATE_END())
        })
    }
}

const usersDataDeleted = (first, head, ref) => {
    return async (dispatch, getState) => {
        let form = getState().form
        let users = getState().users
        let affiliates = getState().general.affiliates ? getState().general.affiliates : {}
        dispatch(AFFILIATE_START())
        let fireData = firebase.firestore().collection("usersDeleted").where("ref", "==", ref)
        if (form.search_id) {
            fireData = fireData.where("docId", "==", form.search_id)
        } else {
            if (form.search_nickname) {
                fireData = fireData.where("meta.nickname_search", "==", form.search_nickname.toLowerCase());
            } else {
                if (form.searchStartDate) {
                    fireData = fireData.where("timestamp", ">=", new Date(form.searchStartDate))
                }
                if (form.searchEndDate) {
                    fireData = fireData.where("timestamp", "<=", new Date(form.searchEndDate))
                }
                if (form.search_verify) {
                    fireData = fireData.where("verify", "==", form.search_verify === "true" ? true : false)
                }
                if (form.search_country) {
                    fireData = fireData.where("meta.country", "==", form.search_country)
                }
                if (form.search_city) {
                    fireData = fireData.where("meta.city", "==", form.search_city)
                }
            }
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (users.lastData && users.lastData[(users.activePage - 1)] && first === "") {
            fireData = fireData.startAfter(users.lastData[(users.activePage - 1)])
        }
        fireData.get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(AFFILIATES_SEARCH_USER_DELETED({
                    list: userList
                }))
                dispatch(AFFILIATE_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (users.lastData && (users.lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    users.lastData.push(_lastData)
                } else if (!users.lastData) {
                    dispatch(changeData("lastData", [_lastData]))
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["verify"] = doc.data().verify
                pushArr["data"] = [
                    { type: "avatar", value: doc.data().meta.avatarThumb },
                    { type: "bold", value: doc.data().meta.nickname, sub: doc.id },
                    { type: "text", value: doc.data().meta.email },
                    { type: "text", value: doc.data().meta.gender ? "Weiblich" : "Mannlich" },
                    { type: "text", value: doc.data().meta.country },
                    { type: "text", value: doc.data().meta.city },
                    { type: "text", value: doc.data().meta.zip ? doc.data().meta.zip : "" },
                    { type: "badge", value: doc.data().online ? "Online" : "Offline", class: doc.data().online ? "success" : "danger" },
                    { type: "text", value: affiliates[doc.data().ref] ? affiliates[doc.data().ref] : doc.data().ref },
                    { type: "badge", value: doc.data().verify ? "True" : "False", class: doc.data().verify ? "success" : "danger" },
                    { type: "text", value: doc.data().pay ? doc.data().pay.toFixed(2) : 0 },
                    { type: "star", value: doc.data().coin, class: doc.data().prize.sendMessage ? "" : "1" },
                    { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
            })
            dispatch(AFFILIATES_SEARCH_USER_DELETED({
                list: userList
            }))
            dispatch(changeData("totalPage", Math.floor(userList.length / 10) ? Math.floor(userList.length / 10) : 1))
            dispatch(AFFILIATE_END())
        })
    }
}
const changeData = (name, value) => {
    return async (dispatch, getState) => {
        dispatch(AFFILIATES_CHANGE({
            [name]: value
        }))
    }
}
const reloadAffiliate = (docId) => {
    return async (dispatch, getState) => {
        // firebase.firestore().collection("payments").
    }
}

export { usersDataDeleted, reloadAffiliate, changeData, usersData, addAffiliate, deleteAffiliate, affiliateData, affiliatesStats };
