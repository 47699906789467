import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import { photoUpdate, photoDelete, saveData } from "../../state/actions/auto_messages"
import Textarea from "../../components/Items/textarea"
import Select from "../../components/Items/select"
import Input from "../../components/Items/input"
import { ButtonPrimary, ButtonPrimaryLigth, ButtonDanger } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import { onChange } from '../../state/actions/form';

const SendAutoMessages = () => {
    const { loading, form } = useSelector(
        (state) => ({
            loading: state.auto_messages.loading,
            form: state.form,
        }), shallowEqual
    );
    const { id } = useParams();
    const [sendStat, sendStatSet] = useState(true)
    const dispatch = useDispatch();
    let history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [dispatch]);

    return (
        <section className={Styles.Pages}>

            <div className={Styles.headerBottom}>
                <div className={Styles.left}>
                    <NavLink to="/auto-messages">
                        Auto Messages
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <NavLink to="/auto-messages/add">
                        Select Fake
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={Styles.right}>
                    <NavLink to={"/auto-messages"}>
                        Back
                    </NavLink>
                    {sendStat ? <ButtonPrimary onClick={(e) => { dispatch(saveData(id, history)); sendStatSet(false) }} style={{ height: 38, borderRadius: 5, fontWeight: 500, fontSize: 13, marginLeft: 15 }} text="Save" /> : ""}

                </div>
            </div>
            <div className={Styles.contentWhite} style={{ marginBottom: 25, padding: 34 }}>
                <div className={classNames(Styles.center)} style={{ maxWidth: 500 }}>
                    <div className={Styles.list}>
                        <label>Message :</label>
                        <Textarea styletype="type_2" name="autoMessages_message" height={200} style={{ flex: 1, marginBottom: 0 }} />
                    </div>
                    <div className={Styles.list}>
                        <label>Message 2 :</label>
                        <Textarea styletype="type_2" name="autoMessages_message2" height={200} style={{ flex: 1, marginBottom: 0 }} />
                    </div>
                    <div className={Styles.list} style={{ marginBottom: 20 }}>
                        <label></label>
                        <small style={{ flex: 1 }}>%username% - %region% - %alt%</small>
                    </div>
                    <div className={Styles.list}>
                        <label>Ref :</label>
                        <Input styletype="type_2" name="autoMessages_ref" type="text" label="Ref :" />
                    </div>
                    <div className={Styles.list}>
                        <label>Type :</label>
                        <Input styletype="type_2" name="autoMessages_type" type="text" label="Type :" />
                    </div>
                    <div className={Styles.list}>
                        <label>Image :</label>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div className={Styles.buttonUpload}>
                                <input className={Styles.absolute} type="file" onChange={(e) => dispatch(photoUpdate("autoMessages_image", e.target))} />
                                <ButtonPrimaryLigth style={{ height: 38, fontSize: 13, borderRadius: 5 }} text="Upload Image" />
                            </div>
                            <div>
                                <ButtonDanger style={{ height: 38, fontSize: 13, marginLeft: 10, borderRadius: 5 }} text="Delete Image" onClick={(e) => dispatch(photoDelete("autoMessages_image"))} />
                            </div>
                        </div>
                    </div>
                    <div className={Styles.list}>
                        <label></label>
                        <small style={{ flex: 1 }}>{form.autoMessages_image}</small>
                    </div>
                    <div className={Styles.list}>
                        <label>Coin :</label>
                        <Input styletype="type_2" name="autoMessages_image_coin" type="text" label="Coin :" />
                    </div>
                    <div className={Styles.list}>
                        <label>Image 2 :</label>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div className={Styles.buttonUpload}>
                                <input className={Styles.absolute} type="file" onChange={(e) => dispatch(photoUpdate("autoMessages_image2", e.target))} />
                                <ButtonPrimaryLigth style={{ height: 38, fontSize: 13, borderRadius: 5 }} text="Upload Image" />
                            </div>
                            <div>
                                <ButtonDanger style={{ height: 38, fontSize: 13, marginLeft: 10, borderRadius: 5 }} text="Delete Image" onClick={(e) => dispatch(photoDelete("autoMessages_image2"))} />
                            </div>
                        </div>
                    </div>
                    <div className={Styles.list}>
                        <label></label>
                        <small style={{ flex: 1 }}>{form.autoMessages_image2}</small>
                    </div>
                    <div className={Styles.list}>
                        <label>Coin :</label>
                        <Input styletype="type_2" name="autoMessages_image_coin2" type="text" label="Coin :" />
                    </div>
                </div>
                {loading ? <div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default SendAutoMessages;



// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
// import Styles from "./AutoMessages.module.scss"
// // import { ButtonMessage} from "../../components/Items/buttons"
// import classNames from "classnames"
// import { photoUpdate, photoDelete, saveChanges } from "../../state/actions/auto_messages"
// import Textarea from "../../components/Items/textarea"
// // import { InputDropdown} from "../../components/Items/input"
// import Input from "../../components/Items/input"
// import { ButtonPrimary, ButtonDanger } from "../../components/Items/buttons"

// const SendAutoMessages = () => {
//     const { loading, form} = useSelector(
//         (state) => ({
//             loading: state.users.loading,
//             form: state.form,
//         }), shallowEqual
//     );
//     const { id } = useParams();
//     const dispatch = useDispatch();
//     useEffect(() => {
//         window.scrollTo(0, 0);
//         // eslint-disable-next-line
//     }, [dispatch]);
//     return (
//         <section className={Styles.AutoMessages}>
//             <div className="headerBottom">
//                 <div className="left">
//                     <h1><NavLink to="/auto-messages">Auto Messages</NavLink> Add Auto Messages</h1>
//                     <p>{id}</p>
//                 </div>
//                 <div className="right">
//                     <NavLink to="/auto-messages/add" className="button">
//                         Back
//                     </NavLink>
//                     {/* onClick={(e) => dispatch(addData())} */}
//                     <ButtonPrimary onClick={(e) => dispatch(saveChanges(id))} style={{ height: 38, fontSize: 13 }} text="Save Auto Message" />
//                 </div>
//             </div>
//             <div className={Styles.contentWhite} style={{ marginBottom: 25, padding:25 }}>
//                 {/* <p>(Enter ref before uploading images)</p> */}
//                 <h3 className={Styles.subTitle}>Add Message</h3>
//                 <div className={classNames(Styles.center, Styles.twoColumn)} style={{ marginBottom: 25, marginLeft:"auto", marginRight:"auto" }}>

//                     <Input styletype="type_3" name="autoMessages_time" type="text" label="Time :" />
//                     <div className={Styles.list}>
//                         <label>Message :</label>
//                         <Textarea name="autoMessages_message" height={200} style={{flex:1, marginBottom:0}} />
//                     </div>
//                     <div className={Styles.list} style={{ marginBottom: 20 }}>
//                         <label></label>
//                         <small style={{ flex: 1 }}>%username% - %region% - %alt%</small>
//                     </div>
//                     <div className={Styles.list}>
//                         <label>Image :</label>
//                         <div style={{display:"flex", flex:1}}>
//                             <div className={Styles.buttonUpload}>
//                                 <input className={Styles.absolute} type="file" onChange={(e) => dispatch(photoUpdate(e.target))} />
//                                 <ButtonPrimary style={{ height: 38, fontSize: 13 }} text="Upload Image" />
//                             </div>
//                             <div>
//                                 <ButtonDanger style={{ height: 38, fontSize: 13, marginLeft: 10 }} text="Delete Image" onClick={(e) => dispatch(photoDelete())}/>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={Styles.list}>
//                         <label></label>
//                         <small style={{ flex: 1 }}>{form.autoMessages_image}</small>
//                     </div>
//                 </div>
//                 {loading ?<div className="loading"></div> : ""}
//             </div>
//         </section>
//     )
// }

// export default SendAutoMessages;
