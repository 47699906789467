import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { contactsData} from "../../state/actions/contacts"
import Table from "../../components/Items/table"
import TableHead from "./TableHead"
import {onChange} from "../../state/actions/form"

const Users = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.contacts.loading,
            list: state.contacts && state.contacts.list ? state.contacts.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    // { type: "text", value: doc.data().username, sub:doc.data().userUd },
    // { type: "text", value: doc.data().email },
    // { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
    // { type: "date", value:  new Date(doc.data().timestamp.toDate()).toLocaleString() },
    const [head, headSet] = useState([
        {name:"Username", orderBy:"", key:"username"}, 
        {name:"Email", orderBy:"", key:"email"}, 
        {name:"message", orderBy:"", key:"message"}, 
        {name:"Date", orderBy:"desc", key:"timestamp"}, 
    ])
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("searchStartDate", ""))
        dispatch(onChange("searchEndDate", ""))
        dispatch(onChange("search_userId", ""))
        dispatch(onChange("search_email", ""))
        dispatch(onChange("search_username", ""))
        dispatch(contactsData("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(contactsData("first", 0, lastDataSet, perPage, change, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(contactsData("", lastData, lastDataSet, perPage, head, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(contactsData("", lastData, lastDataSet, perPage, head, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(contactsData("", lastData, lastDataSet, perPage, head, type))
        }
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(contactsData("first", 0, lastDataSet, perPage, head, 0))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
