import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "../../assets/styles/Pages.module.scss"
import {ccStat } from "../../state/actions/callcenters"
import DateRange from "../../components/Items/dateRange"
import classNames from "classnames"
import TableStyle from '../../components/Items/Table.module.scss';
import firebase from "../../firebase"

const Stats = ({list}) => {
    const dispatch = useDispatch();
    const [affiliate, affiliateSet] = useState([])
    const [affiliateName, affiliateNameSet] = useState([])
    useEffect(() => {
        let affiliate = []
        list.forEach(doc=>{
            if(!doc.data[8]){
                return null
            }
            let i = affiliate.findIndex(x => x.name === doc.data[8].value)
            if(i !== -1){
                affiliate[i].totalPayment = affiliate[i].totalPayment + Number(doc.data[10].value)
                affiliate[i].users = affiliate[i].users + 1
                if(doc.data[9].value === "True"){
                    affiliate[i].verifyUsers = affiliate[i].verifyUsers + 1
                }
                if(Number(doc.data[10].value)){
                    affiliate[i].payUsers = affiliate[i].payUsers + 1
                }
            }else{
                affiliate.push({
                    name:doc.data[8].value,
                    totalPayment:Number(doc.data[10].value),
                    users:1,
                    verifyUsers: doc.data[9].value === "True" ? 1 : 0,
                    payUsers: Number(doc.data[10].value) ? 1 : 0
                })
            }
        })
        affiliateSet(affiliate)
        // eslint-disable-next-line
    }, [list]);
    return (<div>
        <div className={classNames(styles.tabContent)} style={{paddingBottom:20, paddingTop:20}}>
            <div className={TableStyle.tableContent}>
                <table className={classNames(TableStyle.table)}>
                    <thead>
                        <tr>
                            <th>Affiliate</th>
                            <th>User</th>
                            <th>Verify User</th>
                            <th>Pay User</th>
                            <th>Total Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {affiliate.map((doc,index) =>{
                            return <tr>
                            <td>{affiliateName[doc.name] ? affiliateName[doc.name] : doc.name}</td>
                            <td>{doc.users}</td>
                            <td>{doc.verifyUsers}</td>
                            <td>{doc.payUsers}</td>
                            <td>{doc.totalPayment}€</td>
                        </tr>
                        })}
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export default Stats;
