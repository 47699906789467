import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import moment from "moment"
import {defaultFakeId} from "../../Settings"
import {usersList} from "./usersList"
import ResizeImage from 'image-resize'
import { onChange } from "./form"
import { makeid } from "../../Hooks"
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import {toastr} from 'react-redux-toastr'

let unsubscribe = ""
let unsubscribe2 = ""
export const WAITING_MESSAGES = createAction('WAITING_MESSAGES');
export const TOTAL_MESSAGES = createAction('TOTAL_MESSAGES');
export const MESSAGES_START = createAction('MESSAGES_START');
export const MESSAGES_END = createAction('MESSAGES_END');
export const MESSAGES_DATA = createAction('MESSAGES_DATA');
const resize = new ResizeImage({
    format: 'jpg',
    width: 700,
    outputType: "blob"
});

const messagesStat = (type) => {
    return (dispatch, getState) => {
        if(unsubscribe){
            unsubscribe()
        }
        let date = new Date(moment().startOf('day'))
        if(type === "month"){
            date = new Date(moment().startOf('month'))
        }
        if(type === "31"){
            date = new Date(moment().subtract(31, 'days').startOf('day'))
        }
        unsubscribe = firebase.firestore().collection("messages").where("time", ">=", date).onSnapshot(doc=>{
            // let waiting = {
            //     message: 0,
            //     asa1: 0,
            //     asa2: 0,
            //     asa3: 0
            // }
            let total = {
                message: 0,
                asa1: 0,
                asa2: 0,
                asa3: 0,
                gif: 0,
                gift: 0,
                photo: 0,
                new: 0,
                automessage: 0,
                newsletter: 0,
            }
            doc.forEach(doc=>{
                if(doc.data().post.split("_")[0] === defaultFakeId && doc.data().type !== "automessage"){
                    if(doc.data().asa === "Asa1"){
                        total.asa1 += 1
                    }else if(doc.data().asa === "Asa2"){
                        total.asa2 += 1
                    }else if(doc.data().asa === "Asa3"){
                        total.asa3 += 1
                    }else if(doc.data().type === "gif"){
                        total.gif += 1
                    }else if(doc.data().type === "gift"){
                        total.gift += 1
                    }else if(doc.data().type === "automessage"){
                        total.automessage += 1
                    }else if(doc.data().type === "photo"){
                        total.photo += 1
                    }else{
                        total.message += 1
                    }
                }
            })
            // dispatch(WAITING_MESSAGES(waiting))
            dispatch(TOTAL_MESSAGES(total))
        })
    }
}
const waitingStat = (type) => {
    return (dispatch, getState) => {
        firebase.firestore().collection("messagesList").where("lastSend", "==", "user").where("reality", "==", false).where("delete", "==", false).onSnapshot(doc=>{
            let waiting = {
                waiting: 0,
                message: 0,
                asa1: 0,
                asa2: 0,
                asa3: 0
            }
            doc.forEach(doc=>{
                if(doc.data().type === "Asa1"){
                    waiting.asa1 += 1
                }else if(doc.data().type === "Asa2"){
                    waiting.asa2 += 1
                }else if(doc.data().type === "Asa3"){
                    waiting.asa3 += 1
                }else if(doc.data().type === "waiting"){
                    waiting.waiting += 1
                }else if(doc.data().type === ""){
                    waiting.message += 1
                }
            })
            dispatch(WAITING_MESSAGES(waiting))
        })
    }
}
  
const deleteMessage = (messageId) => {
    firebase.firestore().collection("messages").doc(messageId).delete()
}
const messagesData = (first, lastData, lastDataSet, perPage, head, activePage) => {
    return async (dispatch, getState) => {

        let form = getState().form
        let callcenters = getState().callcenters.list ? getState().callcenters.list : []
        dispatch(MESSAGES_START())
        let fireData = firebase.firestore().collection("messages")
        if(form.searchStartDate){
            fireData = fireData.where("time", ">=", new Date(form.searchStartDate))
        }
        if(form.searchEndDate){
            fireData = fireData.where("time", "<=", new Date(form.searchEndDate))
        }
        if (form.search_docId) {
            fireData = fireData.where("id", "==", form.search_docId);
        }
        if (form.search_post) {
            fireData = fireData.where("post", "==", form.search_post);
        }
        if (form.search_get) {
            fireData = fireData.where("get", "==", form.search_get);
        }
        if (form.search_cc) {
            fireData = fireData.where("cc", "==", form.search_cc);
        }
        if (form.search_asa) {
            fireData = fireData.where("asa", "==", form.search_asa);
        }
        head.forEach(doc=>{
            if(doc.orderBy && doc.orderBy !== "disable"){
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            } 
        })
        if(lastData && lastData[(activePage - 1)] && first === ""){
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        if(unsubscribe2){
            unsubscribe2()
        }
        unsubscribe2 = fireData.limit(perPage).onSnapshot(async doc => {
            let userList = []
            if(!doc.size){
                dispatch(MESSAGES_DATA({
                    list: userList
                }))
                dispatch(MESSAGES_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if(doc && _lastData){
                if(activePage >= lastData.length){
                    if(lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)){
                        lastData.push(_lastData)
                        lastDataSet(lastData)
                    }else if(!lastData){
                        lastDataSet([_lastData])
                    }
                }else{
                    if(lastData){
                        lastData.pop()
                        lastData.push(_lastData)
                        lastDataSet(lastData)
                    }else{
                        lastDataSet([_lastData])
                    }
                }
            }
            doc.forEach(doc=>{
                let pushArr = {}
                let ccName = ""
                if(doc.data().cc){
                    callcenters.forEach(doc2=>{
                        if(doc2.docId === doc.data().cc){
                            ccName = doc2.data[0].value
                        }
                    })
                }
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "name", value: doc.data().post },
                    { type: "name", value: doc.data().get },
                    { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
                    { type: "avatar", value: doc.data().image },
                    { type: "text", value: ccName ? ccName : doc.data().cc },
                    { type: "text", value: doc.data().asa ? doc.data().asa : doc.data().type },
                    { type: "text", value: doc.data().buy ? "true" : "" },
                    { type: "text", value: doc.data().visible ? "True" : "False" },
                    { type: "date", value:  new Date(doc.data().time.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);

                firebase.firestore().collection("users").doc(doc.data().post).get().then(doc=>{
                    dispatch(usersList(doc))
                })
                firebase.firestore().collection("users").doc(doc.data().get).get().then(doc=>{
                    dispatch(usersList(doc))
                })
            })
            dispatch(MESSAGES_DATA({
                list: userList
            }))
            dispatch(MESSAGES_END())
        })
    }
}
const messageSend = (userId) => {
    return async (dispatch, getState) => {
        let form = getState().form
        // console.log(form.message_fakeId)
        await firebase.firestore().collection("users").doc(form.message_fakeId).get().then(fakeData =>{
            firebase.firestore().collection("users").doc(userId).get().then(userData =>{
                console.log(fakeData.id)
                console.log(userData.id)
                if(!fakeData.id || !userData.id){
                    return null
                }
                firebase.firestore().collection('newsletterWaiting').add({
                    i: "",
                    u: userData.id,
                    f: form.message_fakeId,
                    d: [
                        {
                            a: userData.data().meta.avatarThumb,
                            n: userData.data().meta.nickname,
                        },
                        {
                            a: fakeData.data().meta.avatarThumb,
                            n: fakeData.data().meta.nickname,
                        }
                    ],
                    t: "1",
                    time:0,
                    m: form.message,
                    p: form.message_image ? form.message_image : "",
                    s:true
                }).then(doc4 =>{

                })
            })
        })
        
        toastr.success('Success', 'Mesaj Gonderildi.')
        // NotificationManager.success('Nachricht gesendet.', 'Erfolgreich!')
    }
}

const photoUpdate = (e) => {
    return async (dispatch, getState) => {
        // const edit_ref = getState().form.edit_ref;
        resize.play(e).then((response) => {
            let randomid = makeid(12)
            firebase.storage().ref('messages/' + randomid + '' + e.files[0].name).put(response).then(doc => {
                firebase.storage().ref('messages/' + randomid + '' + e.files[0].name).getDownloadURL().then(url => {
                    dispatch(onChange("message_image", url))
                })
            })
        })
    }
}

const photoDelete = (e) => {
    return async (dispatch, getState) => {
        dispatch(onChange("message_image", ""))
    }
}
export { photoDelete, photoUpdate, messageSend, deleteMessage,messagesData, messagesStat, waitingStat };
