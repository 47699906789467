import { createReducer } from 'redux-act';

import {
    ONLINE_USERS,
    ONLINE_LOGS_DATA,
    ONLINE_USERS_RESET
} from '../actions/online';

const initialState = {
  loading: false
};

const authReducer = createReducer(
  {
    [ONLINE_USERS_RESET]: (state, payload) => ({
      list: []

    }),
    [ONLINE_USERS]: (state, payload) => ({
      ...state,
      list: payload

    }),
    [ONLINE_LOGS_DATA]: (state, payload) => ({
      ...state,
      ...payload

    })
  },
  initialState
);

export default authReducer