import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Styles from "../../assets/styles/Pages.module.scss"
import { getWaitingNewsletter, sendAll } from "../../state/actions/newsletters"
import TableTotal from "../../components/Items/tableTotal"
import { ButtonPrimary } from "../../components/Items/buttons"
import TableHead from "./TableHead"
import Arrow from "../../assets/img/icons/arrow.svg"

const AddNewsletter = () => {
    const { loading, waiting, totalPage} = useSelector(
        (state) => ({
            loading: state.newsletters.loading,
            waiting: state.newsletters.waiting ? state.newsletters.waiting : [],
            totalPage: state.newsletters.totalPage ? state.newsletters.totalPage : 0,
        }), shallowEqual
    );
    const { id } = useParams();
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Avatar", orderBy:"disable"}, 
        {name:"Nickname", orderBy:"disable"}, 
        {name:"Status", orderBy:"disable"}, 
        {name:"Settings", orderBy:"disable"}
    ])
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getWaitingNewsletter(id))
        // eslint-disable-next-line
    }, []);

    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(getWaitingNewsletter(id - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(getWaitingNewsletter(id + 1))
        }else{
            activePageSet(type)
            dispatch(getWaitingNewsletter(id))
        }
    }

    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(getWaitingNewsletter(id))
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.headerBottom}>
                <div className={Styles.left}>
                    <NavLink to="/newsletters">
                        Newsletters
                    </NavLink>
                    <img className={Styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={Styles.right}>
                    <ButtonPrimary onClick={(e) => dispatch(sendAll(id))} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text={"Send All"} />
                </div>
            </div>
            <div className={Styles.contentWhite} style={{ paddingTop:20, marginBottom:34 }}>
               
                {/* <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(getWaitingNewsletter(id, 0, lastDataSet, perPage, head, false, 0))}} /> */}
                {/* buttonType="newsletter" */}
                <TableTotal class={"type_2"} head={head} lastData={lastData} activePage={activePage} totalPage={totalPage} changePage={changePage} headSetFunc={headSetFunc} body={waiting} buttonType="waiting" />
                {/* <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={waiting} buttonType="waiting" /> */}
                {/* <TableHead3  onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(getWaitingNewsletter(id, "first", 0, lastDataSet, perPage, head, false, 0))}}/>
                {searchListUsers.length ? <Table  class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={searchListUsers} buttonType="addNewsletterUsers" /> : ""} */}
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default AddNewsletter;
