import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary, ButtonPrimaryOutline} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import DateRange from "../../components/Items/dateRange"
import {FormattedMessage, useIntl} from 'react-intl';

const Search = (props) => {
    const intl = useIntl();
    const { packets } = useSelector(
        (state) => ({
            packets: state.general.packets ? state.general.packets : []
        }), shallowEqual
    );
    let arr = []
    packets.forEach(doc2=>{
        arr.push({label: doc2.title, value: doc2.id})
    })
    
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_id" type="text" placeholder="ID" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_nickname" size="12" type="text" placeholder={intl.formatMessage({id:"Dashboard.nickname"})} bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_user_id" type="text" placeholder="User ID"  size="12" />
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_4" name="search_paytype" items={[
                    { label: "Paypal", value: "paypal" },
                    { label: "Kreditkarte", value: "creditcard" },
                    { label: "Sofort-Überweisung", value: "directbanking" },
                    { label: "Paysafecard", value: "paysafecard" },
                    { label: "Vorkasse", value: "prepayment" },
                ]} bar="false" />
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_4" name="search_packet" items={arr} bar="false" />
                {/* <Input style={{marginBottom:0}} styletype="type_2" name="search_packet" type="text" placeholder="Packet"  size="12" /> */}
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_function" type="text" placeholder="Result"  size="12" />
            </li>
            <li>
                <DateRange name1="searchStartDate" name2="searchEndDate" styletype="type_2" />
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
            <li>
                <ButtonPrimaryOutline onClick={props.onsearchtotal} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={"Total"} />
            </li>
        </ul>
    </div>
  )
}

export default Search;
