
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import moment from "moment"
import { publicIpv4 } from 'public-ip';
import firebase_app from 'firebase/compat/app';
import { usersList } from "./usersList"
const oldRealTimeDb = firebase.database();


export const LOGS_START = createAction('LOGS_START');
export const LOGS_END = createAction('LOGS_END');
export const LOGS_WEB_STAT = createAction('LOGS_WEB_STAT');
export const LOGS_DEVICE_STAT = createAction('LOGS_DEVICE_STAT');
export const LOGS_DATA = createAction('LOGS_DATA');

export const logstStat = (type) => {

    // {web: 1926, iphone: 3173, ipad: 315, android: 3792}android: 3792ipad: 315iphone: 3173web: 1926}
    return async (dispatch, getState) => {
        dispatch(LOGS_START())
        let form = getState().form
        let startDate = new Date(moment().startOf('day'))
        let endDate = new Date(moment().endOf('day'))
        if (type && form.startDate2 && form.endDate2) {
            startDate = new Date(moment(form.startDate2).startOf('day'))
            endDate = new Date(moment(form.endDate2).endOf('day'))
        }



        const device = getState().logs.device;
        if (!type && device && device.web) {
            dispatch(LOGS_END())
            return null
        }
        let fire = firebase.firestore().collection("logs")
        if (type && form.startDate2 && form.endDate2) {
            fire = fire.where("t", "<=", endDate)
        }
        fire.where("t", ">=", startDate).get().then(doc => {
            let arr = {
                web: 0,
                iphone: 0,
                android: 0
            }
            let arr2 = []
            doc.forEach(doc => {
                if (doc.data().d === "iPhone" || doc.data().d === "iPad") {
                    arr.iphone += 1
                } else if (doc.data().d === "") {
                    arr.web += 1
                } else {
                    arr.android += 1
                }


                let browser = doc.data().b.split("-")[0]
                browser = browser.toString().replace(/\s+$/, '')

                if (arr2.findIndex(x => x.x === browser) !== -1) {
                    arr2[arr2.findIndex(x => x.x === browser)].y = Number(arr2[arr2.findIndex(x => x.x === browser)].y) + 1
                } else {
                    arr2.push({
                        x: browser,
                        y: 1
                    });
                }
                // arr2[browser] =  arr2[browser] ? arr2[browser] + 1 : 1
            })
            // series_month.push({
            //     x: time,
            //     y: doc.data().pay2
            // });
            let arr3 = [];
            arr2.forEach(doc => {
                if (doc.y > 10) {
                    arr3.push({
                        x: doc.x,
                        y: doc.y
                    })
                }
            })
            dispatch(LOGS_DEVICE_STAT(arr))
            dispatch(LOGS_WEB_STAT(arr3))
        })
    }
}

export const logsData2 = (size) => {
    return (dispatch, getState) => {
        try {
            publicIpv4().then(ipAddress => {
                var __url_string = window.location.href;
                var __url = new URL(__url_string);
                let ref = __url.searchParams.get("ref") ? __url.searchParams.get("ref") : ""
                const userData = getState().auth.userData;
                let b = "";
                // if (visitorInfo().browser.name && visitorInfo().browser.version) {
                //     b = visitorInfo().browser.name + " - " + visitorInfo().browser.version
                // }
                // let o = ""
                // if (visitorInfo().os.name && visitorInfo().os.version) {
                //     o = visitorInfo().os.name + " - " + visitorInfo().os.version
                // }
                firebase.firestore().collection('logs').add({
                    i: ipAddress,
                    b: b,
                    // c: visitorInfo().country.alpha3 ? visitorInfo().country.alpha3 : "",
                    // d: visitorInfo().device.model ? visitorInfo().device.model : "",
                    // o: o,
                    t: firebase_app.firestore.FieldValue.serverTimestamp(),
                    ref: ref,
                    u: userData.docId ? userData.docId : "",
                    r: "admin"
                }).then(logId => {
                    // dispatch(LOG_ID({ id: logId.id, userId: userData.docId ? userData.docId : "" }))
                    oldRealTimeDb.ref('.info/connected').on('value', snapshot => {
                        oldRealTimeDb.ref(`/online/${logId.id}`).onDisconnect().remove().then(() => {
                            oldRealTimeDb.ref(`/online/${logId.id}`).set(logId.id);
                        })
                    })
                }).catch(e => {
                    console.log(e)
                })


            })
        } catch (error) {

        }
        firebase.analytics();
        // var x = document.referrer;
        // firebase.firestore().collection('favs').where("a", "==", docId).orderBy("t", "desc").onSnapshot(doc => {
        //     let arr = []
        //     doc.forEach(doc => {
        //         arr.push(doc.data().b)
        //     })
        //     if (arr.length) {
        //         dispatch(FAVS_LIST(arr))
        //     }
        // })
    };
};
export const logsData = (first, lastData, lastDataSet, perPage, head, activePage) => {
    return async (dispatch, getState) => {
        let form = getState().form
        dispatch(LOGS_START())
        let fireData = firebase.firestore().collection("logs")
        if (form.searchStartDate) {
            fireData = fireData.where("t", ">=", new Date(form.searchStartDate))
        }
        if (form.searchEndDate) {
            fireData = fireData.where("t", "<=", new Date(form.searchEndDate))
        }
        if (form.search_u) {
            fireData = fireData.where("u", "==", form.search_u);
        }
        if (form.search_ip) {
            fireData = fireData.where("i", "==", form.search_ip);
        }
        if (form.search_ref) {
            fireData = fireData.where("ref", "==", form.search_ref);
        }
        if (form.search_d) {
            fireData = fireData.where("d", "==", form.search_o);
        }
        if (form.search_r) {
            fireData = fireData.where("r", "==", form.search_r);
        }
        head.forEach(doc => {
            if (doc.orderBy && doc.orderBy !== "disable") {
                fireData = fireData.orderBy(doc.key, doc.orderBy)
            }
        })
        if (lastData && lastData[(activePage - 1)] && first === "") {
            fireData = fireData.startAfter(lastData[(activePage - 1)])
        }
        fireData.limit(perPage).get().then(async doc => {
            let userList = []
            if (!doc.size) {
                dispatch(LOGS_DATA({
                    list: userList
                }))
                dispatch(LOGS_END())
                return null
            }
            let _lastData = doc.docs[doc.docs.length - 1]
            if (doc && _lastData) {
                if (lastData && (lastData.findIndex(x => x.id === _lastData.id) === -1)) {
                    lastData.push(_lastData)
                    lastDataSet(lastData)
                } else if (!lastData) {
                    lastDataSet([_lastData])
                }
            }
            doc.forEach(doc => {
                let pushArr = {}
                pushArr["docId"] = doc.id
                pushArr["data"] = [
                    { type: "name", value: doc.data().u, sub: doc.data().u },
                    { type: "text", value: doc.data().i },
                    { type: "text", value: doc.data().ref },
                    { type: "text", value: doc.data().d },
                    { type: "text", value: doc.data().r },
                    { type: "date", value: new Date(doc.data().t.toDate()).toLocaleString() },
                ]
                userList.push(pushArr);
                if (doc.data().u) {
                    firebase.firestore().collection("users").doc(doc.data().u).get().then(doc => {
                        dispatch(usersList(doc))
                    })
                }
            })
            dispatch(LOGS_DATA({
                list: userList
            }))
            dispatch(LOGS_END())
        })
    }
}