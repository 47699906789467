import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import DateRange from "../../components/Items/dateRange"
import {FormattedMessage, useIntl} from 'react-intl';

const Search = (props) => {
    const intl = useIntl();
    const { packets } = useSelector(
        (state) => ({
            packets: state.general.packets ? state.general.packets : []
        }), shallowEqual
    );
    let arr = []
    packets.forEach(doc2=>{
        arr.push({label: doc2.title, value: doc2.id})
    })
    
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_userId" type="text" placeholder="USER ID" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_email" size="12" type="text" placeholder="Email" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_username" size="12" type="text" placeholder="Username" bar="false"/>
            </li>
            <li>
                <DateRange name1="searchStartDate" name2="searchEndDate" styletype="type_2" />
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
        </ul>
    </div>
  )
}

export default Search;
