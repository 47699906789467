const path =  {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  AFFILIATE_SYSTEM: '/affiliate-system',
  ADMINS: '/admins',
  AUTO_MESSAGES: '/auto-messages',
  AUTO_MESSAGES_ADD: '/auto-messages/add',
  AUTO_MESSAGES_SEND: '/auto-messages/add/:id',
  PAYMENTS: '/payments',
  ADMINS_ADD: '/admins/add',
  NEWSLETTERS: '/newsletters',
  NEWSLETTERS_ADD: '/newsletters/add-newsletter',
  NEWSLETTER_WAITING: '/newsletters/waiting/:id',
  NEWSLETTERS_ADD_2: '/newsletters/add-newsletter/:id',
  USERS_EDIT: '/users/edit/:id',
  USERS_DELETED_EDIT: '/users-deleted/edit/:id',
  FAKES_EDIT: '/fakes/edit/:id',
  FAKES_ADD: '/fakes/add',
  AFFIRMATIONS: '/affirmations',
  AFFILIATE_SYSTEM_ADD: '/affiliate-system/add',
  AFFILIATE_SYSTEM_EDIT: '/affiliate-system/edit/:id',
  CALLCENTERS_EDIT: '/callcenters/edit/:id',
  NEWSLETTERS_ADD_2: '/newsletters/add-newsletter/:id',
  CALLCENTERS_ADD: '/callcenters/add',
  ADMINS_EDIT: '/admins/edit/:id',
  NEWSLETTERS_ADD_3: '/newsletters/add-newsletter/last-step/:id',
  USERS: '/users',
  FAKES: '/fakes',
  CALLCENTERS: '/callcenters',
  MESSAGES: '/messages',
  MESSAGES_IMAGES: '/messages_image',
  MESSAGES_SEND: '/messages/send/:id',
  LOGS: '/logs',
  CONTACTS: '/contacts',
  SETTINGS: '/settings',
  USERS_DELETED: '/users-deleted',
};
export default path