import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styles from './MessageBox_2.module.scss';
import classnames from 'classnames';
import TimeAgo from 'javascript-time-ago'
import { NavLink } from 'react-router-dom';
import en from 'javascript-time-ago/locale/en'
import Modal from "../../Items/modal"
import Dots from "../../../assets/img/dot.png"
import {userId_S} from "../../../Settings"
import {FormattedMessage} from 'react-intl';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const MessageBox = (props) => {
    const { users, gifts} = useSelector(
        (state) => ({
            users: state.usersList,
            gifts: state.general.gifts ? state.general.gifts : [],
        }), shallowEqual
    );

    const [modal_Image, modal_ImageStatus] = useState("");

    useEffect(() => {
        if(document.getElementById("lastMessage")){
            let obj = document.getElementById("lastMessage")
            obj.scrollIntoView()
            setTimeout(() => {
                obj.scrollIntoView() 
            }, 500);
            setTimeout(() => {
                obj.scrollIntoView() 
            }, 1000);
        }
        // eslint-disable-next-line
    }, [props.messageList]);
    if(!users[props.fakeId]){
        return null
    }
    let secondUserId = props.fakeId
    let avatar = users[props.fakeId].avatar
    let userLink = "/users/" + userId_S(secondUserId)
    return (
        <div className={classnames(styles.messageBox_2)}>
            <header className={classnames(styles.header)} style={{ backgroundImage: "url(" + avatar + ")" }}>
                <div className={styles.content} style={{backgroundImage:"url("+Dots+")"}}>
                    <NavLink to={{ pathname: userLink }} className={styles.avatar} style={{ backgroundImage: "url(" + avatar + ")" }}>
                        <div className={styles.overlay}>
                        </div>
                    </NavLink>
                    <div className={styles.text}>
                        <b className={styles.nickname}>
                            <NavLink to={{ pathname: userLink }}>
                                {users[props.fakeId].nickname}
                            </NavLink>
                        </b>
                    </div>
                </div>
            </header>
            <div className={styles.messageContent} id="message">
                {props.messageList.length === 50 ? <div style={{ width: "100%", textAlign: "center" }}>
                    <button style={{ border: 0, background: "rgb(243, 168, 86)", margin: "auto", color: "#fff", fontWeight: 600, padding: "8px 12px", borderRadius: 50, fontSize: 12, marginBottom: 20 }}><FormattedMessage id="General.digerMesajlar" /></button>
                </div> : ""}
                <ul id="props.General">
                    {props.messageList.map((post, index) => {
                        if (post.image) {
                            return <li id={props.messageList.length === (index+1) ? "lastMessage" : ""} key={index} className={post.post === props.userId ? styles.messageRight : styles.messageLeft}>
                                {post.type === "gift" ? <div>
                                    <img src={gifts[gifts.findIndex(function(item, i){ return item.id === Number(post.image) })] ? gifts[gifts.findIndex(function(item, i){ return item.id === Number(post.image) })].img : ""} alt="gift" />
                                </div>: 
                                <div>
                                    <p className={post.post === props.userId ? styles.post : styles.get}>
                                        <img src={post.image} onClick={(e) => modal_ImageStatus(post.image)} style={{ minWidth: "150px", maxHeight: "350px", cursor: "pointer" }} alt="" />
                                        <br />
                                        {post.message}
                                        <small className={styles.time}>{post.time ? timeAgo.format(new Date(post.time.seconds * 1000), 'twitter') : ""}</small>
                                    </p>
                                </div>}
                            </li>
                        }else if(post.type === "gif"){
                            return <li id={props.messageList.length ===  (index+1) ? "lastMessage" : ""} key={index} className={post.post === props.userId ? styles.messageRight : styles.messageLeft}>
                                 <img src={post.message} alt="gif" />
                            </li>
                        }
                        return <li id={props.messageList.length ===  (index+1) ? "lastMessage" : ""} key={index} className={post.post === props.userId ? styles.messageRight : styles.messageLeft}>
                            <div>
                                <p className={post.post === props.userId ? styles.post : styles.get}>
                                    {post.message}
                                    <small className={styles.time}>
                                        {post.time ? timeAgo.format(new Date(post.time.seconds * 1000), 'twitter') : ""}
                                    </small>
                                </p>

                            </div>
                        </li>
                    })}
                </ul>
            </div>

            {modal_Image ?<Modal title={<FormattedMessage id="General.fotograf" />} content={<img src={modal_Image} alt="" />} status={modal_Image} width={700} close={(e) => modal_ImageStatus("")} /> : ""}
        </div>)
}
export default MessageBox;
