import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary, ButtonPrimaryOutline} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import DateRange from "../../components/Items/dateRange"
import {FormattedMessage, useIntl} from 'react-intl';

const Search = (props) => {
    const intl = useIntl();
    const { packets } = useSelector(
        (state) => ({
            packets: state.general.packets ? state.general.packets : []
        }), shallowEqual
    );
    let arr = []
    packets.forEach(doc2=>{
        arr.push({label: doc2.title, value: doc2.id})
    })
    
  return(
    <div className={Styles.DashboardFiltre}>
        <ul>

        {/* if (form.search_docId) {
            fireData = fireData.where("id", "==", form.search_docId.toLowerCase());
        }
        if (form.search_post) {
            fireData = fireData.where("post", "==", form.search_post.toLowerCase());
        }
        if (form.search_get) {
            fireData = fireData.where("get", "==", form.search_get.toLowerCase());
        }
        if (form.search_cc) {
            fireData = fireData.where("cc", "==", form.search_cc.toLowerCase());
        }
        if (form.search_asa) {
            fireData = fireData.where("asa", "==", form.search_asa.toLowerCase());
        } */}
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_docId" type="text" placeholder="ID" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_post" size="12" type="text" placeholder="Post" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_get" size="12" type="text" placeholder="Get" bar="false"/>
            </li>
            <li>
                <Input style={{marginBottom:0}} styletype="type_2" name="search_cc" size="12" type="text" placeholder="CC" bar="false"/>
            </li>
            <li>
                <Select style={{marginBottom:0}} styletype="type_2" name="search_asa" placeholder="Asa" items={[{label:"Asa 1", value:"Asa1"}, {label:"Asa 2", value:"Asa2"}, {label:"Asa 3", value:"Asa3"}]} bar="false" />
            </li>
            <li>
                <DateRange name1="searchStartDate" name2="searchEndDate" styletype="type_2" />
            </li>
            <li>
                <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
            </li>
            <li>
                <ButtonPrimaryOutline onClick={props.onsearchtotal} style={{ borderRadius: 5, height: 42, width: "100%", textTransform:"capitalize",flex: "none", marginBottom:0 }}   text={"Total"} />
            </li>
        </ul>
    </div>
  )
}

export default Search;
