import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { Data} from "../../state/actions/auto_messages"
import Table from "../../components/Items/table"
import { NavLink } from 'react-router-dom';
import {onChange} from "../../state/actions/form"
import { ButtonPrimary } from "../../components/Items/buttons"

const Users = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.auto_messages.loading,
            list: state.auto_messages && state.auto_messages.list ? state.auto_messages.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    // { type: "bold", value: doc.data().fakeId },
    // { type: "textAlt", value: doc.data().message.substring(0, 100) + "...", fullValue: doc.data().message },
    // { type: "avatar", value: doc.data().photo },
    // { type: "badge", value: doc.data().time, class: "success" },
    // { type: "date", value: new Date(doc.data().timestamp.toDate()).toLocaleString() },
    const [head, headSet] = useState([
        {name:"Fake", orderBy:"disable", key:""}, 
        {name:"Message", orderBy:"disable", key:""}, 
        {name:"Photo", orderBy:"disable", key:""}, 
        {name:"Photo 2", orderBy:"disable", key:""}, 
        {name:"Time", orderBy:"disable", key:""}, 
        {name:"Type", orderBy:"disable", key:""},
        {name:"Date", orderBy:"desc", key:"date"},
        {name:"Settings", orderBy:"disable", key:""}
    ])
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(Data("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(Data("first", 0, lastDataSet, perPage, change, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(Data("", lastData, lastDataSet, perPage, head, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(Data("", lastData, lastDataSet, perPage, head, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(Data("", lastData, lastDataSet, perPage, head, type))
        }
    }
    return (
        <section className={Styles.Pages}>
        <div className={Styles.headerBottom}>
            <div className="left">
            </div>
            <div className="right">
                <NavLink to="/auto-messages/add">
                    <ButtonPrimary style={{height:38, borderRadius:5, fontWeight:500, fontSize:13}} text={"Add Auto Messages"} />
                </NavLink>
            </div>
        </div>
            <div className={Styles.contentWhite}>
            <br/>
                {/* <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(Data("first", 0, lastDataSet, perPage, head, 0))}} /> */}
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="auto_messages" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;



// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { NavLink } from 'react-router-dom';
// import Styles from "./AutoMessages.module.scss"
// // import { ButtonMessage} from "../../components/Items/buttons"
// //import { getData } from "../../state/actions/contacts"
// import Table from "../../components/Items/table"
// import { ButtonPrimary } from "../../components/Items/buttons"
// import { getData } from "../../state/actions/autoMessages"

// const AutoMessages = () => {
//     const { loading, list, form, prize, info, faq, site } = useSelector(
//         (state) => ({
//             loading: state.dashboard.loading,
//             list: state.autoMessages && state.autoMessages.list ? state.autoMessages.list : [],
//             form: state.form,
//         }), shallowEqual
//     );
//     const dispatch = useDispatch();
//     const [tabs, setTabs] = useState(0);
//     useEffect(() => {
//         window.scrollTo(0, 0);
//         dispatch(getData("first"))
//         const windowScroll = (event) => {
//             if (!loading) {
//                 if (window.innerHeight + window.scrollY > document.body.clientHeight - 200) {
//                     if (form.searchNickname) {
//                     } else {
//                         dispatch(getData())
//                     }
//                 }
//             }
//         }
//         window.addEventListener('scroll', windowScroll);

//         // cleanup this component
//         return () => {
//             window.removeEventListener('scroll', windowScroll);
//         };
//         // eslint-disable-next-line
//     }, [dispatch]);
//     return (
//         <section className={Styles.AutoMessages}>
//             <div className="headerBottom">
//                 <div className="left">
//                     <h1>Auto Messages</h1>
//                 </div>
//                 <div className="right">
//                     <NavLink to="/auto-messages/add"><ButtonPrimary style={{ height: 38, fontSize: 13 }} text="Add Auto Message" /></NavLink>
//                 </div>
//             </div>
//             <div className={Styles.contentWhite}>
//                 <div className={Styles.content}>
//                     <Table class={"type_2"} head={["Avatar", "Fake", "Message", "Image", "Time", "Create Date"]} body={list} />
//                 </div>
//                 {loading ?<div className="loading"></div> : ""}
//             </div>
//         </section>
//     )
// }

// export default AutoMessages;
