import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { messagesData} from "../../state/actions/messages"
import Table from "../../components/Items/table"
import TableHead from "./TableHead"
import {onChange} from "../../state/actions/form"

const Users = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.messages.loading,
            list: state.messages && state.messages.list ? state.messages.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Post", orderBy:"", key:"post"}, 
        {name:"Get", orderBy:"", key:"get"}, 
        {name:"Message", orderBy:"", key:"message"}, 
        {name:"Image", orderBy:"disable", key:"image"}, 
        {name:"Callcenter", orderBy:"", key:"cc"}, 
        {name:"Type", orderBy:"", key:"asa"}, 
        {name:"Buy", orderBy:"", key:"buy"}, 
        {name:"Visible", orderBy:"", key:"visible"}, 
        {name:"Date", orderBy:"desc", key:"time"}, 
        {name:"Settings", orderBy:"disable", key:""}
    ])

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("searchStartDate", ""))
        dispatch(onChange("searchEndDate", ""))
        dispatch(onChange("search_docId", ""))
        dispatch(onChange("search_post", ""))
        dispatch(onChange("search_get", ""))
        dispatch(onChange("search_cc", ""))
        dispatch(onChange("search_asa", ""))
        dispatch(messagesData("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(messagesData("first", 0, lastDataSet, perPage, change, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(messagesData("", lastData, lastDataSet, perPage, head, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(messagesData("", lastData, lastDataSet, perPage, head, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(messagesData("", lastData, lastDataSet, perPage, head, type))
        }
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(messagesData("first", 0, lastDataSet, perPage, head, 0))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="messages" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
