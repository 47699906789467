import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import { getUserDetail, saveUser } from "../../state/actions/admins"
import { ButtonPrimary } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import EditDetail from "./EditDetail"


const EditAdmins = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getUserDetail(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (
        <section className={classNames(styles.Pages, styles.edit)}>

            <div className={styles.headerBottom}>
                <div className={styles.left}>
                    <NavLink to="/admins">
                        Admins
                    </NavLink>
                    <img className={styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={styles.right}>
                    <NavLink to={"/admins"}>
                        Back
                    </NavLink>
                    <ButtonPrimary onClick={(e) => dispatch(saveUser(id))} style={{height:38, borderRadius:5, fontWeight:500, fontSize:13, marginLeft:15}} text="Save" />
                </div>
            </div>

            <div className={styles.row}>
                <div className={classNames(styles.container, styles.full)}>
                <EditDetail/> 
                </div>
            </div>
        </section>
    )
}

export default EditAdmins;
