import React from 'react';

export const defaultLAvatar = "https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/noavatar.jpeg?alt=media&token=6b537808-be3e-43d6-bba9-cfe7f56e0361";
export const defaultLandingBg = "https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/defaultLandingBg.jpg?alt=media&token=e3270a3f-a975-49ce-a765-ff938501f5c7";
export const defaultLandingImg = "https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/login.png?alt=media&token=d470507f-eb02-4f95-9945-c8b8d0c15f07";
export const defaultVersion = "v6.1.4";
export const defaultProject = "beepr-de";
export const defaultName = "Beepr";
export const defaultTemplate = "3";
export const TenorKey = "VI0C0AMTC9K0";
export const defaultCurrency = "€";
export const defaultHomeTemplate = "2";
export const defaultLanguage = "de";
export const defaultCountry = "de";
export const template2Type = "";
export const defaultFakeId = "fake";
export const defaultTitle = "Beepr";
export const defaultMicropaymentLink = "micropayment.de";
export const defaultMail = "info@beepr.de";
export const defaultLink = "https://beepr.de";

export const defaultColors ={
  white1: "#f1f1f1",
  gray1: "#F2F4F4",
  gray2: "#DFE3E7",
  gray3: "#9997a2",
  gray4: "#d4d5d9",
  gray5: "#828D99",
  blue: "#5A8DEE",
  indigo: "#6610F2",
  purple: "#6F42C1",
  pink: "#E83E8C",
  red: "#FF5B5C",
  orange: "#FD7E14",
  yellow: "#FDAC41",
  green: "#39DA8A",
  teal: "#20C997",
  cyan: "#00CFDD",
  white: "#FFFFFF",
  whiteBg: "#FFFFFF",
  gray: "#475F7B",
  grayDark: "#394C62",
  primary: "#5A8DEE",
  primaryDark: "#719DF0",
  secondary: "#475F7B",
  secondaryDark: "#394C62",
  success: "#39DA8A",
  successDark: "#25c977",
  info: "#00CFDD",
  warning: "#FDAC41",
  danger: "#FF5B5C",
  light: "#A3AFBD",
  dark: "#394C62",
 };
 export const defaultColorsDark ={
  white1: "#333c5d",
  gray1: "#1a233a",
  gray2: "#464d5c",
  gray3: "#8A99B5",
  gray4: "#464D5C",
  gray5: "#fff",
  blue: "#5A8DEE",
  indigo: "#6610F2",
  purple: "#6F42C1",
  pink: "#E83E8C",
  red: "#FF5B5C",
  orange: "#FD7E14",
  yellow: "#FDAC41",
  green: "#39DA8A",
  teal: "#20C997",
  cyan: "#00CFDD",
  white: "#FFFFFF",
  whiteBg: "#272e48",
  gray: "#BDD1F8",
  grayDark: "#394C62",
  primary: "#5A8DEE",
  primaryDark: "#719DF0",
  secondary: "#475F7B",
  secondaryDark: "#394C62",
  success: "#39DA8A",
  successDark: "#25c977",
  info: "#00CFDD",
  warning: "#FDAC41",
  danger: "#FF5B5C",
  light: "#A3AFBD",
  dark: "#394C62",
 };
export const defaultVip = true;
export const defaultNewsletterOptions = [{name:"An Alle", id:"0"}, {name:"Mitglieder ohne Coin", id:"1"}, {name:"Mitglieder die bisher keine Coins gekauft haben", id:"2"}, {name:"X Coin > User Coin", id:"3"}, {name:"Ort", id:"4"}, {name:"die keine Nachrichten schreiben", id:"5"}, {name:"One User", id:"6"}, {name:"Only Age X", id:"7"}, {name:"Only Google Android", id:"8"}, {name:"Only X Ref", id:"9"}];
export const defaultNewsletterHead = [{name:"Avatar", orderBy:"disable"}, {name:"Nickname", orderBy:"", key:"fakeNickneme"}, {name:"Message", orderBy:"", key:"message"}, {name:"Type", orderBy:"", key:"setting", options:defaultNewsletterOptions}, {name:"Time", orderBy:"desc", key:"timestamp"}, {name:"Users", orderBy:"", key:"totalUser"}, {name:"Status", orderBy:"", key:"status"}, {name:"Settings", orderBy:"disable"}];
export const defaultPaysafecard = true;
export const defaultVorkasse = true;
export const defaultSocial = true;
export const defaultApplink = false;
export const defaultLogoLink = "https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/beepr.svg?alt=media&token=185f5e22-aa53-4439-80ca-ca8225037620";
export const defaultLogoIconLink = "https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/icon.png?alt=media&token=3a33db6c-6195-448f-93e1-f4eee2abfbe7";
export const defaultLogo = <img style={{height:46}} src="https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/beepr.svg?alt=media&token=185f5e22-aa53-4439-80ca-ca8225037620" alt="" />;
export const defaultLogoWhite = <img style={{height:140}} src="https://firebasestorage.googleapis.com/v0/b/beepr-de.appspot.com/o/beeprWhite.svg?alt=media&token=fc0119ce-78cf-43d2-8311-8707b51bda47" alt="" />;
export const defaultPayments = [
        { title: "Paypal", img: require('./assets/img/paypal.png'), value: "paypal" },
        { title: "Kreditkarte", img: require('./assets/img/visa.png'), value: "creditcard" },
        { title: "Sofort-Überweisung", img: require('./assets/img/sofort.png'), value: "directbanking" },
        { title: "Paysafecard", img: require('./assets/img/paysafecard.png'), value: "paysafecard" },
        { title: "Vorkasse", img: require('./assets/img/vorkasse.png'), value: "prepayment" },
    ];
export const defaultVucutYapisi = ["Schlank", "Normal", "Athletisch", "Muskulös", "Mollig", "Rundlich"]
export const defaultSport = ["Fitness", "Fußball", "Golf", "Gymnastik", "Hockey", "Reiten", "Jazz", "Joggen", "Schwimmen", "Volleyball", "Darts", "Tanzen","Tennis","Yoga","Andere"]
export const defaultMusic = ["Hip-Hop", "Country", "Rock", "Classic", "Dance", "House", "Jazz", "Pop", "Rap", "Hard Rock", "Soul", "Techno","Metal ","Andere"]
export const defaultFilm = ["Action", "Fantasy", "Drama", "Animation", "Comedy", "Anime", "Horror", "Thriller", "Andere"]
export const defaultActivite = ["Kino", "Konzerte", "Karaoke", "Events", "Bar", "Disco", "Restaurant", "Theater", "Andere"]
export const defaultBody = ["Schlank", "Normal", "Athletisch", "Muskolös", "Mollig"]
export const defaultEyeColor = ["Blau", "Braun", "Grün", "Grau", "Andere"]
export const defaultHairColor = ["Blond", "Schwarz", "Braun", "Rot", "Grau", "Weiß", "Andere"]
export const defaultStyle = ["Sportlich", "Klassich", "Lässig", "Alternativ"]
export const defaultExtra = ["Tattoo", "Piercing", "Beides", "Keins"]
export const defaultMeslek = ["Angestellte/r", "Selbstständige/r", "Student/in", "Rentner/in", "Offizier/in", "Auszubildende/r", "Beamte/r", "Hausfrau/mann", "Arbeitssuchend"]
export const defaultMezuniyet = ["Universität / Hochschule", "Gymnasium", "Realschule", "Berufsschule", "kein Abschluss"]
export const defaultIliskiDurumu = ["Single", "In einer Beziehung", "Verheiratet", "Verwitwet", "Geschieden", "Romanze", "Offene Beziehung", "Es ist Kompliziert"]
export const defaultCocuklar = ["Ja", "Nein"]
export const defaultRelation = ["Feste Beziehung", "Abenteuer", "Affäre", "Flirt", "Freundschaft"]
export const defaultLanguages = ['Deutsch', 'Englisch', 'Französisch', 'Spanisch', 'Italienisch', 'Polnisch', 'Andere']
export const defaultHobs = ["Lesen", "Reisen", "Musik hören", "Kochen", "Schwimmen", "Joggen", "Fitnessstudio", "Reiten", "Kino", "Wandern", "Extremsport", "Fernsehen", "Computerspiele", "Sport", "Fotografie", "Gesellschaftspiele", "Backen", "Sammeln", "Angeln", "Camping", "Karaoke", "Frag mich"];
export const userId_S = (id) => {
  return id.replace("fake", "user");
}
export const userId_2_S = (id) => {
  return id.replace("user", "fake");
}

export const emojiList = ["😀","😁","😂","🤣","😃","😄","😅","😆","😉","😊","😋","😎","😍","😘","😗","😙","😚","🙂","🤗","🤩","🤔","🤨","😐","😑","😶","🙄","😏","😣","😥","😮","🤐","😯","😪","😫","😴","😌","😛","😜","😝","🤤","😒","😓","😔","😕","🙃","🤑","😲","☹️","🙁","😖","😞","😟","😤","😢","😭","😦","😧","😨","😩","🤯","😬","😰","😱","😳","🤪","😵","😡","😠","🤬","😷","🤒","🤕","🤢","🤮","🤧","😇","🤠","🤡","🤥","🤫","🤭","🧐","🤓","😈","👿","👹","👺","💀","☠️","👻","👽","👾","🤖","💩","😺","😸","😹","😻","😼","😽","🙀","😿","😾","🙈","🙉","🙊","😀","😁","😂","🤣","😃","😄","😅","😆","😉","😊","😋","😎","😍","😘","😗","😙","😚","🙂","🤗","🤩","🤔","🤨","😐","😑","😶","🙄","😏","😣","😥","😮","🤐","😯","😪","😫","😴","😌","😛","😜","😝","🤤","😒","😓","😔","😕","🙃","🤑","😲","☹️","🙁","😖","😞","😟","😤","😢","😭","😦","😧","😨","😩","🤯","😬","😰","😱","😳","🤪","😵","😡","😠","🤬","😷","🤒","🤕","🤢","🤮","🤧","😇","🤠","🤡","🤥","🤫","🤭","🧐","🤓","😈","👿","👹","👺","💀","☠️","👻","👽","👾","🤖","💩","😺","😸","😹","😻","😼","😽","🙀","😿","😾","🙈","🙉","🙊"]