import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import styles from './Style.module.scss';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Switch from '../../components/Items/switch2';
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')
const Index = () => {
  const { list, online, form, userData} = useSelector(
    (state) => ({
        list: state.online.list ? state.online.list : [],
        online: state.online,
        userData: state.auth.userData,
        form:state.form
    }), shallowEqual
  );


  const dispatch = useDispatch();
  useEffect(() => {
  }, [dispatch]);
  let user = 0
  let fake = 0
  list.forEach(doc=>{

    if(online[doc]){
        if(userData.role === 2 && online[doc].ref === userData.ref){
            if(online[doc].nickname){
                user++
            }else{
                fake++
            }
        }else if(userData.role === 1){
            if(online[doc].nickname){
                user++
            }else{
                fake++
            }
        }
    }
  })
  return (
    <div id="onlineUsers" className={classNames(styles.onlineUsers, styles.box)}>
        <div className={styles.iconHeader}>

            <div className={styles.icon}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 460.8 460.8">
                        <path fill="" d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641
                            S296.261,0,230.432,0z"/>
                        <path fill="" d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784
                            c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555
                            c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943
                            c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167
                            c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02
                            c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898
                            C436.8,341.682,437.322,338.024,435.755,334.89z"/>
                </svg>
            </div>
            <p>Online Users</p>
            <b>{form.onlineUsers === "Users" ? user : form.onlineUsers === "Visitors" ? fake : user + fake}</b>
            <Switch items={["All", "Users", "Visitors"]} name="onlineUsers" style={{marginBottom:5, marginTop:5}} />
        </div>
        {/* <div className={styles.hr}></div> */}
        <div className={classNames(styles.listContent)}>
        <ul className={classNames(styles.list, styles.px)}>
            {list.map((doc, index) =>{
                if(!online[doc]){
                    return null
                }
                if(online[doc].nickname){
                    if(form.onlineUsers === "Visitors"){
                        return null
                    }
                }else{
                    if(form.onlineUsers === "Users"){
                        return null
                    }
                }
                if(userData.role === 2 && online[doc].ref !== userData.ref){
                    return null
                }
                return <li key={index}>
                    <div className={styles.name}>
                        <b>{online[doc].nickname ? online[doc].nickname : "Visitor"}</b>
                        <small>{online[doc].d ? online[doc].d : online[doc].b}</small>
                    </div>
                    <p>{timeAgo.format(new Date(online[doc].t.seconds * 1000), 'twitter') }</p>
                </li>
            })}
        </ul>
        </div>
    </div>
  );
};

export default Index;
