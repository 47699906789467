import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import styles from "../../assets/styles/Pages.module.scss"
import classNames from "classnames"
import { getUserDetail, saveUser, deleteUser } from "../../state/actions/users"
import { ButtonPrimary, ButtonDanger, ButtonSecondary } from "../../components/Items/buttons"
import Arrow from "../../assets/img/icons/arrow.svg"
import LastMessages from "./LastMessages"
import ListActivity from "./ListActivity"
import Payments from "./Payments"
import EditDetail from "./EditDetail"
import Modal from "../../components/Items/modal"


const Edit = () => {
    const { adminData } = useSelector(
        (state) => ({
            adminData: state.auth.userData,
        }), shallowEqual
    );
    const history = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [modal, modalSet] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getUserDetail(id))
        // eslint-disable-next-line
    }, [dispatch]);
    return (
        <section className={classNames(styles.Pages, styles.edit)}>

            <div className={styles.headerBottom}>
                <div className={styles.left}>
                    <NavLink to="/users">
                        Users
                    </NavLink>
                    <img className={styles.arrow} src={Arrow} alt="" />
                    <span>
                        {id}
                    </span>
                </div>
                <div className={styles.right}>
                    <NavLink to={"/users?back=true"}>
                        Back
                    </NavLink>
                    {adminData.role === 1 ?
                        <ButtonDanger onClick={(e) => modalSet(true)} style={{ borderRadius: 5, fontWeight: 500, width: 70, fontSize: 13, paddingLeft: 10, paddingRight: 10, height: 38, marginLeft: 15 }} text={"Delete"} />
                        : ""}
                    {adminData.role === 1 ?
                        <ButtonPrimary onClick={(e) => dispatch(saveUser(id))} style={{ height: 38, borderRadius: 5, width: 120, fontWeight: 500, fontSize: 13, marginLeft: 10 }} text="Save" />
                        : ""}
                </div>
            </div>

            <div className={styles.row}>
                <div className={classNames(styles.container, styles.full)}>
                    <EditDetail id={id} />
                </div>
            </div>
            <div className={styles.row}>
                <div className={classNames(styles.container, styles.wHalf)}>
                    <LastMessages id={id} />
                </div>
                <div className={classNames(styles.container, styles.w25)} style={{ flex: 1 }}>
                    <ListActivity id={id} />
                </div>
                <div className={classNames(styles.container, styles.w25)} style={{ flex: 1 }}>
                    <Payments id={id} />
                </div>
            </div>
            {modal ? <Modal title="DELETE USER" content={""} buttons={<div style={{ display: "flex" }}><ButtonDanger onClick={(e) => { dispatch(deleteUser(id)); setTimeout(() => { history.push("/users") }, 1000) }} style={{ borderRadius: 5, fontWeight: 500, fontSize: 13, paddingLeft: 10, paddingRight: 10, height: 38, flex: 1, }} text={"Delete"} /><ButtonSecondary onClick={(e) => modalSet(false)} style={{ borderRadius: 5, fontWeight: 500, fontSize: 13, paddingLeft: 10, paddingRight: 10, height: 38, flex: 1, }} text={"Close"} /></div>} width={500} close={(e) => modalSet("")} /> : ""}
        </section>
    )
}

export default Edit;
