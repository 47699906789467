import { createReducer } from 'redux-act';

import {
    ADMINS_START,
    ADMINS_END,
    ADMINS_SEARCH_USER,
    ADMINS_USER_DETAIL,
} from '../actions/admins';

const initialState = {};

const newsletters = createReducer(
    {
        [ADMINS_START]: (state, payload) => {
            return ({
                ...state,
                loading: true
            })
        },
        [ADMINS_END]: (state, payload) => {
            return ({
                ...state,
                loading: false,
                error: ""
            })
        },
        [ADMINS_USER_DETAIL]: (state, payload) => {
            return ({
                ...state,
                detail:payload
            })
        },
        [ADMINS_SEARCH_USER]: (state, payload) => {
            return ({
                ...state,
                list: payload.list,
            })
        }
    },
    initialState
);
export default newsletters