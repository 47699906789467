
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Index.module.scss';
import { useDispatch } from 'react-redux';
// import { useLocation} from "react-router-dom";
import Header from './Header/Header';
import classNames from 'classnames';
import Sidebar from "./Sidebar/Index"
import {onlineUsersReset} from "../../state/actions/online"

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    // let location = useLocation();
    useEffect(() => {
        dispatch(onlineUsersReset())
        // dispatch(matchPopupData())
        // eslint-disable-next-line
    }, [dispatch]);
    // openMessage
    // if((window.innerWidth < 991)){
        // if(document.body.style.overflowY === "hidden" && location.pathname !== "/dashboard"){
        //    document.body.style.overflowY = "" 
        // }
        // return(
        // <div className={styles.mobileLayout}>
        //     <div className={classNames("container", styles.general)}>
        //             <HeaderMobile />
        //     </div>
        //     <div className={classNames(styles.content)}>
        //         {children}
        //     </div>
        //     <MobilMenu />
        //     <Coins />
        // </div>)
    // }else{
        return (
            <div>
                <Header />
                <div className={classNames(styles.general)} style={{ minHeight: window.innerHeight }}>

                    <Sidebar />
                    <div className={styles.content}>
                        {children}
                        {/* <Footer /> */}
                    </div>
                
                </div>
            </div>
        );
    // }
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
