import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/PagesHead.module.scss"
import { ButtonPrimary} from "../../components/Items/buttons"
import Input from "../../components/Items/input"
import Select from "../../components/Items/select"
import DateRange from "../../components/Items/dateRange"
// import { getRandomFake } from '../../state/actions/users';
import {FormattedMessage, useIntl} from 'react-intl';

const Search = (props) => {
    const {  form, citys } = useSelector(
        (state) => ({
            form:state.form,
        citys: state.form.edit_filter_citys ? state.form.edit_filter_citys : []
        }), shallowEqual
    );
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, [dispatch]);

  return(
    <div className={Styles.DashboardFiltre} >
        <div className={Styles.row}>
            <ul>
                <li>
                    <Input style={{marginBottom:0}} styletype="type_2" name="ssId" type="text" placeholder="ID" bar="false"/>
                </li>
                <li>
                    <Select style={{marginBottom:0}} styletype="type_2" name="edit_search_online" placeholder="Online Status" items={[{ label: "Online", value: true }]} />
                </li>
                <li>
                    <Select style={{marginBottom:0}} styletype="type_2" name="edit_filter_country" placeholder="Land" items={[{label:"Österreich", value:"at"}, {label:"Deutschland", value:"de"}, {label:"Schweiz", value:"ch"}]} />
                </li>
                <li>
                    <Select style={{marginBottom:0}} styletype="type_2" name="edit_filter_city" placeholder="Region" items={citys} />
                </li>
                <li>
                    <Input style={{marginBottom:0}} styletype="type_2" name="edit_filter_ref" type="text" placeholder="Ref" />
                </li>
            </ul>
            <ul>

                <li>
                    <Input style={{marginBottom:0}} styletype="type_2" name="edit_search_nickname_newsletter" type="text" placeholder={intl.formatMessage({id:"Dashboard.nickname"})} bar="false"/>
                </li>
                <li>
                    <Select style={{marginBottom:0}} styletype="type_2" name="edit_search_range2" placeholder="Im Alter Von" items={[{ label: "18 - 25", value: "0" }, { label: "25 - 35", value: "1" }, { label: "35 - 45", value: "2" }, { label: "55 - 65", value: "3" }]} />
                </li>
                <li>
                    <Select style={{marginBottom:0}} styletype="type_2" name="edit_search_gender2" placeholder="Gender" items={[{ label: "Weiblich", value: 1 }, { label: "Männlich", value: 0 }]} />
                </li>
                <li>
                    {form.ssStartDate || form.ssStartDate ? 
                        <Select disabled style={{marginBottom:0}} styletype="type_2" name="edit_search_avatar" placeholder="Profil Bild" items={[{ label: "True", value: true }, { label: "False", value: false }]} /> :   
                        <Select style={{marginBottom:0}} styletype="type_2" name="edit_search_avatar" placeholder="Profil Bild" items={[{ label: "True", value: true }, { label: "False", value: false }]} />
                        }
                    
                </li>
            </ul>
            <ul>
                <li>
                    {form.ssStartDate || form.ssStartDate || form.edit_search_avatar ? 
                        <Select disabled style={{marginBottom:0}} styletype="type_2" name="edit_filter_payment" placeholder="Gezahlt" items={[{ label: "True", value: true }, { label: "False", value: false }]} /> :
                        <Select style={{marginBottom:0}} styletype="type_2" name="edit_filter_payment" placeholder="Gezahlt" items={[{ label: "True", value: true }, { label: "False", value: false }]} />
                    }
                </li>
                <li>
                    {form.ssStartDate || form.ssStartDate || form.edit_search_avatar || form.edit_filter_payment ? 
                        <Input disabled style={{marginBottom:0}} styletype="type_2" name="edit_filter_coin_up" type="text" placeholder="Coin Up" /> :
                        <Input style={{marginBottom:0}} styletype="type_2" name="edit_filter_coin_up" type="text" placeholder="Coin Up" />
                    }
                </li>
                <li>
                    {form.ssStartDate || form.ssStartDate || form.edit_search_avatar || form.edit_filter_payment ? 
                        <Input disabled style={{marginBottom:0}} styletype="type_2" name="edit_filter_coin_down" type="text" placeholder="Coin Down" /> :
                        <Input style={{marginBottom:0}} styletype="type_2" name="edit_filter_coin_down" type="text" placeholder="Coin Down" />
                    }
                </li>
                <li className={Styles.x2}>
                    <DateRange name1="ssStartDate" name2="ssEndDate" styletype="type_2" />
                </li>
            </ul>
        </div>
        <ButtonPrimary onClick={props.onsearch} style={{ borderRadius: 5, height: 146, width: "auto", textTransform:"capitalize",flex: "none", marginBottom:0, marginLeft:15 }}   text={<FormattedMessage id="Dashboard.aramaYap" />} />
    </div>
  )
}

export default Search;
