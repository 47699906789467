import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import Modals from '../Modals/Index';
import {  NavLink, useLocation } from "react-router-dom";
import styles from "./Header.module.scss"
import {defaultLAvatar} from "../../../Settings"
import {searchHeader, changeMode} from "../../../state/actions/dashboard"
import { logOut } from '../../../state/actions/auth';

const Header_2 = (props) => {
    const dispatch = useDispatch();
    const [title, titleSet] = useState("Dashboard");
    const [fullScreen, fullScreenSet] = useState(false);
    const [hideMenu, setHideMenu] = useState(false);
    const [searchMenu, searchMenuSet] = useState(false);
    const [dropdown, dropdownSet] = useState(false);
    const [dropdown2, dropdown2Set] = useState(false);
    let location = useLocation();
    var elem = document.documentElement;
   
    const { auth, mode, search } = useSelector(
        (state) => ({
            auth: state.auth,
            search: state.dashboard.search ? state.dashboard.search : [],
            mode: state.dashboard.mode ? state.dashboard.mode : [],
        }), shallowEqual
    );
    useEffect(() => {
        switch (location.pathname) {
            case "/dashboard":
                titleSet("Dashboard")
            break;
            case "/users-deleted":
                titleSet("Deleted Users")
            break;
            case "/admins":
                titleSet("Admins")
            break;
            case "/callcenters":
                titleSet("Callcenters")
            break;
            case "/users":
                titleSet("Users")
            break;
            case "/fakes":
                titleSet("Fakes")
            break;
            case "/payments":
                titleSet("Payments")
            break;
            case "/affiliate-system":
                titleSet("Affiliate System")
            break;
            case "/newsletters":
                titleSet("Newsletters")
            break;
            case "/messages":
                titleSet("Messages")
            break;
            case "/logs":
                titleSet("Logs")
            break;
            case "/contacts":
                titleSet("Contacts")
            break;
            case "/settings":
                titleSet("Settings")
            break;
        
            default:
                break;
        }
        // eslint-disable-next-line
    }, [location]);
    useEffect(() => {
        const windowScroll = (event) => {
            if(window.scrollY > 20 && !hideMenu){
                setHideMenu(true)
            }else if(window.scrollY < 20 && hideMenu){
                setHideMenu(false)
            }
        }

        window.addEventListener('scroll', windowScroll);

        // cleanup this component
        return () => {
        window.removeEventListener('scroll', windowScroll);
        };
        // eslint-disable-next-line
    }, [hideMenu]);
    if(!auth.userData.username){
        return null
    }
    const openFullscreen = () => {
        if(fullScreen){

            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }else{
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
              } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
              } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
              }
        }
        fullScreenSet(!fullScreen)
    }
    return (<div id="dashboard_header">
        <div className={classNames(styles.headerSearch, searchMenu ? styles.active : "")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect fill="" x="0" y="0" width="24" height="24"/>
                    <path fill="" d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fillRule="nonzero" opacity="0.3"/>
                    <path fill="" d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fillRule="nonzero"/>
                </g>
            </svg>
            <input type="text" placeholder="Search users / fakes (ID, Name, Email)" onChange={(e) => dispatch(searchHeader(e.target.value))} />
            <svg onClick={(e) => searchMenuSet(false)} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect fill="var(--gray)" x="0" y="7" width="16" height="2" rx="1"/>
                        <rect fill="var(--gray)" opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                </g>
            </svg>
        </div>
        {search.length ? <div className={classNames(styles.searchMain, searchMenu ? styles.active : "")}>
            <ul>
                {search.map((doc,index) =>{
                    return<li key={index}>
                        <NavLink to="">
                            <b>{doc.nickname}</b>
                            <small>{doc.docId}</small>
                        </NavLink>
                    </li>
                })}
                
            </ul>
        </div> : ""}
        <div className={classNames(styles.header, hideMenu ? styles.active : "")}>
            <div className={styles.left}>
               <h1>{title}</h1> 
            </div>
            <div className={styles.right}>
                <div className={classNames("dropdown", dropdown2 ? "active" : "")}>
                    <div className={styles.langs} onClick={(e) => dropdown2Set(!dropdown2)}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
                        <path fill="#464655" d="M400.005,0h-288.01C50.141,0.002,0,50.146,0,112v58.667h512V112C512,50.146,461.858,0.002,400.005,0z
                            "/>
                        <path fill="#FFE15A" d="M0,400c0,61.856,50.144,112,112,112h288c61.856,0,112-50.144,112-112v-58.667H0V400z"/>
                        <rect y="170.67" fill="#FF4B55" width="512" height="170.67"/>
                        </svg>

                        German
                    </div>
                    <div className={"dropdown-menu"} style={{top:40}}>

                    </div>
                    <div className="dropdown-bg" onClick={(e) => dropdown2Set(false)}></div>
                </div>
                <div className={styles.icons}>
                    {mode === "dark" ? <div className={styles.bgSwitch} onClick={(e) => dispatch(changeMode("light"))}><div className={styles.track}></div><div className={styles.thumb}></div></div> :
                    <div className={classNames(styles.bgSwitch, styles.active)} onClick={(e) => dispatch(changeMode("dark"))}><div className={styles.track}></div><div className={styles.thumb}></div></div>}
                    
                    <svg onClick={openFullscreen} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fillRule="nonzero"></path>
                        </g>
                    </svg>
                    <svg onClick={(e) => searchMenuSet(true)} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect fill="" x="0" y="0" width="24" height="24"/>
                            <path fill="" d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fillRule="nonzero" opacity="0.3"/>
                            <path fill="" d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fillRule="nonzero"/>
                        </g>
                    </svg>
                </div>
                <div className={classNames("dropdown", dropdown ? "active" : "")}>
                    <div className={styles.profile} onClick={(e) => dropdownSet(!dropdown)}>
                        <div className={styles.profileInfo}>
                            <b>{auth.userData.username}</b>
                            <small>{auth.userData.email}</small>
                        </div>
                        <div className={styles.avatar} style={{backgroundImage:"url("+defaultLAvatar+")"}}>

                        </div>
                    </div>
                    <div className={"dropdown-menu"} style={{top:40}}>
                        <ul>
                            <li onClick={(e) => dispatch(logOut())} style={{paddingLeft:15, cursor:"pointer"}}>
                                Logout
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown-bg" onClick={(e) => dropdownSet(false)}></div>
                </div>
            </div>
        </div>
        <Modals />
{/*     
            {location.pathname !== "/premium" ? <div className={styles.messageButton} onClick={(e) => {dispatch(setMessageOpen(true))}}>
            Postfach {newMessages ? <span id="newMessage2" className={styles.newMessage}>{newMessages}</span> : ""} 
            </div> : ""} */}
        
    </div>)
}
export default Header_2;
