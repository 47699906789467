import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Styles from "../../assets/styles/Pages.module.scss"
import { logsData} from "../../state/actions/logs"
import Table from "../../components/Items/table"
import TableHead from "./TableHead"
import {onChange} from "../../state/actions/form"

const Users = () => {
    const { loading, list} = useSelector(
        (state) => ({
            loading: state.logs.loading,
            list: state.logs && state.logs.list ? state.logs.list : [],
        }), shallowEqual
    );
    const dispatch = useDispatch();
    const [lastData, lastDataSet] = useState([])
    const [perPage, perPageSet] = useState(10)
    const [activePage, activePageSet] = useState(0)
    const [head, headSet] = useState([
        {name:"Name", orderBy:"", key:"u"}, 
        {name:"IP", orderBy:"", key:"i"}, 
        {name:"Ref", orderBy:"", key:"ref"}, 
        {name:"Device", orderBy:"", key:"d"}, 
        {name:"Role", orderBy:"", key:"r"}, 
        {name:"Date", orderBy:"desc", key:"t"}, 
    ])
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(onChange("searchStartDate", ""))
        dispatch(onChange("searchEndDate", ""))
        dispatch(onChange("search_u", ""))
        dispatch(onChange("search_ip", ""))
        dispatch(onChange("search_ref", ""))
        dispatch(onChange("search_d", ""))
        dispatch(onChange("search_r", ""))
        dispatch(logsData("first", lastData, lastDataSet, perPage, head, false, activePage))
        // eslint-disable-next-line
    }, [dispatch]);
    const headSetFunc = (index, value) =>{
        let change = head;
        change.forEach((doc, index)=>{
            if(doc.orderBy === "asc" || doc.orderBy === "desc"){
                change[index].orderBy = ""
            }
        })
        change[index].orderBy = value
        activePageSet(0)
        lastDataSet(0)
        dispatch(logsData("first", 0, lastDataSet, perPage, change, 0))
    }
    const changePage = (type) => {
        if(type==="prev"){
            activePageSet(activePage - 1)
            dispatch(logsData("", lastData, lastDataSet, perPage, head, activePage - 1))
        }else if(type === "next"){
            activePageSet(activePage + 1)
            dispatch(logsData("", lastData, lastDataSet, perPage, head, activePage + 1))
        }else{
            activePageSet(type)
            dispatch(logsData("", lastData, lastDataSet, perPage, head, type))
        }
    }
    return (
        <section className={Styles.Pages}>
            <div className={Styles.contentWhite}>
                <TableHead onsearch={(e) => {activePageSet(0);lastDataSet(0);dispatch(logsData("first", 0, lastDataSet, perPage, head, 0))}} />
                <Table class={"type_2"} head={head} lastData={lastData} activePage={activePage} changePage={changePage} headSetFunc={headSetFunc} body={list} buttonType="" />
                {loading ?<div className="loading"></div> : ""}
            </div>
        </section>
    )
}

export default Users;
